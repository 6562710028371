import CleaningHomeCard from "@crm/components/cleaning/cleaning-home-card";
import {Step} from "@crm/models/step";
import React from "react";
import {CleaningState} from "@crm/services/cleaning-slice";

export const CLEANING_STEP_HOME = 'home';

export class CleaningStepHome extends Step {
    public getIdentifier(): string {
        return CLEANING_STEP_HOME;
    }

    public getName(): string {
        return 'Hjem';
    }

    public getShortName(): undefined {
        return undefined;
    }

    public isComplete(state: CleaningState): boolean {
        return state.address !== undefined;
    }

    public buildPage(currentPage: string): React.JSX.Element {
        return <CleaningHomeCard
            key={this.getIdentifier()}
            hidden={currentPage !== this.getIdentifier()}
        />;
    }
}