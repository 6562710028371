import React from 'react';
import styled, {css} from 'styled-components';
import {
    LightTheme,
    ThemeColorType,
    themeColorTypes,
    Typography as TypographyVariantTypes,
    TypographyVariant
} from '../../theme/light-theme';
import {getFluidSizing} from '@crm/utils/helpers';

const alternativeColorTypes = [
    'initial',
    'inherit',
    'primary',
    'textPrimary',
    'textSecondary',
    'error'
] as const;

type AlternativeColorTypes = typeof alternativeColorTypes[number];
export type ColorTypes = AlternativeColorTypes | ThemeColorType;
type ColorMapperType = Record<AlternativeColorTypes, any>;

type VariantMapperType = Record<TypographyVariant, string>;

// https://css-tricks.com/linearly-scale-font-size-with-css-clamp-based-on-the-viewport/
const fluidFontSizeStyle = (key: TypographyVariant, theme: LightTheme) => {
    const typo = theme.typography[key];
    if ('mobile' in typo) {
        return css`
            ${({theme}) =>
                    getFluidSizing(
                            typo.mobile.fontSize,
                            typo.fontSize,
                            theme.breakpoints.values.largeScreen,
                            theme.breakpoints.values.mobile
                    )}
        `;
    }
    return null;
};

const commonStyles = (key: TypographyVariant) =>
    css`
        font-family: ${({theme}) => theme.typography[key].fontFamily};
        font-weight: ${({theme}) => theme.typography[key].fontWeight};
        line-height: ${({theme}) => theme.typography[key].lineHeight};
        font-size: ${({theme}) => theme.typography[key].fontSize};
    `;

const h1Styles = css`
    ${commonStyles('h1')}
    ${({theme}) => theme.breakpoints.down('tablet')} {
        font-size: ${({theme}) => theme.typography.h1.tablet.fontSize};
    }

    ${({theme}) => theme.breakpoints.down('mobile')} {
        font-size: ${({theme}) => theme.typography.h1.mobile.fontSize};
    }

    ${({theme}) => theme.breakpoints.down('mobileM')} {
        font-size: ${({theme}) => theme.typography.h1.mobileM.fontSize};
    }
`;

const h2Styles = css`
    ${commonStyles('h2')}
    ${({theme}) => theme.breakpoints.down('mobile')} {
        font-size: ${({theme}) => theme.typography.h2.mobile.fontSize};
    }
`;

const h3Styles = css`
    ${commonStyles('h3')}
    ${({theme}) => theme.breakpoints.down('mobile')} {
        font-size: ${({theme}) => theme.typography.h3.mobile.fontSize};
    }
`;

const h4Styles = css`
    ${commonStyles('h4')}
    ${({theme}) => theme.breakpoints.down('mobile')} {
        font-size: ${({theme}) => theme.typography.h4.mobile.fontSize};
    }
`;

const h5Styles = css`
    ${commonStyles('h5')}
    font-size: ${({theme}) => theme.typography.h5.fontSize};
`;

const h40Styles = css`
    ${commonStyles('h40')}
    font-size: ${({theme}) => fluidFontSizeStyle('h40', theme)};
`;

const errorStyles = css`
    ${commonStyles('error')}
    font-size: ${({theme}) => theme.typography.error.fontSize};
    color: ${({theme}) => theme.palette.common.red};
`;

const bodyStyles = css`
    ${commonStyles('body')}
    font-size: ${({theme}) => fluidFontSizeStyle('body', theme)};
`;

const subtitleStyles = css`
    ${commonStyles('subtitle')}
    font-size: ${({theme}) => fluidFontSizeStyle('subtitle', theme)};
`;

const noWrapStyles = css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const stylesWrapper: TypographyVariantTypes = {
    h1: h1Styles,
    h2: h2Styles,
    h3: h3Styles,
    h4: h4Styles,
    h5: h5Styles,
    h40: h40Styles,
    body: bodyStyles,
    subtitle: subtitleStyles,
    error: errorStyles
};

const getColor = (colorType: ColorTypes) => {
    if (themeColorTypes.includes(colorType as ThemeColorType)) {
        return css`
            color: ${({theme}) => theme.palette.common[colorType as ThemeColorType]};
        `;
    } else if (alternativeColorTypes.includes(colorType as AlternativeColorTypes)) {
        return colorMapper[colorType as AlternativeColorTypes];
    }
};

const colorMapper: ColorMapperType = {
    initial: css`
        color: initial;
    `,
    inherit: css`
        color: inherit;
    `,
    primary: css`
        color: ${({theme}) => theme.palette.primary.main};
    `,
    textPrimary: css`
        color: ${({theme}) => theme.palette.text.primary};
    `,
    textSecondary: css`
        color: ${({theme}) => theme.palette.text.secondary};
    `,
    error: css`
        color: ${({theme}) => theme.palette.error.main};
    `
};

const variantMapper: VariantMapperType = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h40: 'p',
    body: 'p',
    subtitle: 'p',
    error: 'p'
};

const StyledTypography = styled.span.withConfig({
    shouldForwardProp: (prop) => (prop !== 'variant' && prop !== 'textAlign'), // Prevent 'variant' from being passed to the DOM
})<Props>`
    ${props => props.variant && stylesWrapper[props.variant]}; // Use 'variant' for styling

    text-align: ${({textAlign}) => textAlign};
    text-transform: ${({textTransform}) => textTransform};
    font-weight: ${({fontWeight, theme}) => fontWeight && theme.typography.weight[fontWeight]};

    ${({color}) => color && getColor(color)};
    ${({noWrap}) => noWrap && noWrapStyles};

    ${({styles}) => styles && styles};

    & > a {
        text-decoration: underline;
    }
`;

export interface Props {
    children: React.ReactNode | React.ReactNode[];
    fontWeight?: 'light' | 'regular' | 'medium' | 'bold';
    color?: ColorTypes;
    variant?: TypographyVariant;
    textAlign?: 'left' | 'center' | 'right' | 'justify';
    textTransform?: 'uppercase' | 'lowercase' | 'capitalize' | 'none' | 'initial' | 'inherit';
    noWrap?: boolean;
    component?: React.ElementType;
    styles?: { root?: string };
}

const Typography: React.FC<Props & React.HTMLAttributes<HTMLDivElement>> = (props: Props) => {
    const {
        children,
        fontWeight,
        color,
        textAlign,
        variant = 'body',
        component,
        noWrap,
        textTransform,
        styles,
        ...rest
    } = props;

    const tag = component ?? variantMapper[variant] ?? 'span';

    return <StyledTypography
        as={tag}
        fontWeight={fontWeight}
        color={color}
        textAlign={textAlign}
        variant={variant}
        noWrap={noWrap}
        textTransform={textTransform}
        styles={styles?.root}
        {...rest}
    >
        {children}
    </StyledTypography>;
};

export default Typography;
