import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {AutocompleteAddress, DawaAutocomplete} from "@crm/api/models/common/dawa-api";
import {AutocompleteAddressRequest, DawaAutocompleteRequest} from "@crm/api/models/requests/dawa-api";

const REGION_FILTER_CODES = '1084|1085';

export const dawaApi = createApi({
    reducerPath: 'dawaApi',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://api.dataforsyningen.dk',
    }),
    endpoints: builder => ({
        addressAutocomplete: builder.query<AutocompleteAddress[], AutocompleteAddressRequest>({
            query: ({query, caretPos, regionFilter}) => ({
                url: 'adresser/autocomplete',
                params: {
                    type: 'adresse',
                    startfra: 'adresse',
                    q: query,
                    caretpos: caretPos ?? query.length,
                    perSide: 10,
                    regionskode: regionFilter === ''
                        ? undefined
                        : regionFilter ?? REGION_FILTER_CODES,
                },
            }),
        }),
        autocomplete: builder.query<DawaAutocomplete[], DawaAutocompleteRequest>({
            query: (data) => ({
                url: 'autocomplete',
                params: {
                    caretpos: data.caretpos ?? data.q.length,
                    ...data,
                } as DawaAutocompleteRequest,
            }),
        }),
    }),
});

export const {useAddressAutocompleteQuery, useAutocompleteQuery} = dawaApi;