import orderStyles from 'styles/order.module.css';
import {smoothScrollToTop} from '@crm/utils/helpers';
import React, {useEffect, useState} from 'react';
import {housekeeprApi} from '@crm/api/housekeepr-api';
import {useAppDispatch, useAppSelector} from '@crm/services/store';
import {skipToken} from '@reduxjs/toolkit/query';
import {cleaningActions, cleaningSelectors} from '@crm/services/cleaning-slice';
import {extraServicePriceOrSelectedDisplay} from '@crm/api/models/common/housekeepr-api';
import {ExtraServiceRequest} from '@crm/api/models/requests/housekeepr-api';

type CleaningExtraServicesProps = { hidden?: boolean };

export const CleaningExtraServicesCard = (props: CleaningExtraServicesProps) => {
    const dispatch = useAppDispatch();

    const canGoToNext = useAppSelector(cleaningSelectors.selectCanGoToNextStep);

    const selectedServiceId = useAppSelector(cleaningSelectors.selectServiceId);
    const selectedExtraServices = useAppSelector(cleaningSelectors.selectExtraServices);
    const selectedFrequencyId = useAppSelector(cleaningSelectors.selectFrequencyId);
    const selectedPaymentIntervalId = useAppSelector(cleaningSelectors.selectPaymentIntervalId);

    const {
        data: extraServicesResult,
        isFetching: extraServicesLoading
    } = housekeeprApi.useGetExtraServicesQuery(selectedServiceId ?? skipToken);

    const {
        data: paymentIntervalsResult
    } = housekeeprApi.useGetPaymentIntervalsQuery(selectedPaymentIntervalId ? undefined : skipToken);

    const paymentInterval = paymentIntervalsResult?.find(paymentInterval => paymentInterval.id == selectedPaymentIntervalId);

    const {
        data: serviceResult
    } = housekeeprApi.useGetServiceQuery(selectedServiceId ?? skipToken);

    const selectedFrequency = serviceResult?.frequencies.find(frequency => frequency.id == selectedFrequencyId);

    const availableExtraServices = extraServicesResult?.filter(extraService => extraService.is_orderable);

    const [editingExtraService, setEditingExtraService] = useState<ExtraServiceRequest | undefined>(undefined);
    const extraServiceBeingEdited = editingExtraService && availableExtraServices?.find(extraService => extraService.id == editingExtraService.id);

    useEffect(() => {
        if (document.body.parentNode == undefined) {
            return;
        }
        const parentNode = document.body.parentNode as HTMLElement;
        if (extraServiceBeingEdited) {
            parentNode.style.overflow = 'hidden';
        } else {
            parentNode.style.overflow = 'unset';
        }
    }, [extraServiceBeingEdited]);

    return <div className={props.hidden ? orderStyles.hidden : orderStyles.cardWrapper}>
        <div className={orderStyles.card}>
            <div className={orderStyles.orderSection}>
                <h1 className={`${orderStyles.cardH1}`}>
                    Tilføj ekstraydelser (valgfrit)
                </h1>
                {paymentInterval !== undefined && paymentInterval.discount > 0 &&
                    <p
                        className={`${orderStyles.orderSectionContent} ${orderStyles.removeTopMargin}`}
                    >
                        Bemærk priser er vist inkl. {paymentInterval.discount}% rabat fra dit valgte betalingsinterval.
                    </p>
                }
                <div
                    id={'extra-services-content'}
                    className={`${orderStyles.orderSectionContent} ${orderStyles.flexRow} ${orderStyles.flexWrap} ${orderStyles.flexCrossStart}`}>
                    {extraServicesLoading && [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((i) =>
                        <div
                            className={`${orderStyles.extraServiceOption} ${orderStyles.shimmer}`}
                            key={i}
                        />
                    )}
                    {availableExtraServices?.map(extraService => {
                        const added = selectedExtraServices.find(selectedExtraService => selectedExtraService.id == extraService.id);
                        return (
                            <div
                                className={`${orderStyles.extraServiceOption} ${selectedExtraServices.some(selectedExtraService => selectedExtraService.id == extraService.id) ? orderStyles.selected : ''}`}
                                key={extraService.id}
                                id={`extra-service-${extraService.id}`}
                                onClick={() => {
                                    setEditingExtraService({
                                        id: extraService.id,
                                        count: 0,
                                        how_often: 1,
                                        ...added
                                    });
                                }}
                            >
                                {/* eslint-disable-next-line @next/next/no-img-element */}
                                <img src={extraService.image_url} alt={extraService.description}
                                     className={orderStyles.extraServiceImage}/>
                                <div className={'flex flex-grow-8'}></div>
                                <div className={orderStyles.extraServiceName}>
                                    {extraService.name}
                                </div>
                                <div className={orderStyles.extraServicePrice}>
                                    {extraServicePriceOrSelectedDisplay(extraService, added, paymentInterval?.discount)}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            {editingExtraService && extraServiceBeingEdited &&
                <div
                    className={`${orderStyles.modalContainer}`}
                    onClick={() => {
                        return;
                    }}
                    onScroll={() => {
                        return;
                    }}
                >
                    <div
                        id={'extra-service-modal'}
                        className={`${orderStyles.modalCard} ${orderStyles.extraServiceModal}`}
                    >
                        <div className={`${orderStyles.flexRow} ${orderStyles.flexCrossStart}`}>
                            <div style={{flex: 1}}>
                                <h2 className={`${orderStyles.cardH2}`}>
                                    {extraServiceBeingEdited?.name}
                                </h2>
                                <p className={`${orderStyles.orderSectionContent} ${orderStyles.removeTopMargin}`}>
                                    {extraServiceBeingEdited?.description}
                                </p>
                                <label className={'mb-1'}>
                                    Vælg
                                    antal{extraServiceBeingEdited.unit ? ` (${extraServiceBeingEdited.unit})` : ''}
                                </label>
                                <div className={`${orderStyles.flexWrap}`}>
                                    <div
                                        className={`${orderStyles.flexRow}`}
                                        style={{position: 'relative', width: '100%'}}
                                    >
                                        <div
                                            className={orderStyles.extraServiceCountButton}
                                            style={{left: 5}}
                                            onClick={() => {
                                                const extraServiceModal = document.getElementById(`extra-service-modal`);
                                                const extraServiceCountInput = extraServiceModal?.querySelector('input');
                                                if (!extraServiceCountInput) return;
                                                const value = extraServiceCountInput.value;
                                                const count = parseInt(value);
                                                const newValue = Math.max(0, Math.min(extraServiceBeingEdited.max_number, isNaN(count) ? 0 : count - 1));

                                                extraServiceCountInput.value = newValue.toString();
                                                setEditingExtraService({
                                                    ...editingExtraService,
                                                    count: newValue
                                                });
                                            }}
                                        >
                                            −
                                        </div>
                                        <input
                                            style={{
                                                boxSizing: 'border-box',
                                                paddingLeft: '30px',
                                                paddingRight: '30px'
                                            }}
                                            maxLength={extraServiceBeingEdited.max_number.toString().length + 1}
                                            placeholder={'0'}
                                            inputMode={'numeric'}
                                            value={editingExtraService.count <= 0 ? '' : editingExtraService.count}
                                            onInput={event => {
                                                const extraServiceCountInput = event.target as HTMLInputElement;
                                                const value = extraServiceCountInput.value;
                                                const count = parseInt(value);
                                                const newValue = Math.max(0, Math.min(extraServiceBeingEdited.max_number, isNaN(count) ? 0 : count));
                                                extraServiceCountInput.value = newValue <= 0 ? '' : newValue.toString();
                                                setEditingExtraService({
                                                    ...editingExtraService,
                                                    count: newValue
                                                });
                                            }}
                                        />
                                        <div
                                            className={orderStyles.extraServiceCountButton}
                                            style={{right: 5}}
                                            onClick={() => {
                                                const extraServiceModal = document.getElementById(`extra-service-modal`);
                                                const extraServiceCountInput = extraServiceModal?.querySelector('input');
                                                if (!extraServiceCountInput) return;
                                                const value = extraServiceCountInput.value;
                                                const count = parseInt(value);
                                                const newValue = Math.max(0, Math.min(extraServiceBeingEdited.max_number, isNaN(count) ? 1 : count + 1));
                                                extraServiceCountInput.value = newValue.toString();
                                                setEditingExtraService({
                                                    ...editingExtraService,
                                                    count: newValue
                                                });
                                            }}
                                        >
                                            +
                                        </div>

                                    </div>
                                    {selectedFrequency && selectedFrequency.every > 0 &&
                                        <label>
                                            Hvor ofte?
                                            <select
                                                id="editing-extra-service"
                                                value={editingExtraService.how_often}
                                                onChange={event => {
                                                    setEditingExtraService({
                                                        ...editingExtraService!,
                                                        how_often: parseInt(event.target.value)
                                                    });
                                                }}
                                            >
                                                {[1, 2, 4].map(howOften =>
                                                    <option
                                                        key={howOften}
                                                        value={howOften}
                                                    >
                                                        {(howOften * selectedFrequency.every) > 1 ? `Hver ${howOften * selectedFrequency.every}. uge` : 'Hver uge'}
                                                    </option>
                                                )}
                                            </select>
                                        </label>
                                    }
                                    <label>
                                        Yderligere information
                                        <textarea
                                            className={orderStyles.textField}
                                            maxLength={500}
                                            inputMode={'text'}
                                            value={editingExtraService.note}
                                            placeholder={'Beskriv hvor servicen skal udføres, hvis Housekeepren kunne være i tvivl.'}
                                            onInput={event => {
                                                const extraServiceCountInput = event.target as HTMLInputElement;
                                                const value = extraServiceCountInput.value;
                                                setEditingExtraService({
                                                    ...editingExtraService,
                                                    note: value
                                                });
                                            }}
                                        />
                                    </label>
                                </div>
                                <div className={`${orderStyles.flexRow} ${orderStyles.flexMainSpaceBetween} pt-4`}>
                                    <div
                                        className={`${orderStyles.buttonWrapper} ${orderStyles.outline} w-[40%]`}>
                                        <button
                                            onClick={() => {
                                                setEditingExtraService(undefined);
                                            }}
                                        >
                                            Annuller
                                        </button>
                                    </div>
                                    <div
                                        className={`${orderStyles.buttonWrapper} w-[40%]`}>
                                        <button
                                            onClick={() => {
                                                dispatch(cleaningActions.setExtraService(editingExtraService));
                                                setEditingExtraService(undefined);
                                            }}
                                        >
                                            Gem
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
        <div className={`${orderStyles.flexRow} ${orderStyles.flexMainSpaceBetween} self-stretch`}>
            <div
                className={`${orderStyles.buttonWrapper} ${orderStyles.outline} w-[200px]`}>
                <button
                    onClick={() => {
                        dispatch(cleaningActions.previousPage());
                        smoothScrollToTop();
                    }}
                >
                    Tilbage
                </button>
            </div>
            <div
                className={`${orderStyles.buttonWrapper} ${orderStyles.outline} ${canGoToNext ? '' : orderStyles.disabled} w-[200px]`}>
                <button
                    onClick={() => {
                        dispatch(cleaningActions.nextPage());
                        smoothScrollToTop();
                    }}
                    disabled={!canGoToNext}
                >
                    Videre
                </button>
            </div>
        </div>
    </div>;
};

export default CleaningExtraServicesCard;