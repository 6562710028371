import React from "react";
import {Step} from "@crm/models/step";
import CleaningTimeCard from "@crm/components/cleaning/cleaning-time-card";
import {CleaningState} from "@crm/services/cleaning-slice";

export const CLEANING_STEP_TIME = 'time';

export class CleaningStepTime extends Step {
    public getIdentifier(): string {
        return CLEANING_STEP_TIME;
    }

    public getName(): string {
        return 'Tidspunkt';
    }

    public getShortName(): string {
        return 'Tid';
    }

    public isComplete(state: CleaningState): boolean {
        return state.availability !== undefined;
    }

    public buildPage(currentPage: string): React.JSX.Element {
        return <CleaningTimeCard
            key={this.getIdentifier()}
            hidden={currentPage !== this.getIdentifier()}
        />;
    }
}