import Link, {LinkProps} from 'next/link';
import {useRouter} from 'next/router';
import React from 'react';
import styled from 'styled-components';

interface Props extends LinkProps {
    children: React.ReactNode | React.ReactNode[];
    className?: string;
    onClick?: () => void;
}

const StyledNavLink = styled.span<{ $active: boolean }>`
    text-decoration: none;

    & :hover {
        color: white;
    }
`;

const NavLink: React.FC<Props> = (props: Props) => {
    const {children, href, className, onClick, ...rest} = props;
    const router = useRouter();

    return <Link href={href} {...rest}>
        <StyledNavLink onClick={onClick} className={className} $active={href === router.pathname}>
            {children}
        </StyledNavLink>
    </Link>;
};

export default NavLink;
