import Image from "next/legacy/image";
import React from 'react';
import styled from 'styled-components';
import lightTheme from '../../../theme/light-theme';
import Typography from '../../typography/typography';
import HorizontalSpacing from '../horizontal-spacing';
import ContactInformation from './contact-information';
import {LogoContainer} from '@crm/components/layout/navbar/logo-container';
import {MobilepayBlue, PaymentCardOptions} from '@crm/utils/images';
import Link from 'next/link';

const FooterRoot = styled.footer`
    background-color: ${props => props.theme.palette.common.darkBlue};
    padding-top: 30px;
    padding-bottom: 38px;

    ${props => props.theme.breakpoints.down('laptop')} {
        padding-bottom: 10px;
    }

    display: flex;
    flex-direction: column;
    z-index: 50;
    position: relative;
`;

const FooterContentContainer = styled(HorizontalSpacing)`
    height: 100%;
    display: block;
`;

const InformationRoot = styled.div`
    max-width: 779px;
`;

const Headline = styled(Typography)`
    margin-top: 90px;
    font-size: 36px;

    ${props => props.theme.breakpoints.down('laptop')} {
        font-size: 24px;
        margin-top: 90px;
    }
`;


const textStyle = {root: `color: ${lightTheme.palette.common.turquoise}`};

const Footer: React.FC = () =>
    <FooterRoot>
        <FooterContentContainer>
            <InformationRoot>
                <LogoContainer negative={true}/>
                <Headline variant="h2" styles={textStyle} id="kontakt">
                    Hvis du har nogle spørgsmål, særlige ønsker eller noget helt tredje, så ring til os på{' '}
                    <span className="text-white hover:underline">
          <Link href="tel:+4570604069">7060 4069</Link>
        </span>{' '}
                    eller{' '}
                    <span className="text-white hover:underline">
          <Link href="mailto:info@housekeepr.dk">send en mail.</Link>
        </span>{' '}
                    <br/>
                    Helt simpelt.
                </Headline>
            </InformationRoot>
            <div>
                <ContactInformation/>
            </div>
        </FooterContentContainer>
        <HorizontalSpacing className="flex">
            <div className="max-w-[158px]">
                <Image
                    priority={true}
                    src={PaymentCardOptions}
                    alt="Visa, Mastercard og Dankort logo"
                />
            </div>
            <div className="max-w-[158px] pl-3 pr-16 self-center">
                <Image
                    priority={true}
                    src={MobilepayBlue}
                    alt="Visa og Mastercard logo"
                />
            </div>
        </HorizontalSpacing>
    </FooterRoot>;

export default Footer;
