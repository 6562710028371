import {createApi, fetchBaseQuery, FetchBaseQueryError, retry} from '@reduxjs/toolkit/query/react';
import {
    AvailabilityRequest,
    AvailabilityRequestForChunking,
    CheckCodeRequest,
    CleaningOrderRequest,
    CleaningServicePriceRequest,
    LoginRequest,
    NewsletterSubscriptionRequest,
    RegisterUserRequest,
    ServicesRequest,
    StoreLocationRequest,
    WindowCleaningOrderRequest,
    WindowCleaningServicePriceRequest
} from '@crm/api/models/requests/housekeepr-api';
import {
    Address,
    Availability,
    ExtraService,
    Frequency,
    Location,
    PaymentInterval,
    ResidenceType,
    SearchPeriod,
    Service,
    ServiceType,
    TimeSlot,
    WindowType
} from '@crm/api/models/common/housekeepr-api';
import {
    ApiResponse,
    AreaResponse,
    AvailabilityResponse,
    CheckCodeResponse,
    CleaningOrderResponse,
    LoginResponse,
    PaymentAvailabilityResponse,
    PaymentMethodResponse,
    PriceResponse,
    RegisterUserResponse,
    WindowCleaningOrderResponse
} from '@crm/api/models/responses/housekeepr-api';
import {AppState} from '@crm/services/store';
import {addDaysToDate} from "@crm/utils/helpers";


const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL;

function transformResponse<Type>(value: any) {
    return (value as ApiResponse<Type>).data;
}

const baseQuery = retry(
    fetchBaseQuery({
        baseUrl: `${BASE_URL}/api/v1`,
        prepareHeaders: (headers, {getState}) => {
            const token = (getState() as AppState).auth.token;
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            headers.set('Accept', 'application/json');
            return headers;
        }
    }),
    {
        maxRetries: 0
    }
);

function splitSearchPeriod(searchPeriod: SearchPeriod, searchChunks: number) {
    const result = [];

    const availabilityWindowLength = searchPeriod.end_offset - searchPeriod.start_offset + 1;
    const chunkSize = availabilityWindowLength / searchChunks;
    const maxDate = addDaysToDate(new Date(), searchPeriod.end_offset);

    for (let i = 0; i < searchChunks; i += 1) {
        const start = Math.round(searchPeriod.start_offset + i * chunkSize);
        const end = Math.round(searchPeriod.start_offset + (i + 1) * chunkSize) - 1;

        const startDate = addDaysToDate(new Date(), start);
        const endDate = i === searchChunks - 1
            ? maxDate
            : addDaysToDate(new Date(), end);

        result.push({
            startDate: `${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getDate().toString().padStart(2, '0')}`,
            endDate: `${endDate.getFullYear()}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}-${endDate.getDate().toString().padStart(2, '0')}`,
        });
    }

    return result;
}

export const housekeeprApi = createApi({
    reducerPath: 'housekeeprApi',
    baseQuery: baseQuery,
    tagTypes: ['UserData', 'Codes', 'Availability'],
    refetchOnMountOrArgChange: 60,
    endpoints: builder => ({
        getServiceAvailability: builder.query<Availability[], AvailabilityRequest>({
            query: data => ({
                url: `customer/availability`,
                method: 'POST',
                body: data
            }),
            providesTags: ['Availability'],
            transformResponse: value => transformResponse<AvailabilityResponse>(value).options
        }),

        getServiceAvailabilitySplit: builder.query<Availability[], AvailabilityRequestForChunking>({
            queryFn: async (
                data,
                api,
                extraOptions,
                fetchBaseQuery,
            ) => {
                const periods = splitSearchPeriod(data.search_period, data.search_chunks);

                const responses = await Promise.all(periods.map(async period => {
                    const response = await fetchBaseQuery({
                        url: 'customer/availability',
                        method: 'POST',
                        body: {
                            ...data,
                            search_period_start: period.startDate,
                            search_period_end: period.endDate,
                        },
                    });

                    if (response.error) {
                        console.error('Error fetching service availability:', response.error);
                        return [] as Availability[];
                    }
                    return transformResponse<AvailabilityResponse>(response.data).options;
                }));

                const allData = responses.flat(); // Flatten arrays of availability data

                if (allData.length === 0) {
                    return {
                        error: {
                            status: 400,
                            data: {
                                error: 'Vi er desværre fuldt booket i dit område. Vi håber på at åbne op for flere tider snarest muligt. Hold øje med din indbakke, så sender vi en mail så snart vi åbner op for flere ledige tider.'
                            }
                        } as FetchBaseQueryError
                    };
                }

                return {data: allData as Availability[]}; // Ensure the correct return type
            },
            providesTags: ['Availability'],
        }),

        getServicePrice: builder.query<number, WindowCleaningServicePriceRequest | CleaningServicePriceRequest>({
            query: ({service_id, ...params}) => ({
                url: `services/${service_id}/price`,
                method: 'POST',
                body: params
            }),
            transformResponse: value => transformResponse<PriceResponse>(value).price
        }),

        getCleaningServicePrice: builder.query<number, CleaningServicePriceRequest>({
            query: ({service_id, ...params}) => ({
                url: `services/${service_id}/price`,
                params: params
            }),
            transformResponse: value => transformResponse<PriceResponse>(value).price
        }),

        getDawaArea: builder.query<AreaResponse, string>({
            query: dawaId => ({
                url: 'address/area',
                params: {
                    address_id: dawaId
                }
            }),
            transformResponse: transformResponse<AreaResponse>
        }),

        getExtraServices: builder.query<ExtraService[], number>({
            query: serviceId => `customer/services/${serviceId}/extraServices`,
            transformResponse: transformResponse<ExtraService[]>
        }),

        getWindowTypes: builder.query<WindowType[], number>({
            query: serviceId => `customer/services/${serviceId}/windowTypes`,
            transformResponse: transformResponse<WindowType[]>
        }),

        getResidenceTypes: builder.query<ResidenceType[], void>({
            query: () => `customer/residenceTypes`,
            transformResponse: transformResponse<ResidenceType[]>
        }),

        getPaymentAvailability: builder.query<PaymentAvailabilityResponse, void>({
            query: () => 'customer/paymentMethods/available',
            extraOptions: {
                maxRetries: 20
            },
            providesTags: () => [{type: 'UserData'}]
        }),

        getPaymentIntervals: builder.query<PaymentInterval[], void>({
            query: () => 'customer/paymentIntervals',
            transformResponse: transformResponse<PaymentInterval[]>
        }),

        getServicesWithoutPrice: builder.query<Service[], ServicesRequest>({
            query: (data) => ({
                url: 'customer/services/filter',
                params: {
                    service_type_id: data.serviceTypeId,
                    parish: data.parish
                }
            }),
            transformResponse: transformResponse<Service[]>
        }),

        getServicesWithoutFilter: builder.query<Service[], number>({
            query: serviceTypeId => `customer/serviceTypes/${serviceTypeId}/services`,
            transformResponse: transformResponse<Service[]>
        }),

        getService: builder.query<Service, number>({
            query: serviceId => `customer/services/${serviceId}`,
            transformResponse: transformResponse<Service>
        }),

        getServiceType: builder.query<ServiceType, number>({
            query: serviceTypeId => `customer/serviceType/${serviceTypeId}`,
            transformResponse: transformResponse<ServiceType>
        }),

        getFrequencies: builder.query<Frequency[], number>({
            query: serviceId => `customer/services/${serviceId}/frequencies`,
            transformResponse: transformResponse<Frequency[]>
        }),

        getServiceTypes: builder.query<ServiceType[], void>({
            query: () => 'customer/serviceTypes',
            transformResponse: transformResponse<ServiceType[]>
        }),

        getTimeSlots: builder.query<TimeSlot[], number>({
            query: serviceTypeId => `customer/serviceTypes/${serviceTypeId}/timeSlots`,
            transformResponse: transformResponse<TimeSlot[]>
        }),

        registerAddress: builder.query<Address, { darId: string, area?: number }>({
            query: ({darId, area}) => ({
                url: 'address',
                method: 'POST',
                body: {
                    address_id: darId,
                    area: area
                }
            }),
            transformResponse: transformResponse<Address>,
        }),

        checkCode: builder.query<CheckCodeResponse, CheckCodeRequest>({
            query: (request) => ({
                url: 'customer/check-code',
                params: request
            }),
            transformResponse: transformResponse<CheckCodeResponse>,
            providesTags: () => [{type: 'Codes'}]
        }),

        registerUser: builder.mutation<RegisterUserResponse, RegisterUserRequest>({
            query: data => ({
                url: 'customer/register',
                method: 'POST',
                body: data
            }),
            transformResponse: transformResponse<RegisterUserResponse>
        }),

        login: builder.mutation<LoginResponse, LoginRequest>({
            query: data => ({
                url: 'login',
                method: 'POST',
                body: {
                    ...data,
                    origin: 'customer-website'
                }
            }),
            transformResponse: transformResponse<LoginResponse>,
            invalidatesTags: [{type: 'UserData'}],
        }),

        checkToken: builder.query<LoginResponse, void>({
            query: () => 'check-token',
            transformResponse: transformResponse<LoginResponse>,
            providesTags: () => [{type: 'UserData'}]
        }),

        storeCleaningOrder: builder.mutation<CleaningOrderResponse, CleaningOrderRequest>({
            query: data => ({
                url: 'customer/cleaningOrders',
                method: 'POST',
                body: data
            }),
            transformResponse: transformResponse<CleaningOrderResponse>
        }),

        serviceOrder: builder.query<CleaningOrderResponse | WindowCleaningOrderResponse, CleaningOrderRequest | WindowCleaningOrderRequest>({
            query: data => ({
                url: 'customer/orders',
                method: 'POST',
                body: data
            }),
            transformResponse: transformResponse<CleaningOrderResponse | WindowCleaningOrderResponse>,
            providesTags: () => [{type: 'UserData'}]
        }),

        storeServiceOrder: builder.mutation<CleaningOrderResponse | WindowCleaningOrderResponse, CleaningOrderRequest | WindowCleaningOrderRequest>({
            query: data => ({
                url: 'customer/orders',
                method: 'POST',
                body: data
            }),
            transformResponse: transformResponse<CleaningOrderResponse | WindowCleaningOrderResponse>
        }),

        getLocation: builder.query<Location, StoreLocationRequest>({
            query: ({dawaId, area}) => ({
                url: 'customer/locations',
                method: 'POST',
                body: {
                    address_id: dawaId,
                    area: area
                }
            }),
            transformResponse: transformResponse<Location>,
            providesTags: () => [{type: 'UserData'}]
        }),

        storeLocation: builder.mutation<Location, StoreLocationRequest>({
            query: ({dawaId, area}) => ({
                url: 'customer/locations',
                method: 'POST',
                body: {
                    address_id: dawaId,
                    area: area
                }
            }),
            transformResponse: transformResponse<Location>,
        }),

        storePaymentMethod: builder.mutation<PaymentMethodResponse, { type: string, continue_url?: string }>({
            query: data => ({
                url: 'customer/paymentMethods',
                method: 'POST',
                body: {
                    ...data
                }
            })
        }),

        subscribeToNewsletter: builder.mutation<'success', NewsletterSubscriptionRequest>({
            query: data => ({
                url: 'notifications/sign-up',
                method: 'POST',
                body: data
            })
        }),

        getBbrUnit: builder.query<{
            enh026EnhedensSamledeAreal?: number,
            enh020EnhedensAnvendelse?: string
        }[], string>({
            query: addressId => ({
                url: 'bbrlight/enheder',
                params: {
                    adresseid: addressId
                }
            })
        })
    })
});
