import FadeIn from '@crm/components/animations/fade-in';
import Typography from '@crm/components/typography';
import {Close} from '@crm/utils/images';
import React, {PropsWithChildren, useEffect, useState} from 'react';
import {useTheme} from 'styled-components';
import {NewsletterSubscription} from "@crm/components/newsletter-subscription/newsletter-subscription";
import {NewsletterSubscriptionAbout} from "@crm/api/models/requests/housekeepr-api";

const Container = (props: PropsWithChildren<{ mobile: boolean, lightBlue?: boolean }>) => {
    const {children, mobile, lightBlue} = props;
    return <>
        {mobile ? <div className="bottom-[85px] flex justify-center w-full left-0">
            <div
                className={`${lightBlue ? 'bg-lightTurquoise' : 'bg-lighterTurquoise'} shadow-xl rounded-lg flex flex-col w-full`}>
                {children}
            </div>
        </div> : <div className="w-full bg-lightTurquoise shadow-xl rounded-lg bottom-[85px] flex flex-col">
            {children}
        </div>}
    </>;
};

export const InvalidAddressDialog = (props: {
    mobile?: boolean;
    address?: string;
    serviceName?: string;
    resetSignal?: any;
    lightBlue?: boolean;
    onClick?: () => void;
    hideCloseButton?: boolean;
}) => {
    const theme = useTheme();
    const [show, setShow] = useState(true);
    const {address, mobile = false, resetSignal} = props;

    useEffect(() => setShow(true), [resetSignal]);

    return <>
        {show ? <FadeIn>
            <div className="pt-6 w-full">
                <Container mobile={mobile} lightBlue={props.lightBlue}>
                    <div className="flex mt-5 mb-2 ml-5 mr-[0.95rem] justify-between items-center">
                        <Typography variant="body" fontWeight="bold" className="pt-0">
                            Ingen services i dit område.
                        </Typography>
                        {!props.hideCloseButton &&
                            <button onClick={() => setShow(false)}>
                                <Close className="fill-darkGreen hover:fill-white scale-[0.75]"/>
                            </button>
                        }
                    </div>
                    <Typography variant="body" className="p-5 pt-0 pb-2">
                        Vi tilbyder desværre
                        endnu {props.serviceName ? `ikke ${props.serviceName}` : 'ingen services'} på adressen,&nbsp;
                        <span className="text-red">{address}</span>.
                    </Typography>

                    <div className="bg-opacity-80 rounded-b-lg flex py-5 justify-center">
                        <NewsletterSubscription
                            newsAbout={NewsletterSubscriptionAbout.WINDOW_CLEANING}
                            textColor={theme.palette.common.lightTurquoise}
                            backgroundColor={theme.palette.common.darkBlue}
                            $phoneInput={true}
                            initialButtonText={'Få besked når service er tilgængelig'}
                            openDown={true}
                        />
                    </div>
                </Container>
            </div>
        </FadeIn> : <></>}
    </>;
};
