import {Breakpoint, BreakPoints} from '../theme/light-theme';

interface Props {
    theme: BreakPoints;
}

export const up =
    (size: Breakpoint | number) =>
        (props: Props): string => {
            const value = typeof size === 'number' ? size : props.theme.breakpoints.values[size];
            return `@media (min-width:${value}px)`;
        };

export const down =
    (size: Breakpoint | number) =>
        (props: Props): string => {
            const value = typeof size === 'number' ? size : props.theme.breakpoints.values[size];
            return `@media (max-width:${value}px)`;
        };

export const between =
    (start: Breakpoint | number, end: Breakpoint | number) =>
        (props: Props): string => {
            const startValue = typeof start === 'number' ? start : props.theme.breakpoints.values[start];
            const endValue = typeof end === 'number' ? end : props.theme.breakpoints.values[end];
            return `@media (min-width:${startValue}px) and (max-width:${endValue}px)`;
        };

export const only =
    (size: Breakpoint) =>
        (props: Props): string => {
            const keys = Object.keys(props.theme.breakpoints.values);

            if (keys.indexOf(size) + 1 < keys.length) {
                return between(size, keys[keys.indexOf(size) + 1] as Breakpoint)(props);
            }

            return up(size)(props);
        };
