import React from 'react';
import styled, {css} from 'styled-components';
import {
    GLOBAL_HORIZONTAL_PADDING,
    GLOBAL_HORIZONTAL_PADDING_MOBILE,
    MAX_WRAP_WIDTH,
    MAX_WRAP_WIDTH_TABLET
} from '@crm/utils/constants';

export enum horizontalSpacingType {
    MOBILE_HORIZONTAL_SCROLL = 'mobileHorizontalScroll'
}

interface Props {
    children: React.ReactNode | React.ReactNode[];
    className?: string;
    type?: horizontalSpacingType.MOBILE_HORIZONTAL_SCROLL;
}

export const globalMobilePadding = GLOBAL_HORIZONTAL_PADDING_MOBILE + 'px';

export const horizontalSpacingStyles = css`
  padding: 0 ${GLOBAL_HORIZONTAL_PADDING}px;

  ${({theme}) => theme.breakpoints.down('mobile')} {
    padding: 0 ${globalMobilePadding};
  }

  max-width: ${({theme}) => theme.breakpoints.values.largeScreen}px;
  margin: 0 auto;
  width: 100%;
  max-width: ${MAX_WRAP_WIDTH}px;

  ${({theme}) => theme.breakpoints.down('tablet')} {
    max-width: ${MAX_WRAP_WIDTH_TABLET}px;
  }
`;

const mobileHorizontalScrollStyles = css`
  ${({theme}) => theme.breakpoints.down('mobile')} {
    overflow: auto;
  }
`;

const HorizontalSpacingRoot = styled.div<{ type?: string }>`
  ${horizontalSpacingStyles}
  ${props =>
    props.type == horizontalSpacingType.MOBILE_HORIZONTAL_SCROLL && mobileHorizontalScrollStyles}
`;

const HorizontalSpacing: React.FC<Props> = (props: Props) =>
    <HorizontalSpacingRoot type={props.type} className={props.className}>
        {props.children}
    </HorizontalSpacingRoot>;

export default HorizontalSpacing;
