import React from 'react';
import {CallMeContext, CallMeContextValues} from "@crm/contexts/call-me-context/call-me-context";

export const useCallMe = (): CallMeContextValues => {
    const values = React.useContext(CallMeContext);

    return {...values};
};

export default useCallMe;
