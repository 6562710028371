export interface Route {
    title?: string;
    href: string;
    hideForMobile?: boolean;
    showInNavigation?: boolean;
}

type Routes = Record<string, Route>;

export const routes: Routes = {
    cleaning: {
        href: '/rengoering',
        title: 'Rengøring',
        showInNavigation: true,
    },
    windowCleaning: {
        href: '/vinduespudsning',
        title: 'Vinduespudsning',
        showInNavigation: true,
    },
    services: {
        href: '/services',
        title: 'Services',
        showInNavigation: true,
    },
    miljøOgKvalitet: {
        href: '/baeredygtighed',
        title: 'Bæredygtighed',
        showInNavigation: true,
    },
    kontakt: {
        href: `/#kontakt`,
        title: 'Kontakt',
        showInNavigation: true,
    },
    spørgsmålOgSvar: {
        href: '/spoergsmaal-og-svar',
        // title: 'FAQ'
    },
    bestilOgBeregn: {
        href: '/#bestil-og-beregn',
        title: 'Bestil & Beregn',
        showInNavigation: true,
        hideForMobile: true,
    },
    becomeHousekeepr: {
        href: '/bliv-housekeepr',
        title: 'Bliv Housekeepr',
        showInNavigation: true,
    },
    becomeSubcontractor: {
        href: '/bliv-underleverandoer',
        title: 'Bliv underleverandør',
        showInNavigation: true,
    },
    chooseServiceAddress: {
        href: '/order-pages/choose-address-page',
    },
    chooseServiceType: {
        href: '/order-pages/choose-service-type-page',
    },
    orderConfirmation: {
        href: '/order-pages/confirm-order',
    },
    privacyPolicy: {
        href: '/handelsbetingelser-og-privatlivspolitik#kunde-&-privatlivspolitik',
    },
    bussPolicy: {
        href: '/handelsbetingelser-og-privatlivspolitik#handelsbetingelser-for-Housekeepr-ApS',
    },
    cookiesPolicy: {
        href: '/handelsbetingelser-og-privatlivspolitik#cookies-header',
    },
    cleaningInYourTown: {
        href: '/rengoering#housekeepr-i-din-by',
    },
    orderCleaning: {
        href: '/rengoering/bestil',
        title: 'Bestil rengøring',
        showInNavigation: true,
    },
    orderWindowCleaning: {
        href: '/vinduespudsning/bestil',
        title: 'Bestil vinduespudsning',
        showInNavigation: true,
    },
};

export const servicesAnchorRoutes = {
    rengøring: {
        href: `${routes.services.href}#rengoering`,
        title: 'Rengøring'
    },
    vinduespudsning: {
        href: `${routes.services.href}#vinduespudsning`,
        title: 'Vinduespudsning'
    },
    produkterViaAbonnement: {
        href: `${routes.services.href}#produkter-via-abonnement`,
        title: 'Produkter via abonnement'
    },
    tæpperens: {
        href: `${routes.services.href}#taepperens`,
        title: 'Tæpperens'
    },
    tøjrensOgVask: {
        href: `${routes.services.href}#toejrens-og-vask`,
        title: 'Tøjrens/vask'
    },
    græsHækHave: {
        href: `${routes.services.href}#graes-haek-have`,
        title: 'Græs, hæk, have'
    },
    handyman: {
        href: `${routes.services.href}#handyman`,
        title: 'Handyman'
    }
};
