import React, {useState} from 'react';
import styled, {useTheme} from 'styled-components';

interface Props {
    $initialCheck?: boolean;
    id: string;
    colorTheme?: {
        circle: string;
        checkmark: string;
    };

    onToggle(val: boolean, id: string): any;
}

interface CircleBoxProps {
    $bgColor: string;
}

const Root = styled.div`
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    cursor: pointer;
`;

const CircleBox = styled.div<{ $bgColor: string }>`
    width: 20px;
    height: 20px;
    background-color: ${props => props.$bgColor};
    border-radius: 100%;
`;

const CheckMark = styled.div<CircleBoxProps>`
    width: 60%;
    height: 60%;
    position: absolute;
    top: 20%;
    left: 20%;
    background-color: ${props => props.$bgColor};
    border-radius: 100%;
`;

export const CheckBoxCircle = (props: Props) => {
    const theme = useTheme();
    const [checked, setChecked] = useState<boolean>(props.$initialCheck ?? false);
    const {
        colorTheme = {
            checkmark: theme.palette.common.darkBlue,
            circle: theme.palette.common.lightTurquoise
        },
        onToggle,
        ...rest
    } = props;

    const handleToggleCheck = () => {
        const toggled = !checked;
        setChecked(toggled);
        onToggle(toggled, props.id);
    };

    return <Root onClick={handleToggleCheck} {...rest}>
        <CircleBox $bgColor={colorTheme.circle}/>
        {checked && <CheckMark $bgColor={colorTheme.checkmark}/>}
    </Root>;
};

export default CheckBoxCircle;
