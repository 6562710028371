import orderStyles from 'styles/order.module.css';
import {smoothScrollToTop} from '@crm/utils/helpers';
import React, {useEffect} from 'react';
import {housekeeprApi} from '@crm/api/housekeepr-api';
import {useAppDispatch, useAppSelector} from '@crm/services/store';
import {skipToken} from '@reduxjs/toolkit/query';
import {cleaningActions, cleaningSelectors} from '@crm/services/cleaning-slice';
import {frequencyDisplayString, paymentIntervalDisplayString} from '@crm/api/models/common/housekeepr-api';
import {
    InvalidAddressDialog
} from "@crm/components/layout/landing-page/order-wizard/steps/address-lookup/invalid-address-dialog";

type CleaningProgrammeCardProps = { hidden?: boolean };

export const CleaningProgrammeCard = (props: CleaningProgrammeCardProps) => {
    const dispatch = useAppDispatch();

    const canGoToNext = useAppSelector(cleaningSelectors.selectCanGoToNextStep);

    const serviceTypeId = useAppSelector(cleaningSelectors.selectServiceTypeId);
    const selectedAddress = useAppSelector(cleaningSelectors.selectAddress);
    const selectedServiceId = useAppSelector(cleaningSelectors.selectServiceId);
    const selectedFrequencyId = useAppSelector(cleaningSelectors.selectFrequencyId);
    const selectedPaymentIntervalId = useAppSelector(cleaningSelectors.selectPaymentIntervalId);
    const selectedIsRecurringService = useAppSelector(cleaningSelectors.selectIsRecurringService);

    const {
        data: serviceTypesResult
    } = housekeeprApi.useGetServiceTypesQuery();

    const {
        data: addressResult,
        error: addressError
    } = housekeeprApi.useRegisterAddressQuery(selectedAddress
        ? {
            darId: selectedAddress.adresse.id
        }
        : skipToken
    );

    const {
        data: filteredServicesResult
    } = housekeeprApi.useGetServicesWithoutPriceQuery(serviceTypeId && addressResult
        ? {
            serviceTypeId,
            parish: addressResult.parish
        }
        : skipToken
    );

    const services = filteredServicesResult;

    const serviceType = serviceTypeId ? serviceTypesResult?.find(serviceType => serviceType.id == serviceTypeId) : serviceTypesResult?.find(serviceType => serviceType.type == 'cleaning');

    const {
        data: frequenciesResult
    } = housekeeprApi.useGetFrequenciesQuery(selectedServiceId ?? skipToken);

    const frequencies = frequenciesResult?.filter(frequency => frequency.public);

    const hasFrequencies = frequencies != null && frequencies.length > 1;

    const {
        data: paymentIntervalsResult
    } = housekeeprApi.useGetPaymentIntervalsQuery(hasFrequencies ? undefined : skipToken);

    const showPaymentIntervals = paymentIntervalsResult && paymentIntervalsResult.length > 1;

    useEffect(() => {
        if (paymentIntervalsResult && paymentIntervalsResult.length == 1 && selectedPaymentIntervalId != paymentIntervalsResult[0].id) {
            dispatch(cleaningActions.setPaymentInterval(paymentIntervalsResult[0]));
        }
    }, [dispatch, paymentIntervalsResult, selectedPaymentIntervalId]);

    useEffect(() => {
            if (selectedServiceId && filteredServicesResult && !filteredServicesResult.some(service => service.id == selectedServiceId)) {
                dispatch(cleaningActions.clearService());
            } else if (!selectedServiceId && filteredServicesResult && filteredServicesResult.length >= 1 && selectedAddress) {
                dispatch(cleaningActions.setService(filteredServicesResult[0]));
            }
        },
        [serviceTypeId, serviceType, dispatch, selectedServiceId, filteredServicesResult, selectedAddress]
    );

    useEffect(() => {
        if (!addressResult && !!addressError && selectedServiceId) {
            dispatch(cleaningActions.clearService());
        }
    }, [addressResult, addressError, selectedAddress, dispatch, selectedServiceId]);

    useEffect(() => {
        if (selectedIsRecurringService && !selectedPaymentIntervalId && paymentIntervalsResult && paymentIntervalsResult.length >= 1) {
            const paymentInterval = paymentIntervalsResult.find(paymentInterval => paymentInterval.months == 6);
            if (paymentInterval) {
                dispatch(cleaningActions.setPaymentInterval(paymentInterval));
            }
        }
    }, [addressResult, addressError, selectedAddress, dispatch, selectedServiceId, selectedPaymentIntervalId, paymentIntervalsResult, hasFrequencies, selectedIsRecurringService]);


    return <div className={props.hidden ? orderStyles.hidden : orderStyles.cardWrapper}>
        {services && services.length == 0
            ? <div className={orderStyles.card}>
                <div className={orderStyles.orderSection}>
                    <h1 className={`${orderStyles.cardH1}`}>
                        Hvilken service?
                    </h1>
                    <div
                        id={'service-content'}
                        className={`${orderStyles.orderSectionContent} ${orderStyles.removeTopMargin}`}>
                        <InvalidAddressDialog
                            mobile={true}
                            lightBlue={true}
                            serviceName={`rengøring`}
                            address={selectedAddress?.tekst}
                            hideCloseButton={true}
                        />
                    </div>
                </div>
            </div>
            : <div className={orderStyles.card}>
                <div className={orderStyles.orderSection}>
                    <h1 className={`${orderStyles.cardH1}`}>
                        Hvilken service?
                    </h1>
                    <p
                        className={`${orderStyles.orderSectionContent} ${orderStyles.removeTopMargin}`}
                    >
                        Få én gratis time ekstra på dit første besøg, når du bestiller <span style={{fontWeight: 500}}>Fast rengøring</span>.
                    </p>
                    <div
                        id={'service-content'}
                        className={`${orderStyles.orderSectionContent} ${orderStyles.flexRow} ${orderStyles.flexWrap}`}>
                        {services?.map(service => {
                            return (
                                <div
                                    className={`${orderStyles.cardOption} ${selectedServiceId == service.id ? orderStyles.selected : ''}`}
                                    onClick={() => dispatch(cleaningActions.setService(service))}
                                    key={service.id}
                                    id={`service-${service.id}`}
                                >
                                    {service.name}
                                </div>
                            );
                        })}
                    </div>
                </div>
                {hasFrequencies &&
                    <>
                        <div className={orderStyles.orderSection}>
                            <h1 className={`${orderStyles.cardH1}`}>
                                Hvor ofte skal vi komme?
                            </h1>
                            <p
                                className={`${orderStyles.orderSectionContent} ${orderStyles.removeTopMargin}`}
                            >
                                Hvor ofte vil du have rengøring. Vi anbefaler hver 2. uge.
                            </p>
                            <div
                                id={'frequency-content'}
                                className={`${orderStyles.orderSectionContent} ${orderStyles.flexRow} ${orderStyles.flexWrap}`}>
                                {frequencies?.map(frequency => {
                                    return (
                                        <div
                                            className={`${orderStyles.cardOption} ${selectedFrequencyId == frequency.id ? orderStyles.selected : ''}`}
                                            onClick={() => dispatch(cleaningActions.setFrequency(frequency))}
                                            key={frequency.id}
                                            id={`frequency-${frequency.id}`}
                                        >
                                            {frequencyDisplayString(frequency)}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        {showPaymentIntervals &&
                            <div className={orderStyles.orderSection}>
                                <h1 className={`${orderStyles.cardH1}`}>
                                    Vælg betalingsinterval
                                </h1>
                                <div
                                    id={'payment-interval-content'}
                                    className={`${orderStyles.orderSectionContent} ${orderStyles.removeTopMargin} ${orderStyles.flexRow} ${orderStyles.flexWrap} `}>
                                    Jo længere betalingsinterval, jo større rabat. Vælger du eksempelvis 6 måneders
                                    betalingsinterval, betaler du for 6 måneders besøg af gangen, og får samtidig 15%
                                    rabat. Perioden betales forud.
                                    {paymentIntervalsResult?.map(paymentInterval => {
                                        return (
                                            <div
                                                className={`${orderStyles.cardOption} ${selectedPaymentIntervalId == paymentInterval.id ? orderStyles.selected : ''}`}
                                                onClick={() => dispatch(cleaningActions.setPaymentInterval(paymentInterval))}
                                                key={paymentInterval.id}
                                                id={`payment-interval-${paymentInterval.id}`}
                                            >
                                                {paymentIntervalDisplayString(paymentInterval)}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        }
                    </>
                }
            </div>

        }
        <div className={`${orderStyles.flexRow} ${orderStyles.flexMainSpaceBetween} self-stretch`}>
            <div
                className={`${orderStyles.buttonWrapper} ${orderStyles.outline} w-[200px]`}>
                <button
                    onClick={() => {
                        dispatch(cleaningActions.previousPage());
                        smoothScrollToTop();
                    }}
                >
                    Tilbage
                </button>
            </div>
            <div
                className={`${orderStyles.buttonWrapper} ${orderStyles.outline} ${canGoToNext ? '' : orderStyles.disabled} w-[200px]`}>
                <button
                    onClick={() => {
                        dispatch(cleaningActions.nextPage());
                        smoothScrollToTop();
                    }}
                    disabled={!canGoToNext}
                >
                    Videre
                </button>
            </div>
        </div>
    </div>;
};

export default CleaningProgrammeCard;