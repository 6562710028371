import React, {useState} from 'react';
import styled, {css, useTheme} from 'styled-components';
import {AutocompleteAddress, DawaAddress, DawaAutocomplete} from '@crm/api/models/common/dawa-api';
import {Arrow} from '../../utils/images';
import LoadingIndicator from '../icon/loading-indicator';
import TextField from '../text-field';
import Typography from '../typography';
import {useAutocompleteQuery} from "@crm/api/dawa-api";
import {wizardActions, wizardSelectors} from "@crm/services/wizard-slice";
import {skipToken} from "@reduxjs/toolkit/query";
import {useAppDispatch, useAppSelector} from "@crm/services/store";
import {TextFieldRef} from "@crm/components/text-field/text-field";

const AddressFieldRoot = styled.div`
    display: block;
    width: 100%;
    position: relative;
`;

const AddressFieldResults = styled.ul`
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    background-color: #b2d6d9;
    border-top: none;
    z-index: 5;
    transition: all 0.2s ease;
    max-height: 300px;
    overflow-y: auto;

    ${({theme}) => theme.breakpoints.down('mobile')} {
        font-size: 14px;
        max-height: 150px;
    }
`;

const AddressFieldResult = styled.li<{ focus: boolean }>`
    cursor: pointer;
    font-family: ${({theme}) => theme.typography.secondary.fontFamily};
    padding: 8px 13px;

    border-top: 1px solid ${({theme}) => theme.palette.common.darkBlue};

    background-color: ${props =>
            props.focus ? ({theme}) => theme.palette.common.turquoise : ``};

    &:hover {
        background-color: ${({theme}) => theme.palette.common.turquoise};
    }
`;

export const AddressFieldTextField = styled(TextField)<{ $hasError?: boolean | string }>`
    border-color: ${({$hasError}) => $hasError ? 'red' : 'initial'};

    ${({theme}) => theme.breakpoints.down('mobile')} {
        & input {
            font-size: 14px;
        }
    }
`;

const Wrapper = styled.div`
    width: 100%;
    overflow: hidden;
    position: absolute;
    border-radius: 0 0 10px 10px;
    margin-top: -8px;
    z-index: 30;
`;

interface Props {
    label?: string;
    name?: string;
    autoScrollOff?: boolean;
    mobile?: boolean;
    hideOptions?: boolean;
    hideArrow?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onClick?: (result: AutocompleteAddress) => void;
}

const endAdornmentStyles = css`
    width: 25px;
    pointer-events: none;
    margin-right: 15px;

    path {
        fill: #2A4D4F;
    }
`;

export const AddressField: React.FC<Props> = (props: Props) => {
    const dispatch = useAppDispatch();
    const {
        hideOptions,
        name,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onClick,
        mobile,
        autoScrollOff,
        ...rest
    } = props;
    const rootRef = React.useRef<HTMLDivElement>(null);
    const addressListRef = React.useRef<HTMLUListElement>(null);
    const textFieldRef = React.useRef<TextFieldRef>(null);

    const isMounted = React.useRef(false);
    const theme = useTheme();

    const searchAddressInput = useAppSelector(wizardSelectors.selectAddressInput);
    const searchAddress = useAppSelector(wizardSelectors.selectAddress);
    const caretPos = useAppSelector(wizardSelectors.selectAddressInputCaretPos);

    const [selectedIndex, setSelectedIndex] = useState<number | undefined>();
    const [selectedOption, setSelectedOption] = useState<DawaAutocomplete | undefined>(searchAddress !== undefined
        ? {
            type: 'adresse',
            tekst: searchAddress.tekst,
            forslagstekst: searchAddress.tekst,
            caretpos: 0,
            data: searchAddress.adresse,
        } as DawaAutocomplete
        : undefined
    );


    const {
        data: addressResults,
        error: addressesError,
        isLoading: isSearching
    } = useAutocompleteQuery(searchAddressInput.length > 1
        ? {
            q: searchAddressInput,
            caretpos: caretPos?.start,
            startfra: selectedOption?.type,
            type: 'adresse',
            fuzzy: true,
        }
        : skipToken
    );

    const displayDropdown = !hideOptions && (searchAddressInput?.length ?? 0) > 0 && (addressResults?.length ?? 0) > 0;

    React.useEffect(() => {
        if (!isMounted.current && rootRef.current && displayDropdown && !autoScrollOff) {
            isMounted.current = true;
            rootRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            });
        }
    }, [isMounted, rootRef, displayDropdown, autoScrollOff]);

    const handleInputChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(wizardActions.setAddressInput({
                text: event.target.value,
                caretPosStart: event.target.selectionStart ?? undefined,
                caretPosEnd: event.target.selectionEnd ?? undefined,
            }));
            setSelectedIndex(undefined);
            if (selectedOption && event.target.value.length < selectedOption.tekst.length) {
                setSelectedOption(undefined);
            }
        },
        [dispatch, selectedOption]
    );

    const selectOption = React.useCallback(
        (option: DawaAutocomplete) => {
            setSelectedOption(option);
            setSelectedIndex(undefined);
            dispatch(wizardActions.setFromAutocomplete(option));

            if (props.onClick && option.type == 'adresse') {
                const address = option.data as DawaAddress;
                props.onClick?.({
                    tekst: option.tekst,
                    adresse: address,
                });
            }
        },
        [dispatch, props]
    )

    const errorText = () => {
        if (!addressesError) {
            return undefined;
        }

        if ('message' in addressesError) {
            return addressesError.message;
        }

        if ('status' in addressesError) {
            switch (addressesError.status) {
                case 'FETCH_ERROR':
                    return 'Hov, der er sket en fejl. Vi kunne ikke oprette forbindelse til adressesøgning.\nPrøv igen senere, så burde der være forbindelse igen.';
            }
        }

        return undefined;
    }

    const scrollIntoView = (element?: Element) => {
        if (!element || !element.parentElement) {
            return;
        }

        const parent = element.parentElement;
        const parentBoundingRect = parent.getBoundingClientRect();
        const boundingRect = element.getBoundingClientRect();

        if (boundingRect.bottom > parentBoundingRect.bottom || boundingRect.top < parentBoundingRect.top) {
            parent.scrollTo({
                top: (element as HTMLElement).offsetTop - 5,
            })
        }
    }
    const handleKeyDown = (e: React.KeyboardEvent) => {
        // arrow up/down button should select next/previous list element
        if (!addressResults?.length) {
            return;
        }

        if (e.key === 'ArrowUp') {
            const newIndex = Math.min(addressResults.length - 1, Math.max(0, (selectedIndex ?? 1) - 1))
            if (selectedIndex != newIndex) {
                setSelectedIndex(newIndex);
            }
            scrollIntoView(addressListRef.current?.children.item(newIndex) ?? undefined);
            e.preventDefault();
        } else if (e.key === 'ArrowDown') {
            const newIndex = Math.min(addressResults.length - 1, Math.max(0, (selectedIndex ?? -1) + 1))
            if (selectedIndex != newIndex) {
                setSelectedIndex(newIndex);
            }
            scrollIntoView(addressListRef.current?.children.item(newIndex) ?? undefined);
            e.preventDefault();
        } else if (e.key === 'Enter' && selectedIndex != null) {
            selectOption(addressResults[selectedIndex]);
        }
    };

    const handleBlur = React.useCallback(() => setSelectedIndex(undefined), []);

    return <AddressFieldRoot ref={rootRef} {...rest}>
        <AddressFieldTextField
            $hasError={addressesError !== undefined}
            value={searchAddressInput}
            caretPos={caretPos}
            name={name}
            autoComplete="off"
            onChange={handleInputChange}
            placeholder="Indtast adresse"
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            styles={{
                inputField: `font-family: ${theme.typography.secondary.fontFamily}`,
                endAdornment: mobile ? 'display: none' : endAdornmentStyles.toString(),
                root: `${displayDropdown && 'border-radius: 10px 10px 0px 0px;'}`
            }}
            endAdornment={isSearching
                ? <LoadingIndicator/>
                : props.hideArrow
                    ? undefined
                    : <Arrow/>
            }
            ref={textFieldRef}
            data-cy={'address-field'}
        />
        {displayDropdown &&
            <Wrapper>
                <AddressFieldResults ref={addressListRef}>
                    {addressResults!.map((result, index) =>
                        <AddressFieldResult
                            key={result.forslagstekst}
                            onMouseDown={() => selectOption(result)}
                            focus={selectedIndex == index}
                            data-cy={'address-option'}
                        >
                            {result.forslagstekst}
                        </AddressFieldResult>
                    )}
                </AddressFieldResults>
            </Wrapper>
        }
        {addressesError &&
            <Typography style={{
                marginTop: 'min(30px, 2%)',
                whiteSpace: 'pre-line',
                color: '#CC3E0C',
            }}>
                {errorText() ?? 'Der opstod en fejl. Prøv igen senere.'}
            </Typography>
        }
    </AddressFieldRoot>;
};

export default AddressField;
