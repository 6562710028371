import {Step} from "@crm/models/step";
import React from "react";
import CleaningUserCard from "@crm/components/cleaning/cleaning-user-card";
import {CleaningState} from "@crm/services/cleaning-slice";

export const CLEANING_STEP_USER = 'user';

export class CleaningStepUser extends Step {
    public getIdentifier(): string {
        return CLEANING_STEP_USER;
    }

    public getName(): string {
        return 'Kundeoplysninger';
    }

    public getShortName(): string {
        return 'Kunde';
    }

    public isComplete(state: CleaningState): boolean {
        return state.authenticated;
    }

    public buildPage(currentPage: string): React.JSX.Element {
        return <CleaningUserCard
            key={this.getIdentifier()}
            hidden={currentPage !== this.getIdentifier()}
        />;
    }
}