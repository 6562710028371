import React, {createRef, ForwardedRef, forwardRef, useState} from 'react';
import styled, {css, keyframes} from 'styled-components';
import FadeIn from '../animations/fade-in';
import {getWindowDimensions} from "@crm/utils/helpers";

export interface TextFieldRootProps {
    $styles?: string;
    $focused: boolean;
    $error?: string | boolean;
}

const BORDER_RADIUS = '10px';

export const TextFieldRoot = styled.div<TextFieldRootProps>`
    display: inline-block;
    position: relative;
    width: 100%;
    min-width: 0;
    padding: 0;
    border: 0;
    vertical-align: top;
    transition: box-shadow ease-in-out 200ms;

    margin: 8px 0;
    background-color: ${({theme}) => theme.palette.common.lightTurquoise};
    border-radius: ${BORDER_RADIUS};

    ${props => props.$styles};
`;

export interface TextFieldInputProps {
    $error?: string | boolean;
    styles?: string;
}

export const TextFieldInput = styled.div<TextFieldInputProps>`
    display: flex;
    align-items: center;
    border-radius: ${BORDER_RADIUS};
    ${({$error, theme}) =>
            $error
                    ? css`
                        border-color: ${theme.palette.error.main};
                    `
                    : css``
    };

    ${props => props.styles};
`;

export interface TextFieldInputFieldProps {
    $styles?: string;
    $displayError?: boolean
    $errorOutsidePlaceholder?: string
    $errorTooltip?: string
}

const onAutoFillStart = keyframes``;
const onAutoFillCancel = keyframes``;

export const TextFieldInputField = styled.input<TextFieldInputFieldProps>`
    background: none;
    outline: none;
    border-radius: ${BORDER_RADIUS};
    border: ${props => ({theme}) => props.$errorTooltip ? theme.palette.error.main : theme.palette.common.lightTurquoise} 1px solid;

    width: 100%;
    padding: 6px 13px;
    font-family: ${({theme}) => theme.typography.body.fontFamily};

    font-size: 16px;
    color: ${({theme, disabled}) => disabled
            ? theme.palette.text.secondary
            : theme.palette.text.primary};

    /* Change Autocomplete styles in Chrome*/

    &:-webkit-autofill {
        -webkit-text-fill-color: ${props => props.theme.palette.text.primary};
        box-shadow: 0 0 0 1000px ${props => props.theme.palette.background.default} inset;
        transition: background-color 5000s ease-in-out 0s;
        animation-name: ${onAutoFillStart};
    }

    &:not:-webkit-autofill {
        animation-name: ${onAutoFillCancel};
    }

    &::placeholder {
        color: ${props => ({theme}) => !props.$displayError ? theme.palette.common.darkBlue : theme.palette.error.main};
        opacity: 0.5;
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${({theme}) => theme.palette.common.darkBlue};
        opacity: 0.5;
    }

    &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${({theme}) => theme.palette.common.darkBlue};
        opacity: 0.5;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px ${({theme}) => theme.palette.common.lightTurquoise} inset !important;
    }

    ${props => props.$styles};
`;

export interface TextFieldEndAdornmentProps {
    styles?: string;
}

export const TextFieldLabel = styled.label`
    display: none;
`;

export const TextFieldEndAdornment = styled.span<TextFieldEndAdornmentProps>`
    margin-left: 12px;

    ${props => props.styles};
`;

export interface TextFieldErrorProps {
    styles?: string;
    heightFromRef: number;
}

export const TextFieldError = styled(FadeIn)<TextFieldErrorProps>`
    //margin-top: -13px;
    //line-height: 1.66;
    height: 18px;
    top: ${props => props.heightFromRef + 15}px;
    color: ${({theme}) => theme.palette.error.main};
    ${({theme}) => theme.typography.secondary};
    font-size: 14px;

    ${props => props.styles};
`;

export interface TextFieldProps {
    value?: string;
    caretPos?: {
        start: number,
        end?: number,
    };
    id?: string;
    disabled?: boolean;
    placeholder?: string;
    type?: 'text' | 'password' | 'email' | 'submit' | React.HTMLInputTypeAttribute;
    inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search' | undefined;
    error?: string;
    errorOutsidePlaceholder?: string;
    errorTooltip?: string;
    name?: string;
    autoComplete?: string | undefined;
    touched?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onKeyDown?: (event: React.KeyboardEvent) => void;
    endAdornment?: React.ReactNode | React.ReactNode[];
    inputProps?: any;
    tooltipStyle?: React.CSSProperties;
    styles?: {
        root?: string;
        error?: string;
        input?: string;
        inputField?: string;
        endAdornment?: string;
    };
    'data-cy'?: string;
}

export interface TextFieldRef {
    setCaretPos: (caretpos: number) => void;
    getText: () => string;
    setText: (text: string) => void;
}

export const TextField = forwardRef(function TextField(props: TextFieldProps, ref: ForwardedRef<TextFieldRef>) {
    const {
        value,
        caretPos,
        onChange,
        onBlur,
        type = 'text',
        inputMode,
        id,
        onFocus,
        onKeyDown,
        error,
        name,
        errorOutsidePlaceholder,
        errorTooltip,
        touched = false,
        endAdornment,
        inputProps,
        placeholder,
        disabled = false,
        styles,
        autoComplete,
        ...rest
    } = props;

    const [focused, setFocused] = React.useState(false);
    const [inputHeight, setInputHeight] = useState(0);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    const inputWrapperRef = createRef<HTMLDivElement>();
    const inputRef = createRef<HTMLInputElement>();
    const displayError = touched;

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        setFocused(true);

        onFocus?.(event);
    };

    React.useEffect(() => {
        if (caretPos && inputRef.current) {
            const currentStart = inputRef.current.selectionStart;
            const currentEnd = inputRef.current.selectionEnd;
            const targetStart = caretPos.start;
            const targetEnd = caretPos.end ?? caretPos.start;

            if (currentStart != targetStart || currentEnd != targetEnd) {
                inputRef.current.setSelectionRange(targetStart, targetEnd);
            }
        }
    }, [caretPos, inputRef]);

    const setCaretPos = (caretpos: number) => {
        if (inputRef.current) {
            inputRef.current.setSelectionRange(caretpos, caretpos);
            inputRef.current.select();
        }
    }

    const getText = () => {
        return inputRef.current?.value ?? '';
    }

    const setText = (text: string) => {
        if (inputRef.current) {
            inputRef.current.value = text;
        }
    }

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        setFocused(false);

        onBlur?.(event);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => onChange?.(event);

    React.useEffect(() => {
        if (inputWrapperRef.current) {
            setInputHeight(inputWrapperRef.current.clientHeight);
        }
    }, [inputWrapperRef])

    React.useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    React.useImperativeHandle(ref, () => ({
        setCaretPos,
        getText,
        setText,
    }));

    return <div style={{position: "relative"}}>
        <TextFieldRoot $styles={styles?.root} $error={displayError} $focused={focused} {...rest}>
            <TextFieldLabel htmlFor={id}>{placeholder}</TextFieldLabel>
            <TextFieldInput ref={inputWrapperRef} styles={styles?.input} $error={displayError}>
                <TextFieldInputField
                    $displayError={displayError}
                    onKeyDown={onKeyDown}
                    value={value}
                    placeholder={displayError ? error : placeholder}
                    type={type}
                    inputMode={inputMode}
                    id={id}
                    name={name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    data-testid={inputProps?.['data-testid']}
                    $styles={styles?.inputField}
                    disabled={disabled}
                    autoComplete={autoComplete}
                    $errorOutsidePlaceholder={errorOutsidePlaceholder}
                    $errorTooltip={errorTooltip}
                    ref={inputRef}
                    data-cy={props['data-cy']}
                />
                {endAdornment &&
                    <TextFieldEndAdornment styles={styles?.endAdornment}>
                        {endAdornment}
                    </TextFieldEndAdornment>
                }
            </TextFieldInput>
        </TextFieldRoot>
        {errorOutsidePlaceholder &&
            <TextFieldError
                heightFromRef={inputHeight}
                styles={styles?.error}
            >
                {errorOutsidePlaceholder}
            </TextFieldError>
        }
    </div>;
});

export default TextField;
