import React from 'react';

export interface MenuContextProps {
    children: React.ReactNode | React.ReactNode[];
}

export interface MenuContextValues {
    opened: boolean;
    handleOpen: () => void;
    handleClose: () => void;
}

export const MenuContext = React.createContext({} as MenuContextValues);

export const MenuProvider = (props: MenuContextProps): React.JSX.Element => {
    const {children} = props;

    const [opened, setOpened] = React.useState(false);

    const handleOpen = React.useCallback(() => setOpened(true), []);

    const handleClose = React.useCallback(() => setOpened(false), []);

    return <MenuContext.Provider value={{opened, handleOpen, handleClose}}>
        {children}
    </MenuContext.Provider>;
};

export default MenuContext;
