import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AutocompleteAddress} from "@crm/api/models/common/dawa-api";
import {Availability, Frequency, Service} from "@crm/api/models/common/housekeepr-api";
import {WindowTypeRequest} from "@crm/api/models/requests/housekeepr-api";
import {HYDRATE} from "next-redux-wrapper";
import createEventV2 from "@crm/utils/gtagv2";
import {housekeeprApi} from "@crm/api/housekeepr-api";
import {WritableDraft} from "immer/src/types/types-external";
import {setTag, setTags} from "@sentry/core";
import {WindowCleaningOrderResponse} from "@crm/api/models/responses/housekeepr-api";
import createPurchaseEvent from "@crm/utils/gtag-purchase";
import {authActions} from "@crm/services/auth-slice";
import {hash} from "@crm/utils/helpers";

export enum WindowCleaningPage {
    HOME,
    WINDOWS,
    TIME,
    USER,
    PAYMENT,
    SUBMITTING_ORDER,
}

export interface WindowCleaningState {
    started: boolean;
    currentPage: WindowCleaningPage;
    maxPage: WindowCleaningPage;
    priceExpanded: boolean;

    serviceTypeId?: number;
    address?: AutocompleteAddress;
    serviceId?: number;
    residenceType?: string;
    secondaryGlazing: boolean;
    windows: WindowTypeRequest[];
    frequencyId?: number;
    availability?: Availability;
    availabilityFetched?: number; //ms time
    email?: string;
    authenticated: boolean;
    unverifiedCode?: string;
    verifiedCode?: string;
    generalNote?: string;
    paymentType?: 'card' | 'mobilepaysubscriptions';
    canPay: boolean,
    paymentAuthorized: boolean;
    paymentError?: string;
    completed: boolean;
    order?: WindowCleaningOrderResponse;
}

const initialState = {
    started: false,
    currentPage: WindowCleaningPage.HOME,
    maxPage: WindowCleaningPage.HOME,
    priceExpanded: false,
    secondaryGlazing: false,
    windows: [],
    authenticated: false,
    paymentType: 'card',
    canPay: false,
    paymentAuthorized: false,
    completed: false,
} as WindowCleaningState;

export const windowCleaningSlice = createSlice({
    name: 'windowCleaning',
    initialState: initialState,
    reducers: {
        start(state, action: PayloadAction<{ serviceTypeId: number, authenticated?: boolean }>) {
            state.started = true;
            state.serviceTypeId = action.payload.serviceTypeId;
            setMaxPage(state);
        },

        setPage(state, action: PayloadAction<WindowCleaningPage>) {
            setMaxPage(state);

            if (action.payload == WindowCleaningPage.SUBMITTING_ORDER) {
                state.paymentAuthorized = false;
                state.paymentError = undefined;
            }

            if (action.payload <= state.maxPage) {
                state.currentPage = action.payload;
            }
        },

        togglePriceExpanded(state) {
            setMaxPage(state);

            if (!state.priceExpanded) {
                createEventV2('view_cart', null);
            }

            state.priceExpanded = !state.priceExpanded;
        },

        setAddress(state, action: PayloadAction<AutocompleteAddress>) {
            state.address = action.payload;
            window.dataLayer.push({
                'user_data': {
                    'address': {
                        'string': hash(state.address.tekst),
                        'street': hash(state.address.adresse.vejnavn),
                        'postal_code': state.address.adresse.postnr,
                        'city': state.address.adresse.postnrnavn,
                    }
                },
            });
            setMaxPage(state);
            setTag('address', action.payload.tekst);
            createEventV2('selected_address', action.payload.adresse.id);
        },
        clearAddress(state) {
            state.address = undefined;
            window.dataLayer.push({
                'user_data': {
                    'address': {
                        'string': undefined,
                        'street': undefined,
                        'postal_code': undefined,
                        'city': undefined,
                    }
                },
            });

            setMaxPage(state);
            setTag('address', undefined);
        },

        setService(state, action: PayloadAction<Service>) {
            state.serviceId = action.payload.id;

            if (state.frequencyId === undefined || !action.payload.frequencies.some(frequency => frequency.id === state.frequencyId)) {
                state.frequencyId = action.payload.frequencies[0].id;
            }

            state.unverifiedCode = state.verifiedCode;
            state.verifiedCode = undefined;

            setMaxPage(state);
            createEventV2('selected_service', action.payload.name);
        },
        clearService(state) {
            state.serviceId = undefined;

            state.unverifiedCode = state.verifiedCode;
            state.verifiedCode = undefined;

            setMaxPage(state);
        },

        setResidenceType(state, action: PayloadAction<string>) {
            state.residenceType = action.payload;

            setMaxPage(state);
            createEventV2('selected_residence_type', action.payload);
        },
        clearResidenceType(state) {
            state.serviceId = undefined;

            setMaxPage(state);
        },

        setSecondaryGlazing(state, action: PayloadAction<boolean>) {
            state.secondaryGlazing = action.payload;

            setMaxPage(state);
            createEventV2('updated_secondary_glazing', action.payload ? 'true' : 'false');
        },


        setWindowType(state, action: PayloadAction<WindowTypeRequest>) {
            if (action.payload.count < 1) {
                state.windows = state.windows.filter(window => window.id !== action.payload.id);

                setMaxPage(state);
                createEventV2('removed_window', action.payload.id.toString());
                return;
            }

            const window = state.windows.find(window => window.id === action.payload.id);
            if (window) {
                const {id, count} = action.payload;
                window.id = id;
                window.count = count;
                createEventV2('updated_window', action.payload.id.toString(), action.payload.count);
            } else {
                state.windows.push(action.payload);
                createEventV2('added_window', action.payload.id.toString(), action.payload.count);
            }

            if (state.windows.length > 1) {
                state.windows.sort((a, b) => a.id - b.id);
            }

            setMaxPage(state);
        },
        removeWindowType(state, action: PayloadAction<number>) {
            state.windows = state.windows.filter(window => window.id !== action.payload);

            setMaxPage(state);
            createEventV2('removed_window', action.payload.toString());
        },

        setFrequency(state, action: PayloadAction<Frequency>) {
            state.frequencyId = action.payload.id;

            state.unverifiedCode = state.verifiedCode;
            state.verifiedCode = undefined;

            setMaxPage(state);
            createEventV2('selected_frequency', action.payload.nicified_string);
        },
        clearFrequency(state) {
            state.frequencyId = undefined;

            state.unverifiedCode = state.verifiedCode;
            state.verifiedCode = undefined;

            setMaxPage(state);
        },

        setAvailability(state, action: PayloadAction<Availability>) {
            state.availability = action.payload;

            setMaxPage(state);
            createEventV2('selected_date_and_time', `${action.payload.date} ${action.payload.time_slot_range}`);
        },
        clearAvailability(state) {
            state.availability = undefined;
            state.availabilityFetched = undefined;

            setMaxPage(state);
        },
        updateAvailabilityFetched(state) {
            state.availabilityFetched = Date.now();
        },

        setVerifiedCode(state, action: PayloadAction<string>) {
            state.verifiedCode = action.payload;
            state.unverifiedCode = undefined;
            createEventV2('added_promotion_code', action.payload);
        },
        clearVerifiedCode(state) {
            state.verifiedCode = undefined;
            state.unverifiedCode = undefined;
        },

        setGeneralNote(state, action: PayloadAction<string>) {
            state.generalNote = action.payload;
        },

        setPaymentType(state, action: PayloadAction<'card' | 'mobilepaysubscriptions'>) {
            state.paymentType = action.payload;

            setMaxPage(state);
            createEventV2('selected_payment_type', action.payload);
        },

        setEmail(state, action: PayloadAction<string>) {
            state.email = action.payload;
        },

        setAuthenticated(state, action: PayloadAction<boolean>) {
            state.authenticated = action.payload;
            state.canPay = false;

            setMaxPage(state);
        },

        setCanPay(state, action: PayloadAction<boolean>) {
            state.canPay = action.payload;
            state.paymentError = undefined;

            setMaxPage(state);
        },

        setPaymentAuthorized(state, action: PayloadAction<boolean>) {
            state.paymentAuthorized = action.payload;
            state.paymentError = undefined;

            if (action.payload) {
                createEventV2('add_payment_info', state.paymentType);
            }

            setMaxPage(state);
        },

        setPaymentError(state, action: PayloadAction<string>) {
            state.paymentError = action.payload;
            state.canPay = false;
            state.paymentAuthorized = false;

            setMaxPage(state);
        },
        clearPaymentError(state) {
            state.paymentError = undefined;

            setMaxPage(state);
        },

        retryPaymentStep(state) {
            state.currentPage = WindowCleaningPage.PAYMENT;
            state.paymentError = undefined;
            state.canPay = false;
            state.paymentAuthorized = false;

            setMaxPage(state);
        },

        markCompleted(state, action: PayloadAction<{ order: WindowCleaningOrderResponse, revenue: number }>) {
            state.completed = true;
            state.order = action.payload.order;

            setMaxPage(state);
            createPurchaseEvent(action.payload.order.order_id, action.payload.revenue);
        },

        resetState(state) {
            createEventV2('closed_order_wizard', 'Window Cleaning');
            setTags({
                'address': undefined,
                'email': undefined,
            });
            return initialState;
        }
    },

    extraReducers: builder => {
        builder
            .addCase(
                HYDRATE,
                (state, action: any) => ({
                    ...state,
                    ...action.payload.subject,
                })
            )
            .addCase(
                authActions.setUser,
                (state, action) => {
                    state.email = action.payload.user.email;
                    state.authenticated = true;
                    state.canPay = false;
                    setMaxPage(state);
                }
            )
            .addCase(
                authActions.clearUser,
                (state) => {
                    state.email = undefined;
                    state.authenticated = false;
                    state.canPay = false;
                    setMaxPage(state);
                }
            )
            .addMatcher(
                housekeeprApi.endpoints.getServiceAvailability.matchFulfilled,
                state => {
                    state.availabilityFetched = Date.now();
                }
            )
            .addMatcher(
                housekeeprApi.endpoints.getServiceAvailabilitySplit.matchFulfilled,
                state => {
                    state.availabilityFetched = Date.now();
                }
            );
    },
});

function setMaxPage(state: WritableDraft<WindowCleaningState>) {
    if (!state.address || !state.residenceType || !state.serviceId) {
        state.maxPage = WindowCleaningPage.HOME;
    } else if (state.windows.length <= 0) {
        state.maxPage = WindowCleaningPage.WINDOWS;
    } else if (!state.availability || !state.frequencyId) {
        state.maxPage = WindowCleaningPage.TIME;
    } else if (!state.authenticated) {
        state.maxPage = WindowCleaningPage.USER;
    } else if (!(state.paymentType || state.canPay)) {
        state.maxPage = WindowCleaningPage.PAYMENT;
    } else {
        state.maxPage = WindowCleaningPage.SUBMITTING_ORDER;
    }

    if (state.currentPage > state.maxPage) {
        state.currentPage = state.maxPage;
    }
}

export const {actions: windowCleaningActions, reducer: windowCleaningReducer} = windowCleaningSlice;
