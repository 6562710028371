import {retry} from "@crm/utils/helpers";

export default function createGtagEvent(eventName: string, eventLabel?: string | null, value?: number, eventCategory = 'order_wizard', extra?: {
    email?: string,
}) {
    retry(async () =>
        window.gtag('event', eventName, {
            event_category: eventCategory,
            event_label: eventLabel,
            value: value,
            ...extra,
        })).catch(console.error);
};
