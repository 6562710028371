import {css} from 'styled-components';

export type Display = {
    overflow?: 'visible' | 'hidden' | 'scroll' | 'auto' | 'initial' | 'inherit';
    textOverflow?: 'clip' | 'ellipsis' | 'initial' | 'inherit';
    visibility?: 'visible' | 'hidden' | 'collapse' | 'initial' | 'inherit';
    whiteSpace?: 'normal' | 'nowrap' | 'pre' | 'pre-line' | 'pre-wrap' | 'initial' | 'inherit';
    display?: 'none' | 'inline' | 'block' | 'inline-block' | 'flex' | 'inline-flex';
    opacity?: number;
};

export const display = css<Display>`
  overflow: ${props => props.overflow};
  text-overflow: ${props => props.textOverflow};
  visibility: ${props => props.visibility};
  white-space: ${props => props.whiteSpace};
  display: ${props => props.display};
  opacity: ${props => props.opacity};
`;

export default display;
