import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {HYDRATE} from "next-redux-wrapper";
import {wizardActions} from "@crm/services/wizard-slice";
import {cleaningActions} from "@crm/services/cleaning-slice";
import {windowCleaningActions} from "@crm/services/window-cleaning-slice";

export interface StepperState {
    activeStep: number;
    noSteps: number;
}

const initialState = {
    activeStep: 0,
    noSteps: 2,
} as StepperState;

export const stepperSlice = createSlice({
    name: 'stepper',
    initialState: initialState,
    reducers: {
        setStep(state, action: PayloadAction<number>) {
            state.activeStep = action.payload;
        },
        setStepFromEnd(state, action: PayloadAction<number>) {
            state.activeStep = state.noSteps - action.payload;
        },
        nextStep(state) {
            state.activeStep += 1;
        },

        setNumberOfSteps(state, action: PayloadAction<number>) {
            state.noSteps = action.payload;
        },
    },

    extraReducers: builder => {
        builder
            .addCase(
                HYDRATE,
                (state, action: any) => ({
                    ...state,
                    ...action.payload.subject,
                })
            )
            .addCase(wizardActions.resetState,
                () => initialState,
            )
            .addCase(cleaningActions.resetState,
                () => initialState,
            )
            .addCase(windowCleaningActions.resetState,
                () => initialState,
            )
    },

    selectors: {
        selectStep: (state) => state.activeStep,
        selectNumberOfSteps: (state) => state.noSteps,
    }
});

export const {actions: stepperActions, reducer: stepperReducer, selectors: stepperSelectors,} = stepperSlice;