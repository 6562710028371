import {Step} from "@crm/models/step";
import React from "react";
import CleaningPaymentCard from "@crm/components/cleaning/cleaning-payment-card";
import {CleaningState} from "@crm/services/cleaning-slice";

export const CLEANING_STEP_PAYMENT = 'payment';

export class CleaningStepPayment extends Step {
    public getIdentifier(): string {
        return CLEANING_STEP_PAYMENT;
    }

    public getName(): string {
        return 'Bestilling';
    }

    public getShortName(): string {
        return 'Bestil';
    }

    public isComplete(state: CleaningState): boolean {
        return state.paymentType !== undefined;
    }

    public buildPage(currentPage: string): React.JSX.Element {
        return <CleaningPaymentCard
            key={this.getIdentifier()}
            hidden={currentPage !== this.getIdentifier()}
        />;
    }
}