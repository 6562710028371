import React, {ForwardedRef, useRef, useState} from 'react';
import styled from 'styled-components';
import {MonthsDK} from './month-look-up';
import ChangeMonthButton from '@crm/components/calendar/calendar-components/change-month-button';
import DayInCalendar from '@crm/components/calendar/calendar-components/day-in-calendar';
import DaysInTheWeek, {CalendarDivider} from '@crm/components/calendar/calendar-components/days-in-the-week';
import DateOptions, {
    AvailabilityOption,
    DateOptionsRef
} from '@crm/components/calendar/calendar-components/date-options/date-options';
import {AppState, useAppSelector} from "@crm/services/store";
import {Availability} from "@crm/api/models/common/housekeepr-api";

interface CalendarProps {
    dateOptions: AvailabilityOption[];
    multipleDateOptions: boolean;
    dateOptionRows: number;
    serviceTypeId: number;
    availabilitySelector: (state: AppState) => Availability | undefined;

    onSelectOption(selectedOption?: AvailabilityOption): any;
}

interface ChooseOptionTextProps {
    $active?: boolean;
}

const CalendarContainer = styled.div`
    width: 100%;
    background: ${({theme}) => theme.palette.common.lightTurquoise};
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    ${({theme}) => theme.typography.body}
    position: relative;
`;

const CalendarHeader = styled.div`
    text-align: center;
    padding: 2rem 6.5% 0.5rem;
    color: ${({theme}) => theme.palette.common.darkBlue};

    & svg {
        & path {
            fill: ${({theme}) => theme.palette.common.darkBlue};
        }
    }
`;

const CalendarFooter = styled.div`
    width: 100%;
    padding: 0 1rem 0 1rem;
`;

const MonthAndYearText = styled.p`
    display: inline;
    ${({theme}) => theme.typography.secondary};
    font-size: 24px;
    font-weight: 500;
    line-height: 1.5;

    ${({theme}) => theme.breakpoints.down('mobile')} {
        font-size: 16px;
    }
`;

const ChooseOptionText = styled.p<ChooseOptionTextProps>`
    ${({theme}) => theme.typography.secondary};
    color: ${props => !props.$active && (({theme}) => theme.palette.common.turquoise)};
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5;
    padding-bottom: 1%;

    ${({theme}) => theme.breakpoints.down('mobile')} {
        font-size: 16px;
    }
`;

const Calendar = React.forwardRef(function Calendar(props: CalendarProps, ref: ForwardedRef<HTMLDivElement>) {
        const availability = useAppSelector(props.availabilitySelector);
        const dateAnchor = availability ? new Date(availability.date) : new Date();
        const yearAnchor = dateAnchor.getFullYear();
        const monthAnchor = dateAnchor.getMonth();

        const [firstDateInCalendar, setFirstDateInCalendar] = useState(new Date(yearAnchor, monthAnchor, 1));
        const month = firstDateInCalendar.getMonth();
        const year = firstDateInCalendar.getFullYear();

        const [selectedDate, setSelectedDate] = useState<Date | undefined>(availability ? dateAnchor : undefined);

        const dayIndex = (firstDateInCalendar.getDay() + 6) % 7;
        const daysInMonth = new Date(year, month + 1, 0).getDate();

        const prevDates = [
            ...Array.from({length: dayIndex}, (_, i) => new Date(year, month, i - dayIndex + 1)),
        ];

        const monthDates = [
            ...Array.from({length: daysInMonth}, (_, i) => new Date(year, month, i + 1)),
        ];

        const nextDates = [
            ...Array.from({length: 42 - dayIndex - daysInMonth}, (_, i) => new Date(year, month, daysInMonth + 1 + i)),
        ];

        const dateOptionsRef = useRef<DateOptionsRef>(null);

        const handleClickOnDate = (date: Date) => {
            setSelectedDate(date);
            handleGetSelectedDayOption?.(undefined);
            dateOptionsRef.current?.clearSelection();

            if (date.getFullYear() == year && date.getMonth() == month) {
                // The clicked date is part of the shown month
                return;
            }

            if (date.getFullYear() > year || date.getFullYear() == year && date.getMonth() > month) {
                nextMonth();
            } else {
                previousMonth();
            }

        };

        const isWindowCleaning = props.serviceTypeId === 2;

        const nextMonth = () => setFirstDateInCalendar(prevDate => new Date(prevDate.getFullYear(), prevDate.getMonth() + 1))

        const previousMonth = () => setFirstDateInCalendar(prevDate => new Date(prevDate.getFullYear(), prevDate.getMonth() - 1))

        /**
         * @param date
         * @returns True if date is the selected date.
         */
        const handleSelectedDate = (date: Date) => {
            if (!selectedDate) {
                return false;
            }
            return selectedDate &&
                date.getFullYear() == selectedDate.getFullYear() &&
                date.getMonth() == selectedDate.getMonth() &&
                date.getDate() == selectedDate.getDate();
        };

        const handleGetSelectedDayOption = props.onSelectOption;

        const getDateOptions = (date?: Date) => {
            if (!date) {
                return [] as AvailabilityOption[];
            }

            return props.dateOptions.filter(option => {
                const optionDate = option.availableDate;
                return date.getFullYear() == optionDate.getFullYear()
                    && date.getMonth() == optionDate.getMonth()
                    && date.getDate() == optionDate.getDate();
            });
        };

        return <CalendarContainer ref={ref} data-cy={'calendar'}>
            <CalendarHeader>
                <ChangeMonthButton direction={'previous'} onClick={previousMonth}/>
                <MonthAndYearText>
                    {MonthsDK[month + 1].upper} {year}
                </MonthAndYearText>
                <ChangeMonthButton direction={'next'} onClick={nextMonth}/>
            </CalendarHeader>
            <DaysInTheWeek/>
            <div className="px-[1rem] w-full grid grid-cols-7 mb-[2.5%] gap-1">
                {prevDates.map(date => {
                    const active = getDateOptions(date).length > 0;
                    return <DayInCalendar
                        key={'lastMonth' + date.getDate()}
                        date={date}
                        $active={active}
                        disabled={false}
                        onClick={() => handleClickOnDate(date)}
                        selected={active && handleSelectedDate(date)}
                    />;
                })}
                {monthDates.map((date, index) => {
                    const active = getDateOptions(date).length > 0;
                    return <DayInCalendar
                        key={index}
                        date={date}
                        $active={active}
                        onClick={active ? () => handleClickOnDate(date) : undefined}
                        selected={active && handleSelectedDate(date)}
                    />;
                })}
                {nextDates.map(date => {
                    const active = getDateOptions(date).length > 0;
                    return <DayInCalendar
                        key={'nextMonth' + date.getDate()}
                        date={date}
                        $active={active}
                        disabled={false}
                        onClick={() => handleClickOnDate(date)}
                        selected={active && handleSelectedDate(date)}
                    />;
                })}
            </div>
            <CalendarFooter>
                <CalendarDivider $marginBottom={1}/>
                <ChooseOptionText $active={selectedDate != undefined}>
                    Vælg tidsrum for ankomst
                </ChooseOptionText>
                <p style={{textAlign: "center", fontSize: "13px"}} className={"mb-2"}>
                    {isWindowCleaning ? "Bemærk at vores arbejdstider er mellem 08:00 og 18:00" : "Bemærk at vores arbejdstider er mellem 08:00 og 16:45"}
                </p>
                <CalendarDivider/>
                <DateOptions
                    availabilitySelector={props.availabilitySelector}
                    selectedDate={selectedDate}
                    options={getDateOptions(selectedDate)}
                    getSelectedOptionCallBack={handleGetSelectedDayOption}
                    rows={props.dateOptionRows}
                    serviceTypeId={props.serviceTypeId}
                    ref={dateOptionsRef}
                />
            </CalendarFooter>
        </CalendarContainer>;
    })
;

export default Calendar;
