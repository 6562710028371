export const MAX_WRAP_WIDTH = 1256;
export const MAX_WRAP_WIDTH_TABLET = 700;
export const GLOBAL_HORIZONTAL_PADDING = 50;
export const GLOBAL_HORIZONTAL_PADDING_MOBILE = 30;

export const VAT = 1.25;
export const PAYMENT_PROCESSING_FEE = 25;
export const PAYMENT_PROCESSING_FEE_WITH_VAT = 31.25;

export const isProduction = process.env.NODE_ENV === "production";
