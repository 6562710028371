import React from 'react';
import useMenu from '../../../hooks/use-menu';
import {BurgerMenu} from '../../../utils/images';
import {Box} from '../../box/box';
import IconButton from '../../icon-button/icon-button';
import styled from 'styled-components';

const IconButtonContainer = styled(IconButton)`
    path {
        transition: fill 0.1s;
        fill: ${({theme}) => theme.palette.common.red};
    }

    &:hover {
        path {
            fill: white;
        }
    }
`;

const Controls: React.FC = () => {
    const {handleOpen} = useMenu();

    return <Box display="flex" $alignItems="center">
        <IconButtonContainer aria-label="Menu button" className="menu-icon menu-icon-test-open" onClick={handleOpen}>
            <BurgerMenu/>
        </IconButtonContainer>
    </Box>;
};

export default Controls;
