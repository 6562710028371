import React, {useEffect} from 'react';
import {contactInfoActions} from "@crm/services/contact-info-slice";
import {useRouter} from "next/router";
import {useAppDispatch} from "@crm/services/store";

export interface GclidSetterProps {
    children: React.ReactNode | React.ReactNode[];
}

export const GclidSetter = (props: GclidSetterProps): React.JSX.Element => {
    const {children} = props;

    const router = useRouter();
    const dispatch = useAppDispatch();
    const {gclid: gclid, ...query} = router.query;

    useEffect(() => {
        if (gclid) {
            dispatch(contactInfoActions.setGclid(gclid as string));
        }
    }, [dispatch, gclid]);

    return <>
        {children}
    </>;
};

export default GclidSetter;
