import React from 'react';
import styled, {css} from 'styled-components';

// type Color = 'default' | 'primary' | 'secondary';
// type Color = Globals | ThemeColorType | (string & {});

type ButtonVariant = 'outlined' | 'contained';

interface ButtonRootProps {
    $variant: ButtonVariant;
}

export const ButtonRoot = styled.button<ButtonRootProps>`
    min-width: 120px;
    height: 35px;

    font-family: ${props => props.theme.typography.secondary.fontFamily};
    font-weight: ${props => props.theme.typography.weight.medium};
    line-height: normal;
    font-size: 13px;

    border-radius: 10px;
    padding: ${props => `${props.theme.spacing(2)} ${props.theme.spacing(4)}`};

    ${(props) => {
        switch (props.$variant) {
            case 'contained':
                return css`
                    color: ${props.theme.palette.common.turquoise};
                    background-color: ${props.theme.palette.common.darkBlue};
                `;
            case 'outlined':
                return css`
                    color: ${props.theme.palette.common.darkBlue};
                    border: 2px solid ${props.theme.palette.common.darkBlue};
                    background-color: transparent;
                `;
        }
    }};

    &&:hover {
        ${(props) => {
            switch (props.$variant) {
                case 'contained':
                    return css`
                        background-color: rgba(42, 77, 79, 0.9);
                    `;
                case 'outlined':
                    return css`
                        color: rgba(42, 77, 79, 0.9);
                        border-color: rgba(42, 77, 79, 0.9);
                        background-color: rgba(255, 255, 255, 0.1);
                    `;
            }
        }};
    }

    &:disabled {
        cursor: default;
        pointer-events: none;
        background-color: ${({theme}) => theme.palette.secondary.main};
        color: grey;
    }
`;

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: ButtonVariant;
}

const Button = React.forwardRef<HTMLButtonElement, Props>(function Button(props: Props, ref) {
    const {children, type = 'button', variant = 'contained', ...rest} = props;

    return <ButtonRoot $variant={variant} ref={ref} type={type} {...rest}>
        {children}
    </ButtonRoot>;
});

export default Button;
