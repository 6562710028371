// https://css-tricks.com/linearly-scale-font-size-with-css-clamp-based-on-the-viewport/

import {createHash} from "crypto";

const getAsNumber = (ex: string | number) => typeof ex === 'number' ? ex : parseInt(ex);

export const getFluidSizing = (
    minFontSize: string | number,
    maxFontSize: string | number,
    maxWidth: string | number,
    minWidth: string | number
) => {
    const fontSizeMin = getAsNumber(minFontSize);
    const fontSizeMax = getAsNumber(maxFontSize);
    const widthMin = getAsNumber(minWidth);
    const widthMax = getAsNumber(maxWidth);

    const slope = (fontSizeMax - fontSizeMin) / (widthMax - widthMin);
    const yAxisIntersection = -widthMin * slope + fontSizeMin;
    const preferredValue = `${yAxisIntersection}px + ${slope * 100}vw`;

    return `clamp(${fontSizeMin}px, ${preferredValue}, ${fontSizeMax}px)`;
};

export const getLocalStorageOrDefault = <T>(key: string, defaultValue: T) => {
    if (typeof window == 'undefined') {
        return defaultValue;
    }
    const stored = window.localStorage.getItem(key);
    if (stored == null) {
        return defaultValue;
    }
    return JSON.parse(stored);
};

export const getWindowDimensions = () => {
    if (typeof window !== 'undefined') {
        const {innerWidth: width, innerHeight: height} = window;
        return {
            width,
            height
        };
    }
    return {
        width: null,
        height: null
    };
};

export const unEscapeBackslash = (text?: string) => {
    if (text?.replaceAll) {
        return text.replaceAll('\\n', '\n');
    }
    if (text) {
        return text;
    }
    return "";
};

export const weekdayFromDate = (date?: string) => {
    if (date) {
        const d = new Date(date);
        const day = d.getDay();
        switch (day) {
            case 1:
                return 'Mandag';
            case 2:
                return 'Tirsdag';
            case 3:
                return 'Onsdag';
            case 4:
                return 'Torsdag';
            case 5:
                return 'Fredag';
            case 6:
                return 'Lørdag';
            case 0:
                return 'Søndag';
        }
    }
    return ""
};

export async function sleep(millis: number) {
    return new Promise(r => setTimeout(r, millis));
}

export async function retry<Type>(callback: () => Promise<Type> | undefined, maxAttempts = 5, delay = 0): Promise<Type> {
    if (delay) {
        await sleep(delay);
    }
    try {
        const result = callback();
        if (result !== undefined) {
            return result;
        }
        if (maxAttempts <= 1) {
            return Promise.reject(undefined);
        }
    } catch (e) {
        if (maxAttempts <= 1) {
            return Promise.reject(e);
        }
    }
    return retry(callback, maxAttempts - 1, Math.max(400, delay * 2));
}

export function capitalize(s: string) {
    if (s.length <= 1) {
        return s.toUpperCase();
    }
    return s[0].toUpperCase() + s.slice(1);
}

export function smoothScrollToTop() {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if ('scrollBehavior' in document.documentElement.style && !isSafari) {
        window.scrollTo({top: 0, behavior: 'smooth'});
    } else {
        const tickDuration = 5;
        // Fallback for browsers that do not support smooth scrolling
        const scrollToTop = (element: HTMLElement, to: number, duration: number) => {
            if (duration <= 0) return;
            const difference = to - element.scrollTop;
            const perTick = difference / duration * tickDuration;

            setTimeout(() => {
                element.scrollTop = element.scrollTop + perTick;
                if (element.scrollTop === to) return;
                scrollToTop(element, to, duration - tickDuration);
            }, tickDuration);
        };

        scrollToTop(document.documentElement, 0, 300);
    }
}

export const hash = (data?: string) => {
    if (data == undefined) {
        return data;
    }
    return createHash('sha256').update(data.trim().toLowerCase()).digest('hex');
};

export const now = () => {
    return new Date();
};

export const addDaysToDate = (date: Date, days: number) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
};

