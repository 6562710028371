//CREDIT: https://www.bypeople.com/overlapping-dots-css-loader/

import React from 'react';
import styled, {css, keyframes} from 'styled-components';

interface LoaderProps {
    marginTopPx?: number;
    marginBottomPx?: number;
}

interface DotProps {
    $dotN: 1 | 2 | 3 | 4;
}

const Loader = styled.div<LoaderProps>`
    font-size: 20px;
    position: relative;
    width: 4em;
    height: 1em;
    margin: ${props => props.marginTopPx ?? 0}px auto ${props => props.marginBottomPx ?? 0}px auto;
`;

const handleDots = (dotN: number) => {
    switch (dotN) {
        case 1:
            return css`
                left: 0;
                animation-name: ${reveal};
            `;
        case 2:
            return css`
                left: 0;
                animation-name: ${slide};
            `;
        case 3:
            return css`
                left: 1.5em;
                animation-name: ${slide};
            `;
        case 4:
            return css`
                left: 3em;
                animation-name: ${reveal};
                animation-direction: reverse;
            `;
    }
};

const reveal = keyframes`
    from {
        transform: scale(0.001);
    }
    to {
        transform: scale(1);
    }
`;

const slide = keyframes`
    to {
        transform: translateX(1.5em);
    }
`;

const Dot = styled.div<DotProps>`
    width: 1em;
    height: 1em;
    border-radius: 0.5em;
    background: ${({theme}) => theme.palette.common.darkBlue};
    position: absolute;
    animation-duration: 0.5s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;

    ${props => handleDots(props.$dotN)}
`;

export const LoadingDots = (props: LoaderProps) =>
    <Loader marginTopPx={props.marginTopPx} marginBottomPx={props.marginBottomPx}>
        <Dot $dotN={1}/>
        <Dot $dotN={2}/>
        <Dot $dotN={3}/>
        <Dot $dotN={4}/>
    </Loader>;

export default LoadingDots;
