import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AutocompleteAddress, DawaAddress, DawaAutocomplete} from "@crm/api/models/common/dawa-api";
import {HYDRATE} from "next-redux-wrapper";
import createEventV2 from "@crm/utils/gtagv2";
import {setTag, setTags} from "@sentry/core";
import {ServiceType} from "@crm/api/models/common/housekeepr-api";
import {hash} from "@crm/utils/helpers";
import {REHYDRATE} from "redux-persist";
import {AuthState} from "@crm/services/auth-slice";

export interface WizardState {
    started: boolean;
    addressInputValue: string;
    addressInputCaretPos?: {
        start: number;
        end?: number;
    };
    addressName?: string;
    dawaAddress?: AutocompleteAddress;
    serviceTypeId?: number;
    area?: number;
    email?: string;
}

const initialState = {
    started: false,
    addressInputValue: '',
    completed: false,
} as WizardState;

export const wizardSlice = createSlice({
    name: 'wizard',
    initialState: initialState,
    reducers: {
        setAddressInput(state, action: PayloadAction<{ text: string, caretPosStart?: number, caretPosEnd?: number }>) {
            if (!state.started) {
                createEventV2('search_address', action.payload.text);
            }

            return {
                ...initialState,
                started: true,
                addressInputValue: action.payload.text,
                addressInputCaretPos: {
                    start: action.payload.caretPosStart,
                    end: action.payload.caretPosEnd ?? action.payload.caretPosStart,
                },
            } as WizardState;
        },

        setFromAutocomplete(state, action: PayloadAction<DawaAutocomplete>) {
            state.addressInputValue = action.payload.tekst;
            state.addressInputCaretPos = {
                start: action.payload.caretpos,
                end: action.payload.caretpos,
            }

            if (action.payload.type == 'adresse') {
                const address = action.payload.data as DawaAddress;
                state.addressName = action.payload.tekst;
                state.dawaAddress = {
                    tekst: action.payload.tekst,
                    adresse: address,
                };
                window.dataLayer.push({
                    'user_data': {
                        'address': {
                            'string': hash(state.dawaAddress.tekst),
                            'street': hash(state.dawaAddress.adresse.vejnavn),
                            'postal_code': state.dawaAddress.adresse.postnr,
                            'city': state.dawaAddress.adresse.postnrnavn,
                        }
                    },
                });
                setTag('address', state.addressName);
                createEventV2('selected_address', address.id);
            }
        },


        clearDawaAddress(state) {
            state.addressName = undefined;
            state.dawaAddress = undefined;
            window.dataLayer.push({
                'user_data': {
                    'address': {
                        'string': undefined,
                        'street': undefined,
                        'postal_code': undefined,
                        'city': undefined,
                    }
                },
            });
            setTag('address', state.addressName);
        },

        setArea(state, action: PayloadAction<number>) {
            state.area = action.payload;
            createEventV2('changed_area', action.payload.toString());
        },
        clearArea(state) {
            state.area = undefined;
        },

        setServiceType(state, action: PayloadAction<ServiceType>) {
            state.serviceTypeId = action.payload.id;
            createEventV2('selected_service_type', action.payload.name);
        },

        setEmail(state, action: PayloadAction<string>) {
            state.email = action.payload;
        },

        resetState(state) {
            createEventV2('closed_order_wizard', 'Cleaning');
            setTags({
                'address': undefined,
                'email': undefined,
            });
            return initialState;
        }
    },

    extraReducers: builder => {
        builder
            .addCase(
                HYDRATE,
                (state, action: any) => ({
                    ...state,
                    ...action.payload.subject,
                })
            )
            .addCase(
                REHYDRATE,
                (state, action: any) => {
                    if (action.payload == undefined) {
                        return state;
                    }
                    const wizardState: WizardState = action.payload.wizard;
                    const authState: AuthState = action.payload.auth;

                    const firstName = authState.user?.name.split(' ').slice(0, -1).join(' ');
                    const lastName = authState.user?.name.split(' ').slice(-1).join(' ');
                    window.dataLayer.push({
                        'user_data': {
                            'id': authState.user?.id,
                            'first_name': hash(firstName),
                            'last_name': hash(lastName),
                            'email': hash(authState.user?.email),
                            'phone': hash(authState.user?.phone),
                            'address': {
                                'string': hash(wizardState.dawaAddress?.tekst),
                                'street': hash(wizardState.dawaAddress?.adresse.vejnavn),
                                'postal_code': wizardState.dawaAddress?.adresse.postnr,
                                'city': wizardState.dawaAddress?.adresse.postnrnavn,
                            }
                        },
                    });

                    return {
                        ...state,
                        ...wizardState,
                    };
                }
            );
    },

    selectors: {
        selectAddressInput: (state) => state.addressInputValue,
        selectAddressInputCaretPos: (state) => state.addressInputCaretPos,
        selectAddress: (state) => state.dawaAddress,
        selectArea: (state) => state.area,
        selectServiceTypeId: (state) => state.serviceTypeId,
    }
});

export const {actions: wizardActions, reducer: wizardReducer, selectors: wizardSelectors} = wizardSlice;
