import Button from '@crm/components/button';
import {TextField} from '@crm/components/text-field';
import {useAppSelector} from '@crm/services/store';
import {useDispatch} from 'react-redux';
import React, {useState} from 'react';
import styled, {css} from 'styled-components';
import Typography from '@crm/components/typography';
import {housekeeprApi} from '@crm/api/housekeepr-api';
import {skipToken} from '@reduxjs/toolkit/query';
import {LoadingDots} from '@crm/components/icon/loading-dots';
import {cleaningActions, cleaningSelectors} from '@crm/services/cleaning-slice';

const RedeemCodeFieldWrapper = styled.div`
    flex: 1 0 auto;
    margin-right: 24px;
`;

export function CleaningRedeemCode() {
    const dispatch = useDispatch();

    const unverifiedCode = useAppSelector(cleaningSelectors.selectUnverifiedCode);
    const verifiedCode = useAppSelector(cleaningSelectors.selectVerifiedCode);
    const serviceId = useAppSelector(cleaningSelectors.selectServiceId);
    const frequencyId = useAppSelector(cleaningSelectors.selectFrequencyId);

    const [inputValue, setInputValue] = useState(verifiedCode ?? unverifiedCode ?? '');
    const [checkingValue, setCheckingValue] = useState<undefined | string>(verifiedCode ?? unverifiedCode);

    const {
        data: checkedCode,
        error,
        isFetching
    } = housekeeprApi.useCheckCodeQuery(checkingValue && serviceId && frequencyId ? {
        code: checkingValue.toUpperCase(),
        service_id: serviceId,
        frequency_id: frequencyId
    } : skipToken);

    const disabled = !!checkingValue && !error;

    React.useEffect(() => {
        if (error && verifiedCode) {
            dispatch(cleaningActions.clearVerifiedCode());
        } else if (checkedCode && checkingValue && checkingValue != verifiedCode && !isFetching && !error) {
            dispatch(cleaningActions.setVerifiedCode(checkingValue));
        }
    }, [verifiedCode, checkedCode, checkingValue, dispatch, isFetching, error]);

    return <>
        <div className={'flex flex-row items-center justify-between w-full'}>
            <RedeemCodeFieldWrapper>
                <TextField
                    value={inputValue}
                    onChange={event => {
                        setInputValue(event.target.value.toUpperCase());
                    }}
                    placeholder={'Indtast kode - valgfri'}
                    disabled={disabled}
                    styles={disabled
                        ? {
                            inputField: css`
                            color: #2A4D4F;
                            background-color: #6D9093;
                            border: none;
                        `.toString()
                        }
                        : undefined
                    }
                />
            </RedeemCodeFieldWrapper>
            <Button
                onClick={() => {
                    setCheckingValue(disabled ? undefined : inputValue);
                    if (disabled) {
                        dispatch(cleaningActions.clearVerifiedCode());
                    }
                }}
            >
                {disabled ? 'Fjern' : 'Tilføj'}
            </Button>
        </div>
        {checkingValue && !isFetching && (checkedCode || error) &&
            <Typography variant={'body'} color={'darkBlue'}>
                {error && 'data' in error ? (error.data as {
                    error?: string
                }).error : checkedCode?.description ?? 'Den indtastede kode kunne ikke bruges.'}
            </Typography>
        }
        {isFetching && <LoadingDots marginTopPx={24}/>}
    </>;
}