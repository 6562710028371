import {css} from 'styled-components';

export type Spacing = {
    margin?: number | string;
    marginTop?: number | string;
    marginRight?: number | string;
    marginBottom?: number | string;
    marginLeft?: number | string;
    marginX?: number | string;
    marginY?: number | string;
    padding?: number | string;
    paddingTop?: number | string;
    paddingRight?: number | string;
    paddingBottom?: number | string;
    paddingLeft?: number | string;
    paddingX?: number | string;
    paddingY?: number | string;
};

const applySpacing = (value: string | number): string => {
    if (typeof value === 'number') {
        return `${value * 4}px`;
    }

    return value;
};

const spacing = css<Spacing>`
  margin: ${props => props.margin && applySpacing(props.margin)};
  margin-top: ${props => props.marginTop && applySpacing(props.marginTop)};
  margin-bottom: ${props => props.marginBottom && applySpacing(props.marginBottom)};
  margin-left: ${props => props.marginLeft && applySpacing(props.marginLeft)};
  margin-right: ${props => props.marginRight && applySpacing(props.marginRight)};
  ${({marginX}) =>
    marginX &&
    css`
      margin-left: ${applySpacing(marginX)};
      margin-right: ${applySpacing(marginX)};
    `};
  ${({marginY}) =>
    marginY &&
    css`
      margin-top: ${applySpacing(marginY)};
      margin-bottom: ${applySpacing(marginY)};
    `};

  padding: ${props => props.padding && applySpacing(props.padding)};
  padding-top: ${props => props.paddingTop && applySpacing(props.paddingTop)};
  padding-bottom: ${props => props.paddingBottom && applySpacing(props.paddingBottom)};
  padding-left: ${props => props.paddingLeft && applySpacing(props.paddingLeft)};
  padding-right: ${props => props.paddingRight && applySpacing(props.paddingRight)};

  ${({paddingX}) =>
    paddingX &&
    css`
      padding-left: ${applySpacing(paddingX)};
      padding-right: ${applySpacing(paddingX)};
    `};
  ${({paddingY}) =>
    paddingY &&
    css`
      padding-top: ${applySpacing(paddingY)};
      padding-bottom: ${applySpacing(paddingY)};
    `};
`;

export default spacing;
