import React from 'react';
import styled, {useTheme} from 'styled-components';
import Controls from './controls';
import {LogoContainer} from './logo-container';
import MegaMenu from './mega-menu/mega-menu';
import useMediaQuery from "@crm/hooks/use-media-query/use-media-query";
import Typography from "@crm/components/typography";
import CallMeDialog from "@crm/components/layout/navbar/call-me/call-me-dialog";
import {useCallMe} from "@crm/hooks/use-call-me";
import {useMenu} from "@crm/hooks/use-menu";

const LoginContainer = styled.div`
    position: fixed;
    top: 0;
    z-index: 100;
    right: 0;
    left: 0;
    width: 100%;
    padding: inherit;
`;

const Container = styled.div.attrs({className: 'shared-nav-header'})`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding-right: 46px;
`;

const AbsLogoContainer = styled.div<{ $zIndex: number }>`
    position: absolute;
    z-index: ${props => props.$zIndex};
    top: 20px;
    left: 0;
    padding: 0 140px;

    ${props => props.theme.breakpoints.down('laptop')} {
        padding: 0 50px;
    }

    ${({theme}) => theme.breakpoints.down('mobile')} {
        padding: 0 30px;
    }
`;

const Navbar: React.FC = () => {
    const [logoContainerZIndex, setLogoContainerZIndex] = React.useState(9);
    const theme = useTheme();
    const {openCallMe, submitted} = useCallMe();
    const {opened: menuOpen} = useMenu();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

    React.useEffect(() => {
        if (menuOpen) {
            setLogoContainerZIndex(10)
        } else {
            setLogoContainerZIndex(200)
        }
    }, [menuOpen]);


    return <>
        <LoginContainer>
            <MegaMenu/>
            <CallMeDialog/>
            <Container>
                <div className="bg-red rounded-xl px-4 py-1 mr-5"
                     style={{cursor: submitted ? undefined : 'pointer'}}>
                    <Typography
                        style={{fontSize: 16, color: theme.palette.common.white}}
                        onClick={!submitted ? openCallMe : undefined}
                    >
                        {submitted ? 'Tak! Vi kontakter dig' : 'Ring mig op'}
                    </Typography>
                </div>
                <Controls/>
            </Container>
        </LoginContainer>
        <AbsLogoContainer $zIndex={logoContainerZIndex}>
            <LogoContainer withName={!isMobile}/>
        </AbsLogoContainer>
    </>;
};

export default Navbar;
