import {useAppDispatch, useAppSelector} from "@crm/services/store";
import orderStyles from "@crm/styles/order.module.css";
import TextField from "@crm/components/text-field";
import {contactInfoActions, contactInfoSelectors} from "@crm/services/contact-info-slice";
import PhoneNumberInputField from "@crm/components/phone-number-input-field/phone-number-input-field";
import React, {useEffect, useState} from "react";
import {housekeeprApi} from "@crm/api/housekeepr-api";
import {skipToken} from "@reduxjs/toolkit/query";
import {authActions, authSelectors} from "@crm/services/auth-slice";
import {smoothScrollToTop} from "@crm/utils/helpers";
import createEventV2 from "@crm/utils/gtagv2";
import {cleaningActions, cleaningSelectors} from '@crm/services/cleaning-slice';
import CheckBoxCircle from "@crm/components/check-box/check-box-circle";
import Typography from "@crm/components/typography";
import {routes} from "@crm/routes";

type CleaningUserCardProps = { hidden?: boolean };

export const CleaningUserCard = (props: CleaningUserCardProps) => {
    const dispatch = useAppDispatch();

    const canGoToNext = useAppSelector(cleaningSelectors.selectCanGoToNextStep);

    const authenticated = useAppSelector(authSelectors.selectAuthenticated);

    const [existingUser, setExistingUser] = useState(false);

    const {
        firstName,
        lastName,
        email,
        countryCode,
        phoneNumber,
        password,
        passwordConfirmation,
        gclid,
    } = useAppSelector(contactInfoSelectors.selectContactInfo);

    const [registerUser, {
        isLoading: registeringUser,
        data: registeredUser,
        reset: resetRegisterUser,
        error: registrationError,
    }] = housekeeprApi.useRegisterUserMutation();

    const [login, {
        isLoading: loggingIn,
        data: loggedInUser,
        reset: resetLogin,
        error: loginError,
    }] = housekeeprApi.useLoginMutation();

    const loadingUser = registeringUser || loggingIn;

    const {
        data: tokenUser,
        error: tokenError,
    } = housekeeprApi.useCheckTokenQuery(
        authenticated && !loadingUser && !(loggedInUser ?? registeredUser)
            ? undefined
            : skipToken
    );

    const userResponse = loggedInUser ?? registeredUser ?? tokenUser;

    const user = useAppSelector(authSelectors.selectUser);

    const selectedAddress = useAppSelector(cleaningSelectors.selectAddress);
    const {data: address} = housekeeprApi.useRegisterAddressQuery(selectedAddress
        ? {
            darId: selectedAddress.adresse.id,
        }
        : skipToken
    );

    const validateEmail = (email: string) =>
        email
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );

    /**
     * Validate whether phone-number is made of digits and matches in length dependent on the countryCode.
     */
    const validatePhoneNumber = (phoneNumber: string, countryCode: number) => {
        let nDigits;
        if (countryCode == 47) {
            nDigits = 9;
        } else {
            nDigits = 8;
        }
        //TODO Consider building regex only once, instead of every execution
        const re = new RegExp('^\\d{' + nDigits + '}$'); //        /^\d{8}$/
        return phoneNumber.toLowerCase().match(re);
    };

    const validFirstName = firstName.length >= 2;
    const validLastName = firstName.length >= 1;
    const validEmail = validateEmail(email);
    const validPhoneNumber = validatePhoneNumber(phoneNumber, countryCode);
    const validPassword = password.length >= 8;
    const validPasswordConfirmation = password == passwordConfirmation;
    const validAll = validFirstName && validLastName && validEmail && validPhoneNumber && validPassword && validPasswordConfirmation;

    const registrationErrorMessage = (registrationError as any)?.data?.error ?? (registrationError as any)?.data?.message;
    const loginErrorMessage = (loginError as any)?.data?.error ?? (loginError as any)?.data?.message;

    const [acceptedTOS, setAcceptedTOS] = React.useState(false);
    const [acceptedMarketing, setAcceptedMarketing] = React.useState(false);

    useEffect(() => {
        if (tokenError && user) {
            dispatch(authActions.clearUser());
        } else if (user == undefined && userResponse?.user) {
            dispatch(authActions.setUser(userResponse));
            if (registeredUser != undefined) {
                createEventV2('sign_up', email);
            } else if (loggedInUser != undefined) {
                createEventV2('login', email);
            }
        }
    }, [address?.address_string, dispatch, email, loggedInUser, registeredUser, tokenError, user, userResponse]);

    return <div className={props.hidden ? orderStyles.hidden : orderStyles.cardWrapper}>
        <div className={orderStyles.card}>
            <div className={orderStyles.orderSection}>
                <h1 className={orderStyles.cardH1}>
                    Kundeoplysninger
                </h1>
                <div className={`${orderStyles.orderSectionContent}`}>
                    {user &&
                        <div className={orderStyles.orderHandler}>
                            <div className={'mt-4 max-w-full break-words'}>
                                <p className={'font-bold'}>
                                    {`Velkommen ${user.name}!`}
                                </p>

                                <p className={'mt-3 mb-1'}>
                                    Kontaktinformationer
                                </p>
                                <p>
                                    {`Navn: ${user.name}`}
                                </p>
                                <p>
                                    {`Email: ${user.email}`}
                                </p>
                                <p>
                                    {`Telefon: ${user.phone}`}
                                </p>
                            </div>
                        </div>
                    }
                    {!user && (existingUser
                            ? <div
                                id={'existing-user-content'}
                                className={`${orderStyles.orderSectionContent}`}
                            >
                                <div className={`max-w-full mx-auto ${orderStyles.flexColumn}`}>
                                    <TextField
                                        type="email"
                                        id="email"
                                        value={email}
                                        inputMode={'email'}
                                        placeholder="E-mail"
                                        errorTooltip={!email || validEmail ? undefined : 'E-mailen kunne ikke findes'}
                                        onChange={(event) => dispatch(contactInfoActions.setEmail(event.target.value))}
                                    />
                                    <TextField
                                        id="password"
                                        type="password"
                                        value={password}
                                        placeholder="Password"
                                        errorTooltip={!(password && !validPassword) ? undefined : 'E-mail og/eller password kunne ikke findes.'}
                                        errorOutsidePlaceholder={loginErrorMessage}
                                        onChange={(event) => dispatch(contactInfoActions.setPassword(event.target.value))}
                                    />
                                </div>
                            </div>
                            : <div
                                id={'new-user-content'}
                                className={`${orderStyles.orderSectionContent} ${orderStyles.flexRow} ${orderStyles.flexWrap}`}
                            >
                                <div className={orderStyles.desktopHalf}>
                                    <TextField
                                        id="firstName"
                                        value={firstName}
                                        placeholder="Fornavn"
                                        errorTooltip={!firstName || validFirstName ? undefined : 'Skriv venligst navn på mindst to bogstaver'}
                                        onChange={(event) => dispatch(contactInfoActions.setFirstName(event.target.value))}
                                    />

                                    <TextField
                                        id="lastName"
                                        value={lastName}
                                        placeholder="Efternavn"
                                        errorTooltip={!lastName || validLastName ? undefined : 'Skriv venligst efternavn på mindst et bogstav'}
                                        onChange={(event) => dispatch(contactInfoActions.setLastName(event.target.value))}
                                    />

                                    <PhoneNumberInputField
                                        type="tel"
                                        id="phoneNumber"
                                        value={phoneNumber}
                                        placeholder="Telefonnummer"
                                        errorTooltip={!phoneNumber || validPhoneNumber ? undefined : 'Formatet for telefonnummeret er ikke korrekt'}
                                        onSelect={(event) => dispatch(contactInfoActions.setCountryCode(event.value))}
                                        onChange={(event) => dispatch(contactInfoActions.setPhoneNumber(event.target.value))}
                                    />
                                </div>

                                <div className={orderStyles.desktopHalf}>
                                    <TextField
                                        type="email"
                                        id="email"
                                        inputMode={'email'}
                                        value={email}
                                        placeholder="E-mail"
                                        errorTooltip={!email || validEmail ? undefined : 'Email er ugyldig'}
                                        onChange={(event) => dispatch(contactInfoActions.setEmail(event.target.value))}
                                    />

                                    <TextField
                                        id="password"
                                        type="password"
                                        value={password}
                                        placeholder="Password (mindst 8 tegn)"
                                        errorTooltip={!password || validPassword ? undefined : 'Password skal være mindst 8 tegn langt.'}
                                        onChange={(event) => dispatch(contactInfoActions.setPassword(event.target.value))}
                                    />

                                    <TextField
                                        id="confirm-password"
                                        type="password"
                                        value={passwordConfirmation}
                                        placeholder="Bekræft password"
                                        errorTooltip={!passwordConfirmation || validPasswordConfirmation ? undefined : 'Password matcher ikke'}
                                        onChange={(event) => dispatch(contactInfoActions.setPasswordConfirmation(event.target.value))}
                                    />
                                </div>

                                {registrationError &&
                                    <p className={`text-center mx-auto mt-4 ${orderStyles.removeTopMargin}`}>
                                        {registrationErrorMessage}
                                    </p>
                                }
                                <div className={`${orderStyles.flexRow} px-2`}>
                                    <CheckBoxCircle
                                        id="acceptedTOS"
                                        onToggle={setAcceptedTOS}
                                        data-cy={'accept-tos'}
                                        $initialCheck={acceptedTOS}
                                    />
                                    <Typography variant={'body'}>
                                        Jeg har læst og accepterer Housekeeprs&nbsp;
                                        <a href={routes.privacyPolicy.href}
                                           rel="noopener noreferrer" target="_blank"
                                           className={orderStyles.hyperlink}>handelsbetingelser
                                        </a>
                                        &nbsp;og&nbsp;
                                        <a
                                            rel="noopener noreferrer"
                                            href={routes.bussPolicy.href}
                                            target="_blank"
                                            className={orderStyles.hyperlink}>privatlivspolitik
                                        </a>
                                    </Typography>
                                </div>
                                <div className={`${orderStyles.flexRow} ${orderStyles.removeTopMargin} px-2 pt-2`}>
                                    <CheckBoxCircle
                                        id="acceptedMarketing"
                                        onToggle={setAcceptedMarketing}
                                        data-cy={'accept-marketing'}
                                        $initialCheck={acceptedMarketing}
                                    />
                                    <Typography variant={'body'}>
                                        Ja tak til skræddersyet tilbud og info om Housekeepr
                                    </Typography>
                                </div>
                            </div>
                    )}

                    <div className={`${orderStyles.flexColumn} ${orderStyles.flexCrossCenter} mt-6`}>
                        <div className={`${orderStyles.buttonWrapper} mt-8 text-base max-w-xs w-full`}>
                            <button
                                disabled={loadingUser || (!existingUser && !user && (!acceptedTOS || !validAll || !address))}
                                onClick={
                                    () => {
                                        if (loadingUser) return;

                                        if (user) {
                                            setExistingUser(true);
                                            resetLogin();
                                            resetRegisterUser();
                                            dispatch(authActions.clearUser());
                                            createEventV2('logout', user.email);
                                            smoothScrollToTop();
                                            return;
                                        }

                                        if (existingUser) {
                                            smoothScrollToTop();
                                            const mutation = login({
                                                email: email,
                                                password: password,
                                            });
                                            mutation.unwrap().then(() => {
                                                smoothScrollToTop();
                                                dispatch(cleaningActions.nextPage());
                                            });
                                            return mutation;
                                        }

                                        if (!validAll || !address) return;

                                        const mutation = registerUser({
                                            name: `${firstName.trim()} ${lastName.trim()}`,
                                            email: email,
                                            phone: `+${countryCode}${phoneNumber}`,
                                            password: password,
                                            password_confirmation: passwordConfirmation,
                                            invoice_address_id: address.id,
                                            accepted_marketing: acceptedMarketing,
                                            gclid: gclid,
                                        });
                                        mutation.unwrap().then(() => {
                                            smoothScrollToTop();
                                            dispatch(cleaningActions.nextPage());
                                        });
                                        return mutation;
                                    }
                                }
                            >
                                {
                                    user
                                        ? 'Log ud'
                                        : existingUser
                                            ? loggingIn ? 'Logger ind...' : 'Log ind'
                                            : registeringUser ? 'Opretter bruger...' : 'Opret bruger'
                                }
                            </button>
                        </div>
                    </div>
                    {!user && <div
                        className={'underline cursor-pointer selection:select-none text-center mx-auto mt-4'}
                        onClick={() => setExistingUser(existingUser => !existingUser)}
                    >
                        {existingUser ? 'Er du ny kunde?' : 'Er du allerede kunde?'}
                    </div>}
                </div>
            </div>
        </div>
        <div className={`${orderStyles.flexRow} ${orderStyles.flexMainSpaceBetween} self-stretch`}>
            <div className={`${orderStyles.buttonWrapper} ${orderStyles.outline} w-[200px]`}>
                <button
                    onClick={() => {
                        dispatch(cleaningActions.previousPage());
                        smoothScrollToTop();
                    }}
                >
                    Tilbage
                </button>
            </div>
            <div
                className={`${orderStyles.buttonWrapper} ${orderStyles.outline} ${canGoToNext ? '' : orderStyles.disabled} w-[200px]`}>
                <button
                    onClick={() => {
                        dispatch(cleaningActions.nextPage());
                        smoothScrollToTop();
                    }}
                    disabled={!canGoToNext}
                >
                    Videre
                </button>
            </div>
        </div>
    </div>;
}

export default CleaningUserCard;
