import React from 'react';
import styled, {css} from 'styled-components';

interface DayInCalendarProps {
    date?: Date;
    $active?: boolean;
    disabled?: boolean;
    selected?: boolean;

    onClick?(): any;
}

const DateNumber = styled.span<DayInCalendarProps>`
    text-align: center;
    font-size: 16px;
    color: ${props => ({theme}) => props.$active
            ? theme.palette.common.darkBlue
            : theme.palette.common.turquoise};
    ${({theme}) => theme.typography.secondary};

    ${({theme}) => theme.breakpoints.down('tabletM')} {
        font-size: 15px;
    }

    ${({theme}) => theme.breakpoints.down('mobile')} {
        font-size: 14px;
    }

    color: ${props => props.selected && (({theme}) => theme.palette.common.lightTurquoise)};
`;

const DateButton = styled.button<DayInCalendarProps>`
    width: 50%;
    border: none;
    -webkit-border-radius: 20%;
    -moz-border-radius: 20%;
    border-radius: 20%;
    position: relative;
    margin: 0 2.14% 0 2.14%;
    ${props => props.$active && css`
        &:hover {
            cursor: pointer;
            background-color: ${({theme}) => theme.palette.common.darkBlue};
        }

        &:hover ${DateNumber} {
            color: ${({theme}) => theme.palette.common.lightTurquoise};
        }
    `}
    background-color: ${props => props.selected && (({theme}) => theme.palette.common.darkBlue)};
`;

const DayInCalendar = (props: DayInCalendarProps) =>
    <div className='w-full flex justify-center'>
        <DateButton
            $active={props.$active}
            disabled={props.disabled ?? !props.$active}
            onClick={props.onClick}
            selected={props.selected}
            className="aspect-square w-full mobile:w-1/2"
            data-cy={props.$active ? 'day-button-active' : 'day-button'}
        >
            {props.date && <DateNumber $active={props.$active} selected={props.selected}>
                {props.date.getDate()}
            </DateNumber>}
        </DateButton>
    </div>;

export default DayInCalendar;
