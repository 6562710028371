import {motion} from 'framer-motion';
import {ReactElement, useState} from 'react';
import styled, {useTheme} from 'styled-components';
import {HoverVariant, StylizedButton} from '../button/stylized-button';
import Typography from '../typography';
import styles from './newsletter-subscription.module.css';
import {SubscriptionForm} from './subcription-form';
import {Close} from '@crm/utils/images';
import IconButton from '../icon-button';
import createGtagEvent from "@crm/utils/gtag";
import {NewsletterSubscriptionAbout} from "@crm/api/models/requests/housekeepr-api";

interface Props {
    newsAbout: NewsletterSubscriptionAbout;
    textColor?: string;
    backgroundColor?: string;
    $hoverVariant?: HoverVariant;
    initialButtonText?: string;
    successText?: string;
    $phoneInput?: boolean;
    cvrInput?: boolean;
    title?: ReactElement | string;
    subtitle?: string;
    onToggle?: () => void;
    whenText?: string;
    shadow?: string;
    openDown?: boolean;
    checkBoxCircleColor?: string;
    checkBoxCheckmarkColor?: string;
}

const Title = styled(Typography)<{ $textColor: string; $lessMb: boolean }>`
    text-align: center;
    font-size: 22px;
    letter-spacing: 0;
    line-height: 29px;
    margin-bottom: ${props => props.$lessMb ? 10 : 30}px;
    color: ${props => props.$textColor};
`;

const CloseButton = styled(IconButton)<{ $fillColor: string }>`
    path {
        transition: fill 0.2s;
        scale: 0.85;
        fill: ${props => props.$fillColor};
    }

    &:hover {
        path {
            fill: #fff;
        }
    }
`;

const Subtitle = styled(Typography)`
    font-size: 12px;
    text-align: center;
    padding-bottom: 15px;
`;

export const NewsletterSubscription = (props: Props) => {
    const theme = useTheme();

    const {
        newsAbout,
        title = <>
            Få besked når Housekeepr <br/> åbner ny service i din by
        </>,
        subtitle,
        $phoneInput = true,
        cvrInput = false,
        backgroundColor = theme.palette.common.darkBrown,
        textColor = theme.palette.common.gold,
        $hoverVariant = 'whiteText',
        initialButtonText = 'FÅ BESKED NÅR SERVICE ER TILGÆNGELIG',
        successText = 'TAK. DU VIL FÅ BESKED',
        whenText,
        shadow,
        checkBoxCircleColor = textColor,
        checkBoxCheckmarkColor = backgroundColor,
    } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [btnText, setBtnText] = useState(initialButtonText);

    const handleSubmitted = (res: 'error' | 'success', email?: string) => {
        if (res == 'success' && !isSubmitted) {
            createGtagEvent('email_subscription', newsAbout, undefined, 'mail_list', {'email': email});
            setIsOpen(false);
            setIsSubmitted(true);
            setBtnText(successText);
        }
    };

    const handleOpen = () => {
        if (isSubmitted) {
            return;
        }
        setIsOpen(!isOpen);
        props.onToggle?.();
    }

    const handleClose = () => {
        if (!isSubmitted) {
            createGtagEvent('email_subscription_abandoned', newsAbout, undefined, 'mail_list');
        }
        setIsOpen(false);
        props.onToggle?.();
    }

    return <div
        className="relative inline-block z-40"
        style={{
            minWidth: 'min(400px, 80vw)',
            width: 442,
            maxWidth: 'min(400px, 80vw)',
        }}>
        {' '}
        <motion.div
            className={`absolute ${styles.subscriptionContainer} ${props.openDown ? styles.top : ''}`}
            style={{
                backgroundColor: backgroundColor,
                color: textColor,
                boxShadow: shadow
            }}
            layout
            data-isopen={isOpen}
            transition={{
                duration: 0.5,
                delay: 0,
                ease: [0, 0.71, 0.2, 1.01]
            }}
        >
            <div>
                <div className="h-5">
                    <CloseButton onClick={handleClose} $fillColor={textColor}>
                        <Close/>
                    </CloseButton>
                </div>
                <Title variant="h40" $textColor={textColor} $lessMb={subtitle != undefined}>
                    {title}
                </Title>
            </div>
            {subtitle && <div className="w-full flex justify-center">
                <div>
                    <Subtitle>{subtitle}</Subtitle>
                </div>
            </div>}
            <SubscriptionForm
                $display={isOpen}
                $phoneInput={$phoneInput}
                cvrInput={cvrInput}
                newsAbout={newsAbout}
                onSubmitted={(res, email) => handleSubmitted(res, email)}
                backgroundColor={backgroundColor}
                textColor={textColor}
                whenText={whenText}
                checkBoxCircleColor={checkBoxCircleColor}
                checkBoxCheckmarkColor={checkBoxCheckmarkColor}
            />
        </motion.div>
        <StylizedButton
            $textColor={textColor}
            $bgColor={backgroundColor}
            fontSize={16}
            className="w-full"
            $hoverVariant={$hoverVariant}
            onClick={handleOpen}
            style={{
                boxShadow: shadow,
            }}
        >
            {btnText}
        </StylizedButton>
    </div>;
};

