import {between, down, only, up} from '../mixins/breakpoints';

export const breakpoints = [
    'mobileM',
    'mobile',
    'tabletM',
    'tablet',
    'laptop',
    'desktop',
    'largeScreen'
] as const;
export type Breakpoint = typeof breakpoints[number];
export type Breakpoints = Record<Breakpoint, number>;

export type TypographyVariant =
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h40'
    | 'body'
    | 'subtitle'
    | 'error';
export type Typography = Record<TypographyVariant, any>;

export const themeColorTypes = [
    'turquoise',
    'lighterTurquoise',
    'lightTurquoise',
    'darkBlue',
    'gold',
    'darkGreen',
    'lightGreen',
    'white',
    'darkGrey',
    'lightGrey',
    'darkBrown',
    'red'
] as const;

export type ThemeColorType = typeof themeColorTypes[number];
export type ThemeColorTypes = Record<ThemeColorType, string>;

export interface BreakPoints {
    breakpoints: {
        values: Breakpoints;
        up: typeof up;
        down: typeof down;
        between: typeof between;
        only: typeof only;
    };
}

export interface LightTheme {
    breakpoints: {
        values: Breakpoints;
        up: typeof up;
        down: typeof down;
        between: typeof between;
        only: typeof only;
    };
    spacing: (value: number | string) => string;

    palette: {
        background: {
            default: string;
        };
        common: ThemeColorTypes;
        error: {
            main: string;
        };
        primary: {
            main: string;
            contrastText: string;
        };
        secondary: {
            main: string;
            contrastText: string;
        };
        success: {
            main: string;
        };
        text: {
            primary: string;
            primaryHover: string;
            secondary: string;
            secondaryHover: string;
        };
    };
    typography: {
        primary: {
            fontFamily: string;
        };
        secondary: {
            fontFamily: string;
        };
        weight: {
            light: number;
            regular: number;
            medium: number;
            bold: number;
        };
        h1: {
            fontFamily: string;
            fontSize: string;
            fontWeight: number;
            lineHeight: number | string;
            mobile: {
                fontSize: string;
            };
            mobileM: {
                fontSize: string;
            };
            tablet: {
                fontSize: string;
            };
        };
        h2: {
            fontFamily: string;
            fontSize: string;
            fontWeight: number;
            lineHeight: number | string;
            mobile: {
                fontSize: string;
            };
        };
        h3: {
            fontFamily: string;
            fontSize: string;
            fontWeight: number;
            lineHeight: number | string;
            mobile: {
                fontSize: string;
            };
        };
        h4: {
            fontFamily: string;
            fontSize: string;
            fontWeight: number;
            lineHeight: number | string;
            mobile: {
                fontSize: string;
            };
        };
        h40: {
            fontFamily: string;
            fontSize: string;
            fontWeight: number;
            lineHeight: number | string;
            mobile: {
                fontSize: string;
            };
        };
        h5: {
            fontFamily: string;
            fontSize: string;
            fontWeight: number;
            lineHeight: number | string;
        };
        error: {
            fontFamily: string;
            fontSize: string;
            fontWeight: number;
            lineHeight: number | string;
        };
        subtitle: {
            fontFamily: string;
            fontSize: string;
            fontWeight: number;
            lineHeight: number | string;
            mobile: {
                fontSize: string;
            };
        };
        body: {
            fontFamily: string;
            fontSize: string;
            fontWeight: number;
            lineHeight: number | string;
            letterSpacing?: number | string;
            mobile: {
                fontSize: string;
            };
        };
    };
}

const lightTheme: LightTheme = {
    breakpoints: {
        values: {
            mobileM: 375,
            mobile: 580,
            tabletM: 768,
            tablet: 960,
            laptop: 1260,
            desktop: 1320,
            largeScreen: 1920
        },
        up: up,
        down: down,
        between: between,
        only: only
    },
    spacing: (value: number | string) => typeof value === 'string' ? value : `${value * 4}px`,
    palette: {
        background: {
            default: '#97C4C9'
        },
        common: {
            lighterTurquoise: '#C8E9EC',
            lightTurquoise: '#B2D6D9',
            lightGreen: '#BFDDBB',
            turquoise: '#97C4C9',
            darkBlue: '#2A4D4F',
            gold: '#E7CF8C',
            darkGreen: '#104925',
            white: '#FFFFFF',
            darkGrey: '#707070',
            lightGrey: '#00000065',
            darkBrown: '#4C402A',
            red: '#CC3E0C'
        },
        error: {
            main: '#CC3E0C'
        },
        primary: {
            main: '#2A4D4F',
            contrastText: '#97C4C9'
        },
        secondary: {
            main: '#97C4C9',
            contrastText: '#2A4D4F'
        },
        success: {
            main: '#00b385'
        },
        text: {
            primary: '#2A4D4F',
            primaryHover: '#000',
            secondary: '#ffffff',
            secondaryHover: '#ffffff'
        }
    },
    typography: {
        primary: {
            fontFamily: 'Cooper Lt BT, sans-serif'
        },
        secondary: {
            fontFamily: 'Graphik, sans-serif'
        },
        weight: {
            light: 300,
            regular: 400,
            medium: 500,
            bold: 600
        },
        body: {
            fontFamily: 'Graphik, sans-serif',
            fontSize: '15px',
            fontWeight: 400,
            lineHeight: 1.3333,
            mobile: {
                fontSize: '15px'
            }
        },
        h1: {
            fontFamily: 'Cooper Lt BT, sans-serif',
            fontSize: '64px',
            fontWeight: 300,
            lineHeight: 1.2,
            tablet: {
                fontSize: '50px'
            },
            mobile: {
                fontSize: '36px'
            },
            mobileM: {
                fontSize: '30px'
            }
        },
        h2: {
            fontFamily: 'Cooper Lt BT, sans-serif',
            fontSize: '42px',
            fontWeight: 300,
            lineHeight: 1.2,
            mobile: {
                fontSize: '36px'
            }
        },
        h3: {
            fontFamily: 'Cooper Lt BT, sans-serif',
            fontSize: '24px',
            fontWeight: 500,
            lineHeight: 1.2,
            mobile: {
                fontSize: '24px'
            }
        },
        h4: {
            fontFamily: 'Cooper Lt BT, sans-serif',
            fontSize: '24px',
            fontWeight: 300,
            lineHeight: 1.5,
            mobile: {
                fontSize: '16px'
            }
        },
        h40: {
            fontFamily: 'Cooper Lt BT, sans-serif',
            fontSize: '36px',
            fontWeight: 300,
            lineHeight: 1.5,
            mobile: {
                fontSize: '24px'
            }
        },
        h5: {
            fontFamily: 'Graphik, sans-serif',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: 1.286
        },
        error: {
            fontFamily: 'Graphik, sans-serif',
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: 1.286
        },
        subtitle: {
            fontFamily: 'Graphik, sans-serif',
            fontSize: '24px',
            fontWeight: 400,
            lineHeight: 1.292,
            mobile: {
                fontSize: '16px'
            }
        }
    }
};

export default lightTheme;
