import React from 'react';
import styled, {useTheme} from 'styled-components';
import {useMediaQuery} from '@crm/hooks/use-media-query/use-media-query';
import useMenu from '../../../../hooks/use-menu';
import {getFluidSizing} from '@crm/utils/helpers';
import {Close} from '../../../../utils/images';
import {Box} from '../../../box';
import {IconButton} from '../../../icon-button';
import HorizontalSpacing from '../../horizontal-spacing';
import {LogoContainer} from '../logo-container';
import {MenuLinks} from './menu-links';


const MegaMenuRoot = styled.div.attrs({className: 'shared-nav-header'})<{ open: boolean }>`
    position: absolute;
    top: 0;
    z-index: 11;
    width: 100%;
    border-radius: 0 0 10px 10px;
    left: 0;
    background-color: ${({theme}) => theme.palette.common.turquoise};

    min-height: ${({theme}) =>
            getFluidSizing(
                    200,
                    300,
                    theme.breakpoints.values.largeScreen,
                    theme.breakpoints.values.mobile
            )};

    display: flex;
    align-items: flex-start;
    flex-direction: column;
    box-shadow: 0 0 35px #00000065;
    padding-bottom: 42px;
    transform: ${({open}) => open ? 'translateY(0)' : 'translateY(-100%)'};

    ${({theme}) => theme.breakpoints.down('mobile')} {
        padding-bottom: 0;
        height: 100vh;
        transform: ${({open}) => open ? 'translateX(0)' : 'translateX(100%)'};
        justify-content: space-between;
        overflow-y: hidden;
        border-radius: 0;
    }

    transition: transform 0.2s ease;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  padding: 0 46px;
  align-items: center;
  justify-content: space-between;
`;

const CloseButtonContainer = styled.div`
  path {
    transition: fill 0.2s;
    fill: ${({theme}) => theme.palette.common.red};
  }

  &:hover {
    path {
      fill: white;
    }
  }
`;

const CloseButton: React.FC = () =>
    <CloseButtonContainer className="menu-icon menu-icon-test-close">
        <Close/>
    </CloseButtonContainer>;

const MegaMenu = (props: { onOpen?(opened: boolean): void }) => {
    const rootRef = React.useRef<null | HTMLDivElement>(null);
    const {opened, handleClose} = useMenu();
    const theme = useTheme();
    const mobileVw = useMediaQuery(theme.breakpoints.down('mobile'));

    const onClickOutsideMenu = React.useCallback(
        (ev: MouseEvent) => {
            if (rootRef?.current?.contains(ev.target as Node) || mobileVw) {
                return;
            }
            handleClose();
        },
        [handleClose, mobileVw]
    );

    React.useEffect(() => {
        if (opened) {
            document.addEventListener('mousedown', onClickOutsideMenu, false);
        } else {
            document.removeEventListener('mousedown', onClickOutsideMenu, false);
        }

        props.onOpen?.(opened);

        return () => document.removeEventListener('mousedown', onClickOutsideMenu, false);
    }, [onClickOutsideMenu, opened, props]);

    if (mobileVw) {
        return <MegaMenuRoot open={opened} ref={rootRef}>
            <HorizontalSpacing>
                <div className="flex justify-between">
                    <LogoContainer/>
                    <Box $flexDirection="row" display="flex" $alignItems="center">
                        <IconButton onClick={handleClose}>
                            <CloseButton/>
                        </IconButton>
                    </Box>
                </div>
                <MenuLinks style={{marginTop: 42}}/>
            </HorizontalSpacing>
        </MegaMenuRoot>;
    }

    return <MegaMenuRoot open={opened} ref={rootRef}>
        <Container>
            <LogoContainer/>

            <Box $flexDirection="row" display="flex" $alignItems="center">
                <IconButton onClick={handleClose}>
                    <CloseButton/>
                </IconButton>
            </Box>
        </Container>
        <Container>
            <MenuLinks style={{marginTop: 20}}/>
        </Container>
    </MegaMenuRoot>;
};

export default MegaMenu;
