import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {housekeeprApi} from '@crm/api/housekeepr-api';
import {dawaApi} from '@crm/api/dawa-api';
import {wizardReducer, wizardSlice} from '@crm/services/wizard-slice';
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import {createWrapper} from 'next-redux-wrapper';
import {setupListeners} from '@reduxjs/toolkit/query';
import {
    createMigrate,
    FLUSH,
    PAUSE,
    PERSIST,
    persistReducer,
    persistStore,
    PURGE,
    REGISTER,
    REHYDRATE
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {stepperReducer, stepperSlice} from '@crm/services/stepper-slice';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import {contactInfoReducer, contactInfoSlice} from '@crm/services/contact-info-slice';
import {authReducer, authSlice} from '@crm/services/auth-slice';
import {windowCleaningReducer, windowCleaningSlice} from '@crm/services/window-cleaning-slice';
import {cleaningReducer, cleaningSlice} from '@crm/services/cleaning-slice';
import {CLEANING_STEP_HOME} from "@crm/models/steps/cleaning_step_home";

const migrations = {
    1: (state: any) => {
        return {
            _persist: state._persist,
        };
    },
    2: (state: any) => {
        return {
            ...state,
            cleaning: {
                ...state.cleaning,
                currentPage: undefined,
                currentStep: CLEANING_STEP_HOME,
            }
        };
    },
    3: (state: any) => {
        return {
            ...state,
            cleaning: state.cleaning2,
            cleaning2: undefined,
        };
    }
};

const persistConfig = {
    key: 'root',
    version: 3,
    whitelist: [authSlice.name, wizardSlice.name, windowCleaningSlice.name, stepperSlice.name, contactInfoSlice.name, cleaningSlice.name],
    stateReconciler: autoMergeLevel2,
    storage,
    debug: false,
    migrate: createMigrate(migrations, {debug: false})
};

const reducers = combineReducers({
    [housekeeprApi.reducerPath]: housekeeprApi.reducer,
    [dawaApi.reducerPath]: dawaApi.reducer,
    [authSlice.name]: authReducer,
    [contactInfoSlice.name]: contactInfoReducer,
    [wizardSlice.name]: wizardReducer,
    [cleaningSlice.name]: cleaningReducer,
    [stepperSlice.name]: stepperReducer,
    [windowCleaningSlice.name]: windowCleaningReducer
});

const persistedReducer = persistReducer<ReturnType<typeof reducers>>(persistConfig, reducers);

const makeConfiguredStore = (reducer: typeof reducers) => {
    const store = configureStore({
        reducer: reducer,
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware({
                serializableCheck: {
                    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
                }
            })
                .concat(housekeeprApi.middleware)
                .concat(dawaApi.middleware),
        devTools: true
    });
    setupListeners(store.dispatch);
    return store;
};

const makeConfiguredPersistedStore = (reducer: typeof persistedReducer) => {
    const store = configureStore({
        reducer: reducer,
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware({
                serializableCheck: false
            })
                .concat(housekeeprApi.middleware)
                .concat(dawaApi.middleware),
        devTools: true
    });
    setupListeners(store.dispatch);
    return store;
};

const makeStore = () => {
    const isServer = typeof window === 'undefined';
    if (isServer) {
        return makeConfiguredStore(reducers);
    }

    const store = makeConfiguredPersistedStore(persistedReducer);
    const jsStore = store as any;
    jsStore.__persistor = persistStore(jsStore); // Nasty hack (from docs)
    return store;
};


export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];

//export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action>;
//export type AppReducer = typeof reducers;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export const wrapper = createWrapper<AppStore>(makeStore);

