import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {User} from "@crm/api/models/common/housekeepr-api";
import {hash} from "@crm/utils/helpers";

declare global {
    interface Window {
        dataLayer: any[];
    }
}

export interface AuthState {
    user?: User;
    token?: string;
}

const initialState: AuthState = {};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser(state, action: PayloadAction<{ user: User, token: string, }>) {
            state.user = action.payload.user;
            state.token = action.payload.token;

            // Push user_id to Google Tag Manager dataLayer
            if (typeof window !== 'undefined' && window.dataLayer) {
                const firstName = state.user.name.split(' ').slice(0, -1).join(' ');
                const lastName = state.user.name.split(' ').slice(-1).join(' ');
                window.dataLayer.push({
                    'user_data': {
                        'id': state.user.id,
                        'first_name': hash(firstName),
                        'last_name': hash(lastName),
                        'email': hash(state.user.email),
                        'phone': hash(state.user.phone),
                    },
                });
            }
        },
        clearUser(state) {
            state.user = undefined;
            state.token = undefined;

            // Clear user_id from Google Tag Manager dataLayer
            if (typeof window !== 'undefined' && window.dataLayer) {
                window.dataLayer.push({
                    'user_data': {
                        'id': undefined,
                        'email': undefined,
                        'phone': undefined,
                        'first_name': undefined,
                        'last_name': undefined,
                    },
                });
            }
        },
    },
    selectors: {
        selectAuthenticated: (state) => !!state.token && !!state.user,
        selectUser: (state) => state.user,
    },
});

export const {actions: authActions, reducer: authReducer, selectors: authSelectors} = authSlice;
