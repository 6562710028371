import orderStyles from '@crm/styles/order.module.css';
import React, {useEffect, useMemo, useState} from 'react';
import {useAppDispatch, useAppSelector} from '@crm/services/store';
import LoadingDots from '@crm/components/icon/loading-dots/loading-dots';
import {CleaningOrderRequest, CleaningServicePriceRequest} from '@crm/api/models/requests/housekeepr-api';
import {housekeeprApi} from '@crm/api/housekeepr-api';
import {skipToken} from '@reduxjs/toolkit/query';
import Typography from '../typography/typography';
import LegacyLinksToApp from '@crm/components/link/legacy-links-to-app';
import {useRouter} from 'next/router';
import useCallMe from '@crm/hooks/use-call-me/use-call-me';
import {smoothScrollToTop} from '@crm/utils/helpers';
import {cleaningActions, cleaningSelectors} from '@crm/services/cleaning-slice';
import {CLEANING_STEP_SUBMITTING_ORDER} from "@crm/models/steps/cleaning_step_submitting_order";

type CleaningOrderHandlerCardProps = { hidden?: boolean };

export const CleaningOrderHandlerCard = (props: CleaningOrderHandlerCardProps) => {
    const dispatch = useAppDispatch();
    const router = useRouter();

    const {openCallMe, submitted: submittedCallMe} = useCallMe();

    const [openedPaymentUrl, setOpenedPaymentUrl] = useState(false);
    const [submittedOrder, setSubmittedOrder] = useState(false);
    const [stateText, setStateText] = useState('Behandler din ordre...');

    const cleaningState = useAppSelector(cleaningSelectors.selectCleaning);

    const pathname = router.pathname;
    const {payment_authorization: paymentAuthorization, ...query} = router.query;

    const {
        currentStep
    } = cleaningState;

    const {
        address,
        area
    } = cleaningState;

    const {
        serviceId,
        frequencyId,
        paymentIntervalId
    } = cleaningState;

    const {
        extraServices
    } = cleaningState;

    const {
        availability
    } = cleaningState;

    const {
        authenticated
    } = cleaningState;

    const {
        verifiedCode,
        paymentType,
        canPay,
        paymentAuthorized,
        paymentError
    } = cleaningState;

    const {data: checkedCode} = housekeeprApi.useCheckCodeQuery(verifiedCode && serviceId && frequencyId ? {
        code: verifiedCode,
        service_id: serviceId,
        frequency_id: frequencyId
    } : skipToken);

    const codeReady = !verifiedCode || !!checkedCode;

    const active = currentStep === CLEANING_STEP_SUBMITTING_ORDER && authenticated && !cleaningState.completed;

    const {
        data: location
    } = housekeeprApi.useGetLocationQuery(active && address
        ? {
            dawaId: address.adresse.id
        }
        : skipToken
    );

    const [
        createPaymentMethod,
        {
            data: createdPaymentMethod,
            isLoading: creatingPaymentMethod,
        }
    ] = housekeeprApi.useStorePaymentMethodMutation();

    const {
        data: paymentMethodAvailability
    } = housekeeprApi.useGetPaymentAvailabilityQuery(active ? undefined : skipToken);

    const orderRequest = useMemo(() => !!active && !!location && !!availability
            ? {
                location_id: location.id,
                specified_area: area,
                service_id: serviceId,
                frequency_id: frequencyId,
                payment_interval_id: paymentIntervalId,

                extra_services: extraServices,

                schedule_id: availability.schedule_id,
                time_slot_id: availability.time_slot_id,

                discount_code: checkedCode?.discount_code?.code,
                referral_code: checkedCode?.referral_code?.code,
                campaign_code: checkedCode?.campaign_code?.code
            } as CleaningOrderRequest
            : undefined,
        [active, area, availability, checkedCode?.campaign_code?.code, checkedCode?.discount_code?.code, checkedCode?.referral_code?.code, extraServices, frequencyId, location, paymentIntervalId, serviceId]);

    const {
        data: orderResult,
        isLoading: ordering,
        error: orderError
    } = housekeeprApi.useServiceOrderQuery(orderRequest && active && canPay && codeReady ? orderRequest : skipToken);

    const {
        data: serviceResult
    } = housekeeprApi.useGetServiceQuery(serviceId ?? skipToken);

    const frequency = serviceResult?.frequencies.find(f => f.id == frequencyId);

    const canSearchPrice = active && !!serviceId && !!location;

    const priceRequest = canSearchPrice ? {
        service_id: serviceId,
        address_id: location.address.id
    } as CleaningServicePriceRequest : undefined;

    const {
        data: priceResult
    } = housekeeprApi.useGetServicePriceQuery(active && priceRequest ? priceRequest : skipToken);


    const {data: availableExtraServices} = housekeeprApi.useGetExtraServicesQuery(serviceId && active ? serviceId : skipToken);
    const {data: paymentIntervals} = housekeeprApi.useGetPaymentIntervalsQuery(paymentIntervalId && active ? undefined : skipToken);

    const revenueReady = !!priceResult && availableExtraServices && paymentIntervals;

    const paymentInterval = paymentIntervals?.find(paymentInterval => paymentInterval.id == paymentIntervalId);
    const discountCode = checkedCode?.discount_code;

    const avgWeeksPerMonth = 4.345;
    const subscriptionValueMultiplier = 12;
    const isRecurring = frequency?.every != 0;

    const visitsPerMonth = isRecurring
        ? avgWeeksPerMonth / (frequency?.every ?? 1)
        : 1;

    const servicePrice = priceResult;

    const extraServicePrice = extraServices !== undefined && extraServices.length >= 1 && availableExtraServices
        ? extraServices
            .map(orderExtraService => {
                const extraService = availableExtraServices.find(extraService => extraService.id == orderExtraService.id);
                const extraServicePrice = extraService?.price ?? 0;
                return extraServicePrice * orderExtraService.count * (1 / Math.max(1, orderExtraService.how_often));
            })
            .reduce((sum, price) => sum + price, 0)
        : 0;

    const paymentIntervalDiscountMultiplier = (1 - (paymentInterval?.discount ?? 0) / 100);
    const codeDiscount = (discountCode && servicePrice) ? servicePrice * ((discountCode?.percent_off ?? 0) / 100) + (discountCode?.value ?? 0) : 0;

    const averageFullPrice = servicePrice
        ? servicePrice
        + extraServicePrice
        - codeDiscount
        : 0;

    const averagePrice =
        averageFullPrice
        * paymentIntervalDiscountMultiplier;

    const finalPrice = isRecurring
        ? averagePrice * subscriptionValueMultiplier * visitsPerMonth
        : averagePrice;
    const order = orderResult ?? cleaningState.order;

    const error = orderError;

    useEffect(() => {
        if (ordering && !order) {
            window.onbeforeunload = () => {
                return 'Du er ved at forlade siden. Er du sikker på at du vil forlade siden?';
            };
        } else if (order) {
            window.onbeforeunload = null;
        }
    }, [order, ordering]);

    useEffect(() => {
        if (error) return;

        if (!active) return;

        if (!paymentType) return;

        if (canPay) {
            setOpenedPaymentUrl(true);

            if (!ordering && orderRequest && !submittedOrder) {
                setStateText('Opretter din ordre...');
                setSubmittedOrder(true);
            }
        } else if (paymentAuthorization) {
            // Previous payment method failed - show error
            if (paymentAuthorization == 'failed' || paymentError) {
                if (!paymentError) {
                    dispatch(cleaningActions.setPaymentError('Der skete en fejl under oprettelsen af betalingsmetoden.'));

                    console.error('Payment authorization failed.');
                    router.replace({
                        pathname,
                        query
                    }, undefined, {shallow: true}).catch(console.error);
                }
            }

            // Payment authorized
            else if (paymentAuthorization == 'success' || paymentAuthorized) {
                if (!paymentAuthorized) {
                    dispatch(cleaningActions.setPaymentAuthorized(true));
                }

                router.replace({
                    pathname,
                    query
                }, undefined, {shallow: true}).catch(console.error);
            }
        } else if (paymentMethodAvailability) {

            // User has available payment method - no further action required
            if (paymentMethodAvailability.available) {
                setOpenedPaymentUrl(true);
                dispatch(cleaningActions.setCanPay(true));
            }

            // User has no available payment method - create one
            else if (!createdPaymentMethod && !creatingPaymentMethod) {
                createPaymentMethod({type: paymentType, continue_url: window.location.href});
            }

            // Open url of newly created payment method
            else if (createdPaymentMethod && !openedPaymentUrl) {
                setStateText('Du bliver nu sendt til betaling...');
                setOpenedPaymentUrl(true);

                window.onbeforeunload = () => {
                    return 'Du er ved at forlade siden. Er du sikker på at du vil forlade siden?';
                };

                setTimeout(() => {
                    window.onbeforeunload = null;
                    window.open(createdPaymentMethod.link, '_self');
                }, 5000);
            }
        }
    }, [active, canPay, createPaymentMethod, createdPaymentMethod, creatingPaymentMethod, dispatch, error, openedPaymentUrl, orderRequest, ordering, pathname, paymentAuthorization, paymentAuthorized, paymentError, paymentMethodAvailability, paymentType, query, router, submittedOrder]);

    useEffect(() => {
        if (error) return;

        if (!active) return;

        if (orderResult && revenueReady) {
            dispatch(cleaningActions.markCompleted({
                order: orderResult,
                revenue: finalPrice
            }));
        }
    }, [active, dispatch, error, finalPrice, orderResult, revenueReady]);


    if (order) {
        return <div
            className={orderStyles.cardWrapper}
        >
            <div
                className={orderStyles.card}
            >
                <h1 className={`${orderStyles.cardH1}`}>
                    Tak fordi du har valgt Housekeepr.
                </h1>
                <div
                    className={`${orderStyles.orderSectionContent}`}
                    style={{marginTop: -44}}
                >
                    <p>
                        Du vil snarest modtage en mail med overblik over din bestilling.
                    </p>
                    <p>
                        Download app&apos;en der hvor du normalt henter dine apps, eller klik på ikonerne
                        nedenfor.
                    </p>
                    <p>
                        I app&apos;en kan du chatte direkte med vores hjælpsomme support og håndtere alt omkring din
                        service.
                    </p>
                    <p>
                        Vi glæder os til at hjælpe dig.
                    </p>
                </div>
                <LegacyLinksToApp className={orderStyles.removeTopMargin} style={{paddingLeft: 0}}/>
            </div>
            <div
                className={`${orderStyles.buttonWrapper} ${orderStyles.outline} self-start w-[200px]`}>
                <button
                    onClick={() => {
                        router.push('/')
                            .then(() => {
                                dispatch(cleaningActions.resetState());
                            })
                            .catch(console.error);
                    }}
                >
                    Tilbage til forsiden
                </button>
            </div>
        </div>;
    }

    return <div className={props.hidden ? orderStyles.hidden : orderStyles.cardWrapper}>
        <div className={orderStyles.card}>
            <div className={orderStyles.orderSection}>
                <h1 className={`${orderStyles.cardH1}`}>
                    Behandler din ordre...
                </h1>

                <div
                    className={orderStyles.orderHandler}
                >
                    {error || paymentError || (paymentAuthorized && paymentMethodAvailability && !paymentMethodAvailability.available)
                        ? <>
                            <Typography
                                variant={'body'}
                                textAlign={'center'}
                            >
                                <p>{(error as any)?.data?.error?.toString() ?? paymentError ?? ' Der skete en fejl ved oprettelsen af din ordre.'}</p>
                                <p>Prøv igen senere eller skriv dig op til at blive ringet op.</p>
                                <div className="bg-red rounded-xl mt-3 px-4 py-1 mx-auto max-w-[144px]"
                                     style={{cursor: submittedCallMe ? undefined : 'pointer'}}>
                                    <Typography
                                        style={{fontSize: 16, color: 'white'}}
                                        onClick={!submittedCallMe ? openCallMe : undefined}
                                    >
                                        {submittedCallMe ? 'Tak! Vi kontakter dig' : 'Ring mig op'}
                                    </Typography>
                                </div>
                            </Typography>
                        </>
                        : <>
                            <LoadingDots/>
                            <Typography
                                variant={'body'}
                                textAlign={'center'}
                                className={'mt-4'}
                            >
                                {stateText}
                            </Typography>
                            <p style={{fontSize: 11, marginTop: 16}}>
                                Luk ikke denne side før ordren er gennemført.
                            </p>
                        </>
                    }
                </div>
            </div>
        </div>
        {(error || paymentError || (paymentAuthorized && paymentMethodAvailability && !paymentMethodAvailability.available)) &&
            <div
                className={`${orderStyles.buttonWrapper} ${orderStyles.outline}} self-start w-[200px]`}>
                <button
                    onClick={() => {
                        dispatch(cleaningActions.retryPaymentStep());
                        smoothScrollToTop();
                    }}
                >
                    Prøv igen
                </button>
            </div>
        }
    </div>;
};

export default CleaningOrderHandlerCard;
