import orderStyles from '@crm/styles/order.module.css';
import {capitalize, smoothScrollToTop} from '@crm/utils/helpers';
import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '@crm/services/store';
import {housekeeprApi} from '@crm/api/housekeepr-api';
import {skipToken} from '@reduxjs/toolkit/query';
import {PaymentCardOptions} from '@crm/utils/images';
import CheckBoxCircle from '@crm/components/check-box/check-box-circle';
import {routes} from '@crm/routes';
import Typography from '@crm/components/typography';
import createEventV2 from '@crm/utils/gtagv2';
import {cleaningActions, cleaningSelectors} from '@crm/services/cleaning-slice';
import {paymentIntervalDisplayString} from '@crm/api/models/common/housekeepr-api';
import {CleaningRedeemCode} from '@crm/components/cleaning/cleaning-redeem-code';
import {CLEANING_STEP_HOME} from "@crm/models/steps/cleaning_step_home";
import {CLEANING_STEP_PROGRAMME} from "@crm/models/steps/cleaning_step_programme";
import {CLEANING_STEP_TIME} from "@crm/models/steps/cleaning_step_time";
import {CLEANING_STEP_PAYMENT} from "@crm/models/steps/cleaning_step_payment";
import {CLEANING_STEP_SUBMITTING_ORDER} from "@crm/models/steps/cleaning_step_submitting_order";
import {authSelectors} from "@crm/services/auth-slice";
import {CleaningServicePriceRequest} from "@crm/api/models/requests/housekeepr-api";
import {VAT} from "@crm/utils/constants";

type CleaningPaymentCardProps = { hidden?: boolean };

export const CleaningPaymentCard = (props: CleaningPaymentCardProps) => {
    const dispatch = useAppDispatch();

    const currentStep = useAppSelector(cleaningSelectors.selectStep);
    const isOnThisPage = currentStep === CLEANING_STEP_PAYMENT;
    const canGoToNext = useAppSelector(cleaningSelectors.selectCanGoToNextStep);

    const selectedPaymentType = useAppSelector(cleaningSelectors.selectPaymentType);

    const selectedAddress = useAppSelector(cleaningSelectors.selectAddress);
    const selectedArea = useAppSelector(cleaningSelectors.selectArea);
    const selectedServiceId = useAppSelector(cleaningSelectors.selectServiceId);
    const selectedIsRecurringService = useAppSelector(cleaningSelectors.selectIsRecurringService);
    const selectedFrequencyId = useAppSelector(cleaningSelectors.selectFrequencyId);
    const selectedPaymentIntervalId = useAppSelector(cleaningSelectors.selectPaymentIntervalId);
    const selectedAvailability = useAppSelector(cleaningSelectors.selectAvailability);
    const authenticated = useAppSelector(authSelectors.selectAuthenticated);
    const canPay = useAppSelector(state => state.cleaning.canPay);
    const selectedExtraServices = useAppSelector(cleaningSelectors.selectExtraServices);
    const verifiedCode = useAppSelector(cleaningSelectors.selectVerifiedCode);

    const [acceptedTOS, setAcceptedTOS] = React.useState(false);

    const {
        data: addressResult
    } = housekeeprApi.useRegisterAddressQuery(selectedAddress && isOnThisPage
        ? {
            darId: selectedAddress.adresse.id
        }
        : skipToken
    );

    const area = selectedArea ?? addressResult?.area;

    const {
        data: serviceResult
    } = housekeeprApi.useGetServiceQuery(selectedServiceId && isOnThisPage
        ? selectedServiceId
        : skipToken
    );

    const {
        data: paymentMethodAvailabilityResult
    } = housekeeprApi.useGetPaymentAvailabilityQuery(authenticated && isOnThisPage
        ? undefined
        : skipToken
    );

    const {
        data: paymentIntervalsResult
    } = housekeeprApi.useGetPaymentIntervalsQuery(selectedPaymentIntervalId !== undefined && isOnThisPage
        ? undefined
        : skipToken
    );

    const paymentInterval = paymentIntervalsResult?.find(paymentInterval => paymentInterval.id == selectedPaymentIntervalId);
    const showPaymentInterval = paymentIntervalsResult != null && paymentIntervalsResult.length > 1;

    const frequency = serviceResult?.frequencies.find(frequency => frequency.id == selectedFrequencyId);

    const useCode = !!verifiedCode;

    const {data: codeResult} = housekeeprApi.useCheckCodeQuery(
        useCode && selectedServiceId && selectedFrequencyId ? {
            code: verifiedCode,
            service_id: selectedServiceId,
            frequency_id: selectedFrequencyId,
        } : skipToken
    );

    const canSearchPrice = !!selectedServiceId && !!addressResult && !!frequency;

    const priceRequest = canSearchPrice ? {
        service_id: selectedServiceId,
        address_id: addressResult.id,
        area: selectedArea
    } as CleaningServicePriceRequest : undefined;

    const {
        data: priceResult,
    } = housekeeprApi.useGetServicePriceQuery(priceRequest ?? skipToken);

    useEffect(() => {
        if (!canPay && paymentMethodAvailabilityResult?.available) {
            dispatch(cleaningActions.setCanPay(true));
            if (!selectedPaymentType) {
                dispatch(cleaningActions.setPaymentType('card'));
            }
        }
    }, [authenticated, canPay, dispatch, paymentMethodAvailabilityResult?.available, selectedPaymentType]);


    const {
        data: extraServicesResult,
        isFetching: extraServicesLoading
    } = housekeeprApi.useGetExtraServicesQuery(selectedServiceId ?? skipToken);

    const availableExtraServices = extraServicesResult?.filter(extraService => extraService.is_orderable);

    const extraServicePrice = selectedExtraServices !== undefined && selectedExtraServices.length >= 1 && availableExtraServices
        ? selectedExtraServices
            .map(orderExtraService => {
                const extraService = availableExtraServices.find(extraService => extraService.id == orderExtraService.id);
                const extraServicePrice = extraService?.price ?? 0;
                return extraServicePrice * orderExtraService.count * (1 / Math.max(1, orderExtraService.how_often));
            })
            .reduce((sum, price) => sum + price, 0)
        : 0;

    const servicePrice = priceResult;
    const craftsmanDeductionMultiplier = serviceResult ? (1 - serviceResult.craftsman_deduction / 100) : 1;
    const paymentIntervalDiscountMultiplier = paymentInterval ? (1 - paymentInterval.discount / 100) : 1;
    const codeDiscount = useCode && servicePrice ? servicePrice * ((codeResult?.discount_code?.percent_off ?? 0) / 100) + (codeResult?.discount_code?.value ?? 0) : 0;

    const averageFullPrice =
        (servicePrice ?? 0) * VAT
        + extraServicePrice * VAT
        - codeDiscount * VAT;

    const averagePrice =
        averageFullPrice
        * paymentIntervalDiscountMultiplier
        * craftsmanDeductionMultiplier;

    const formattedAverageFullPrice = averageFullPrice.toLocaleString('da-DK', {
        style: 'currency',
        currency: 'DKK'
    });

    const formattedAveragePrice = averagePrice.toLocaleString('da-DK', {
        style: 'currency',
        currency: 'DKK'
    });

    const today = new Date();
    const firstVisitDate = selectedAvailability ? new Date(selectedAvailability.date) : new Date();
    let paymentPeriodStart = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
    let paymentPeriodEnd = new Date(paymentPeriodStart.getFullYear(), paymentPeriodStart.getMonth() + (paymentInterval?.months ?? 1), 0);
    const weekMillis = 7 * 24 * 60 * 60 * 1000;

    while (selectedAvailability && paymentPeriodEnd.getTime() < firstVisitDate.getTime()) {
        paymentPeriodStart = new Date(paymentPeriodEnd.getFullYear(), paymentPeriodEnd.getMonth() + 1, 1);
        paymentPeriodEnd = new Date(paymentPeriodStart.getFullYear(), paymentPeriodStart.getMonth() + (paymentInterval?.months ?? 1), 0);
    }

    const visits = selectedIsRecurringService ? Math.floor((paymentPeriodEnd.getTime() - firstVisitDate.getTime()) / (weekMillis * (frequency?.every ?? 1))) + 1 : 1;
    const visitsPrice = visits * (servicePrice ?? 0);
    const visitsDiscountAmount = codeDiscount * visits;

    const extraServicesPrice = availableExtraServices
        ? selectedExtraServices
            .map(extraServiceRequest => {
                const extraService = availableExtraServices.find(service => service.id === extraServiceRequest.id);
                const extraServiceVisits = Math.floor(visits / extraServiceRequest.how_often);
                return extraServiceVisits * (extraService?.price ?? 0);
            })
            .reduce((sum, price) => sum + price, 0)
        : 0;

    const totalPaymentWithoutPaymentIntervalDiscount = (visitsPrice * VAT
        - visitsDiscountAmount * VAT
        + extraServicesPrice * VAT);

    const totalPayment = totalPaymentWithoutPaymentIntervalDiscount * paymentIntervalDiscountMultiplier;

    const formattedTotalPayment = totalPayment.toLocaleString('da-DK', {
        style: 'currency',
        currency: 'DKK'
    });

    const totalPaymentSaving =
        visitsDiscountAmount * VAT + (1 - paymentIntervalDiscountMultiplier) * totalPaymentWithoutPaymentIntervalDiscount;

    const formattedTotalPaymentSaving = totalPaymentSaving.toLocaleString('da-DK', {
        style: 'currency',
        currency: 'DKK'
    });

    return <div
        className={props.hidden ? orderStyles.hidden : orderStyles.cardWrapper}
    >
        <div
            className={orderStyles.card}
        >
            <div
                className={orderStyles.orderSection}
            >
                <h1 className={orderStyles.cardH1}>
                    Bestillingsoversigt
                </h1>
                <p className={`${orderStyles.orderSectionContent} ${orderStyles.removeTopMargin}`}>
                    Tjek at alt er som det skal være. Hvis ikke, kan du klikke på et punkt for at redigere.
                </p>
                <div
                    className={`${orderStyles.orderSectionContent} ${orderStyles.flexRow} ${orderStyles.flexWrap} ${orderStyles.flexCrossStretch}`}>
                    <div
                        className={`${orderStyles.overviewCard} cursor-pointer`}
                        onClick={() => {
                            dispatch(cleaningActions.setPage(CLEANING_STEP_HOME));
                            document.querySelector('#address-content')?.scrollIntoView({
                                behavior: 'smooth',
                                block: 'center'
                            });
                        }}
                    >
                        <h2>Adresse</h2>
                        <p>
                            {selectedAddress?.tekst ?? 'Ikke valgt endnu'}
                        </p>
                    </div>
                    <div
                        className={`${orderStyles.overviewCard} cursor-pointer`}
                        onClick={() => {
                            dispatch(cleaningActions.setPage(CLEANING_STEP_HOME));
                            document.querySelector('#area-content')?.scrollIntoView({
                                behavior: 'smooth',
                                block: 'center'
                            });
                        }}
                    >
                        <h2>Areal</h2>
                        <p>
                            {area ? `${area} m²` : 'Indlæser...'}
                        </p>
                    </div>
                    <div
                        className={`${orderStyles.overviewCard} cursor-pointer`}
                        onClick={() => {
                            dispatch(cleaningActions.setPage(CLEANING_STEP_PROGRAMME));
                            document.querySelector('#service-content')?.scrollIntoView({
                                behavior: 'smooth',
                                block: 'center'
                            });
                        }}
                    >
                        <h2>Rengøringstype</h2>
                        <p>
                            {serviceResult?.name ?? (selectedServiceId ? `Service #${selectedServiceId}` : 'Ikke valgt endnu')}
                        </p>
                    </div>
                    <div
                        className={`${orderStyles.overviewCard} cursor-pointer`}
                        onClick={() => {
                            dispatch(cleaningActions.setPage(CLEANING_STEP_PROGRAMME));
                            document.querySelector('#frequency-content')?.scrollIntoView({
                                behavior: 'smooth',
                                block: 'center'
                            });
                        }}
                    >
                        <h2>Hvor ofte?</h2>
                        <p>
                            {frequency?.nicified_string ?? 'Ikke valgt endnu'}
                        </p>
                    </div>
                    {showPaymentInterval && paymentInterval &&
                        <div
                            className={`${orderStyles.overviewCard} cursor-pointer`}
                            onClick={() => {
                                dispatch(cleaningActions.setPage(CLEANING_STEP_PROGRAMME));
                                document.querySelector('#payment-interval-content')?.scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'center'
                                });
                            }}
                        >
                            <h2>Betalingsinterval</h2>
                            <p>
                                {paymentIntervalDisplayString(paymentInterval)}
                            </p>
                        </div>
                    }
                    <div
                        className={`${orderStyles.overviewCard} cursor-pointer`}
                        onClick={() => {
                            dispatch(cleaningActions.setPage(CLEANING_STEP_TIME));
                            document.querySelector('#availability-content')?.scrollIntoView({
                                behavior: 'smooth',
                                block: 'center'
                            });
                        }}
                    >
                        <h2>
                            {frequency?.every !== 0
                                ? 'Tidsrum for ankomst ved første besøg'
                                : 'Tidsrum for ankomst'
                            }
                        </h2>
                        <p>
                            {selectedAvailability
                                ? `${capitalize(new Date(selectedAvailability.date).toLocaleDateString('da-DK', {dateStyle: 'medium'}))}, ${selectedAvailability.time_slot_range}`
                                : 'Ikke valgt endnu'
                            }
                        </p>
                    </div>
                </div>
            </div>
            <div
                className={orderStyles.orderSection}
            >
                <h1 className={orderStyles.cardH1}>
                    Kampagner & Rabatkoder
                </h1>
                <p className={`${orderStyles.orderSectionContent} ${orderStyles.removeTopMargin}`}>
                    Hvis du har en kampagnekode, referralkode eller rabatkode, kan du indtaste den her.
                    Bemærk at referralkoder kun kan benyttes på abonnementer, og rabatkoder ikke gælder for
                    ekstraydelser.
                </p>
                <div
                    style={{marginTop: -12}}
                >
                    <CleaningRedeemCode/>
                </div>
            </div>
            {serviceResult && frequency && servicePrice &&
                <div className={orderStyles.orderSection}>
                    <h1 className={orderStyles.cardH1}>
                        Prisoversigt
                    </h1>
                    <div className={`${orderStyles.orderSectionContent} ${orderStyles.removeTopMargin}`}>
                        {frequency.every != 0 && paymentInterval
                            ? <>
                                <p>
                                    Din pris per besøg efter rabat og forventet servicefradrag er i
                                    gennemsnit {formattedAveragePrice}
                                </p>
                                {showPaymentInterval &&
                                    <>
                                        <p>
                                            Samlet pris for
                                            valgte {paymentInterval.months} {paymentInterval.months > 1 ? 'måneders' : 'måneds'} forudbetaling
                                            ({visits} besøg) er {formattedTotalPayment}
                                        </p>
                                        {totalPaymentSaving > 0 &&
                                            <p>
                                                Samlet besparelse for
                                                valgte {paymentInterval.months} {paymentInterval.months > 1 ? 'måneders' : 'måneds'} forudbetaling
                                                er {formattedTotalPaymentSaving}
                                            </p>
                                        }
                                    </>
                                }
                            </>
                            : <p>
                                Din pris er {formattedAverageFullPrice} efter rabat.
                            </p>

                        }
                        <p>
                            Du kan desuden med fordel benytte dig af servicefradraget på op til 11.900 kr.
                            fradrag pr. person. <a
                            className={orderStyles.hyperlink}
                            target={'_blank'}
                            href={'https://skat.dk/borger/fradrag/servicefradrag/servicefradrag'}
                        >
                            (Læs mere på skat.dk)
                        </a>
                        </p>
                        <a
                            className={orderStyles.hyperlink}
                            rel="noopener noreferrer"
                            onClick={() => dispatch(cleaningActions.togglePriceExpanded())}
                        >
                            Vis detaljeret prisoversigt
                        </a>
                    </div>
                </div>
            }
            <div
                className={orderStyles.orderSection}
            >
                <h1 className={orderStyles.cardH1}>
                    Betaling
                </h1>
                {(frequency?.every ?? 0) == 0
                    ? <p className={`${orderStyles.orderSectionContent} ${orderStyles.removeTopMargin}`}>
                        Engangsbesøg trækkes automatisk efter endt udførsel.
                    </p>
                    : <p className={`${orderStyles.orderSectionContent} ${orderStyles.removeTopMargin}`}>
                        Første abonnementsbetaling sker umiddelbart efter bestilling og dækker dit forventede forbrug i
                        første betalingsperiode, som går fra starten af indeværende måned
                        og {paymentInterval?.months ?? '1, 3 eller 6'} måned{paymentInterval?.months == 1 ? '' : 'er'} frem.
                        Fremtidige betalinger sker d. 21. i sidste måned af betalingsperioden, og dækker dit
                        forventede forbrug i den næste betalingsperiode.
                    </p>
                }
                <div className={`${orderStyles.orderSectionContent} ${orderStyles.flexRow} ${orderStyles.flexWrap}`}>
                    {paymentMethodAvailabilityResult?.available ?
                        <>
                            <p>
                                Du har allerede en tilknyttet betalingsmetode, der vil blive brugt. Du kan til enhver
                                tid ændre din betalingsmetode i app&apos;en.
                            </p>
                            <div
                                className={`${orderStyles.flexRow} px-2`}
                            >
                                <CheckBoxCircle
                                    id="acceptedTOS"
                                    onToggle={setAcceptedTOS}
                                    data-cy={'accept-tos'}
                                    $initialCheck={acceptedTOS}
                                />
                                <Typography
                                    variant={'body'}
                                >
                                    Jeg har læst og accepterer Housekeeprs&nbsp;
                                    <a href={routes.privacyPolicy.href}
                                       rel="noopener noreferrer" target="_blank"
                                       className={orderStyles.hyperlink}>handelsbetingelser
                                    </a>
                                    &nbsp;og&nbsp;
                                    <a
                                        rel="noopener noreferrer"
                                        href={routes.bussPolicy.href}
                                        target="_blank"
                                        className={orderStyles.hyperlink}>privatlivspolitik
                                    </a>
                                </Typography>
                            </div>
                        </>
                        : <>
                            <div
                                className={`${orderStyles.cardPaymentOption} ${selectedPaymentType == 'card' ? orderStyles.selected : ''}`}
                                onClick={() => {
                                    dispatch(cleaningActions.setPaymentType('card'));
                                }}
                            >
                                <img
                                    className={'mx-2'}
                                    src={PaymentCardOptions.src}
                                    alt={'Betal med kort'}
                                />
                            </div>
                            <div
                                className={`${orderStyles.flexRow} px-2`}
                            >
                                <CheckBoxCircle
                                    id="acceptedTOS"
                                    onToggle={setAcceptedTOS}
                                    data-cy={'accept-tos'}
                                    $initialCheck={acceptedTOS}
                                />
                                <Typography
                                    variant={'body'}
                                >
                                    Jeg har læst og accepterer Housekeeprs&nbsp;
                                    <a href={routes.privacyPolicy.href}
                                       rel="noopener noreferrer" target="_blank"
                                       className={orderStyles.hyperlink}>handelsbetingelser
                                    </a>
                                    &nbsp;og&nbsp;
                                    <a
                                        href={routes.bussPolicy.href}
                                        target="_blank"
                                        className={orderStyles.hyperlink}>privatlivspolitik
                                    </a>
                                </Typography>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
        <div className={`${orderStyles.flexRow} ${orderStyles.flexMainSpaceBetween} self-stretch`}>
            <div
                className={`${orderStyles.buttonWrapper} ${orderStyles.outline} w-[200px]`}>
                <button
                    onClick={() => {
                        dispatch(cleaningActions.previousPage());
                        smoothScrollToTop();
                    }}
                >
                    Tilbage
                </button>
            </div>
            <div
                className={`${orderStyles.buttonWrapper} ${(!canGoToNext || !acceptedTOS) ? orderStyles.disabled : ''} w-[200px]`}>
                <button
                    onClick={() => {
                        createEventV2('begin_checkout');
                        dispatch(cleaningActions.setPage(CLEANING_STEP_SUBMITTING_ORDER));
                        smoothScrollToTop();
                    }}
                    disabled={!canGoToNext || !acceptedTOS}
                >
                    Bestil
                </button>
            </div>
        </div>
    </div>;
};

export default CleaningPaymentCard;
