import useMediaQuery from '@crm/hooks/use-media-query/use-media-query';
import {routes} from '@crm/routes';
import React from 'react';
import styled, {useTheme} from 'styled-components';
import useMenu from '../../../../hooks/use-menu';
import NavLink from '../../../navlink/navlink';

interface Props {
    style?: React.CSSProperties;
}

const MenuItemContainer = styled.div`
    width: 50%;
    padding-bottom: 10px;

    ${({theme}) => theme.breakpoints.down('mobile')} {
        width: 100%;
    }
`;

const TypographyMenuItem = styled(NavLink)`
    font-family: ${({theme}) => theme.typography.primary.fontFamily};
    color: ${({theme}) => theme.palette.common.darkBlue};
    font-size: 24px;
`;

const BecomeHousekeeprMenuItem = styled.a`
    font-family: ${({theme}) => theme.typography.primary.fontFamily};
    color: ${({theme}) => theme.palette.common.darkBlue};
    font-size: 24px;

    &:hover {
        color: ${({theme}) => theme.palette.common.white};
    }
`;

export const MenuLinks: React.FC<Props> = (props: Props) => {
    const {style} = props;
    const theme = useTheme();
    const mobileDevice = useMediaQuery(theme.breakpoints.down('mobile'));
    const {handleClose} = useMenu();

    return <div className="flex flex-wrap w-full" style={style}>
        <div className="w-full tabletM:w-2/3 tablet:w-1/2 flex flex-wrap">
            {Object.values(routes).filter(route => route.showInNavigation).map(
                (menuItem: { title?: string; href: string; hideForMobile?: boolean }) => {
                    if (!(mobileDevice && menuItem?.hideForMobile)) {
                        return <MenuItemContainer key={menuItem.href}>
                            <TypographyMenuItem onClick={handleClose} href={menuItem.href}>
                                {menuItem.title}
                            </TypographyMenuItem>
                        </MenuItemContainer>;
                    }
                }
            )}
        </div>
        <div className="mobile:hidden">
            <BecomeHousekeeprMenuItem onClick={handleClose} href={routes.chooseServiceAddress.href}>
                Bestil & Beregn
            </BecomeHousekeeprMenuItem>
        </div>
    </div>;
};
