// Icons
import CleaningBucket from '../public/icons/cleaning-bucket.webp';
import CleaningCloth from '../public/icons/cleaning-cloth.webp';
import CoffeeCapsules from '../public/icons/coffee-capsules.webp';
import Dishwasher from '../public/icons/dishwasher.webp';
import DishwashingLiquid from '../public/icons/dishwashing-liquid.webp';
import Handsoap from '../public/icons/handsoap.webp';
import PaperTowel from '../public/icons/paper-towel.webp';
import Sponge from '../public/icons/sponge.webp';
import ToiletBrush from '../public/icons/toilet-brush.webp';
import Toiletpaper from '../public/icons/toiletpaper.webp';
import Toothbrushes from '../public/icons/toothbrushes.webp';
import Toothpaste from '../public/icons/toothpaste.webp';
import VentedBrush from '../public/icons/vented-brush.webp';
import abonnementsprodukter from '../public/images/abonnementsprodukter.webp';
import Arrow from '../public/images/arrow.svg';
import BurgerMenu from '../public/images/burger-menu.svg';
import Close from '../public/images/close.svg';
import Mobile from '../public/images/mobile.svg';
import Mobilepay from '../public/images/mobilepay.svg';
import MobilepayBlue from '../public/images/mobilepay-blue-logo.webp';
import VisaMastercard from '../public/images/visa-mastercard.svg';
import VisaDankort from '../public/images/cart-visa-dk.svg';
import VisaMastercardPensopay from '../public/images/V-MC-penso.webp';
import PaymentCardOptions from '../public/images/payment-options.webp';
import Bed from '../public/icons/bed.svg';
import Ironing from '../public/icons/ironing.svg';
import Shirt from '../public/icons/shirt.svg';
import Couch from '../public/icons/couch.svg';
import Bin from '../public/icons/bin.svg';
import Shower from '../public/icons/shower.svg';
import Bottles from '../public/icons/bottles.svg';
import Frigde from '../public/icons/fridge.svg';
import Oven from '../public/icons/oven.svg';
import ExtractorHood from '../public/icons/extractor-hood.svg';
import WateringPot from '../public/icons/watering-pot.svg';
import AppleBadge from '../public/images/app-store-badge-DK-apple.svg';
import GoogleBadge from '../public/images/google-play-badge-DK.svg';

// Essentials-sections images
import EssentialProducts from '../public/images/sections/essentials/essential-products.webp';
// Get started images
import BilIMiljø from '../public/images/sections/get-started/car-in-environment.webp';
import KvindeGørRent from '../public/images/sections/get-started/lady-cleaning.webp';
import MobilIHånd from '../public/images/sections/get-started/phone-in-hand.webp';
import Keys from '../public/images/sections/get-started/keys.webp';
import MobileApp from '../public/images/sections/get-started/mobile-app.webp';
import WebApp from '../public/images/sections/get-started/web-app.webp';
// Mobile-section images
import LeftPhone from '../public/images/sections/mobile-section/left-phone.webp';
import RightPhone from '../public/images/sections/mobile-section/right-phone.webp';
import WomanCleaning from '../public/images/sections/mobile-section/woman-cleaning.webp';
import GræsHækHave from '../public/images/sections/services/græs-hæk-have.webp';
import MoreServices from '../public/images/sections/services/more-services.webp';
// Services images
import Carwash from '../public/images/services/carwash.webp';
// Services page images
import Cleaning from '../public/images/services/cleaning.webp';
import GrassMan from '../public/images/services/grass.webp';
import HandymanServicesPage from '../public/images/services/handyman.webp';
import WindowCleaning from '../public/images/services/window-cleaning.webp';
import WindowCleaning2 from '../public/images/services/window-cleaning-2.webp';
import Laundry from '../public/images/services/laundry.webp';
import CarpetCleaning from '../public/images/services/carpet-cleaning.webp';
// Environment And Quality page images
import CleaningMan from '../public/images/miljoe-og-kvalitet/cleaning-man.webp';
import FlowerPot from '../public/images/miljoe-og-kvalitet/flower-pot.webp';
import ShowerCleaning from '../public/images/miljoe-og-kvalitet/shower-cleaning.webp';
import Tent from '../public/images/miljoe-og-kvalitet/tent.webp';
import CarFromFront from '../public/images/miljoe-og-kvalitet/car-front.webp';
import PineForest from '../public/images/miljoe-og-kvalitet/pines.webp';
import WomanCleaningKitchenLarger from '../public/images/miljoe-og-kvalitet/woman-cleaning-kitchen-larger.webp';

// Cleaning page images
import DkMap from '../public/images/dk-map-cleaning.svg';
// table section images
import Entrance from '../public/images/sections/table/entrance.webp';
import Kitchen from '../public/images/sections/table/kitchen.webp';
import LivingRoom from '../public/images/sections/table/living-room.webp';
import OvenPng from '../public/images/sections/table/oven.webp';
import Rooms from '../public/images/sections/table/rooms.webp';
import ShowerHead from '../public/images/sections/table/shower.webp';
// Reasons why
import ExtraStartup from '../public/images/sections/reasons-why/extra-startup.webp';
import VacuumLivingRoom from '../public/images/sections/reasons-why/vacuum-living-room.webp';
import LaughingPeople from '../public/images/sections/reasons-why/laughing-people.webp';
import MobileAppTwo from '../public/images/sections/reasons-why/mobile-app.webp';
import MobileAppTwoZoomedIn from '../public/images/sections/reasons-why/mobile-app-zoomed-in.webp';
// Become Housekeepr
import ClappingHands from '../public/images/become-housekeepr/clapping-hands.webp';
import RandomFaces from '../public/images/become-housekeepr/random-people.webp';
import CityMap from '../public/images/become-housekeepr/city-map.webp';
import PeopleAndPhone from '../public/images/become-housekeepr/people-phone.webp';
import AppCalendar from '../public/images/become-housekeepr/housekeepr-app-calendar.webp';
import WashingMachine from '../public/images/become-housekeepr/washing-machine.webp';
import Mobile1 from '../public/images/become-housekeepr/phones/mobile1.webp';
import Mobile2 from '../public/images/become-housekeepr/phones/mobile2.webp';
import Mobile3 from '../public/images/become-housekeepr/phones/mobile3.webp';
import Mobile4 from '../public/images/become-housekeepr/phones/mobile4.webp';
import Mobile5 from '../public/images/become-housekeepr/phones/mobile5.webp';
import Mobile6 from '../public/images/become-housekeepr/phones/mobile6.webp';

import RoundedRightArrow from '../public/icons/rounded-right-arrow.svg';
import RoundedLeftArrow from '../public/icons/rounded-left-arrow.svg';

export {
    RoundedRightArrow,
    RoundedLeftArrow,
    Bed,
    Ironing,
    Shirt,
    Couch,
    Bin,
    Shower,
    Bottles,
    Frigde,
    Oven,
    ExtractorHood,
    WateringPot,
    Mobile,
    BurgerMenu,
    Close,
    Arrow,
    CleaningBucket,
    CleaningCloth,
    CoffeeCapsules,
    Dishwasher,
    DishwashingLiquid,
    Handsoap,
    PaperTowel,
    Sponge,
    ToiletBrush,
    Toiletpaper,
    Toothbrushes,
    Toothpaste,
    VentedBrush,
    Mobilepay,
    MobilepayBlue,
    AppleBadge,
    GoogleBadge,
    VisaMastercard,
    VisaDankort,
    VisaMastercardPensopay,
    PaymentCardOptions
};
export const images = {
    abonnementsprodukter,
    Services: {
        GræsHækHave,
        MoreServices,
        alt: {
            GræsHækHave: 'Buske foran terrasse med en hvid stol.',
            MoreService: 'Pil der pejer til højre.'
        }
    },
    GetStarted: {
        BilIMiljø,
        KvindeGørRent,
        MobilIHånd,
        Keys,
        MobileApp,
        WebApp,
        alt: {
            BilIMiljø: 'Vi optimiserer vores kørsel for at skåne miljøet.',
            KvindeGørRent: 'Vi gør rent med miljøvenlige rengøringsmidler.',
            MobilIHånd: 'Skræddersyet rengøring i Housekeepr appen.',
            Keys: 'Nøgler i Housekeeprs varetægt foran nøgleboks der er godkendt af forsikring.',
            MobileApp:
                'Onboarding flow i Housekeepr mobil app viser redigering af vinduespudser service.',
            WebApp: 'Bestil din næste service på Housekeepr webshop.'
        }
    },
    MobileSection: {
        LeftPhone,
        RightPhone,
        WomanCleaning,
        alt: {
            LeftPhone:
                'Housekeepr kunde app under Overblik - Viser notifikationer, kommende rengøringer i kalenderen og tilvalgte Ekstra service.',
            RightPhone:
                'Housekeepr kunde app under Services - Viser Nyheder og udbydet af forskellige service.',
            WomanCleaning: 'Housekeepr udfører rengøring i et køkken.'
        }
    },
    EssentialsSection: {
        EssentialProducts,
        alt: {
            EssentialProducts:
                'Housekeepr kasse med essentielle ting du har behov for i hjemmet, der kan købes via abonnement.'
        }
    },
    ServicesPage: {
        Cleaning,
        WindowCleaning,
        WindowCleaning2,
        HandymanServicesPage,
        GrassMan,
        Carwash,
        Laundry,
        CarpetCleaning,
        alt: {
            Cleaning: 'Housekeepr udfører professionel rengøring med fokus på kvalitet.',
            WindowCleaning: 'Housekeepr specialist ved arbejde med vinduespudsning.',
            WindowCleaning2: 'Detalje af Housekeeprs vinduespudsningstjeneste.',
            HandymanServicesPage: 'Housekeeprs håndværker reparerer en skydedør, der illustrerer alsidighed.',
            GrassMan: 'Housekeepr gartner ordner haven, sikrer velplejede græsarealer.',
            Carwash: 'Housekeepr vasker bil, demonstrerer omfattende rengøringsservice udendørs.',
            Laundry: 'Housekeepr håndterer vasketøj, viser tjenesten for tøjvask.',
            CarpetCleaning: 'Efter rengøring af tæppe, fremhæver Housekeeprs dybtgående rengøringsevner.',
        }
    },
    EnvironmentAndQualityPage: {
        CleaningMan,
        FlowerPot,
        ShowerCleaning,
        Tent,
        CarFromFront,
        PineForest,
        WomanCleaningKitchenLarger,
        alt: {
            CleaningMan: 'Professionel og bæredygtig rengøringshjælp.',
            FlowerPot: 'Professionel gardner.',
            ShowerCleaning: 'Housekeepers VVS mand inspicerer, vedligeholder og reparerer bruser.',
            Tent: '',
            CarFromFront: 'Housekeeprs bil klar til at levere effektiv og miljøvenlig rengøring.',
            PineForest: 'Bæredygtig skov, der symboliserer brugen af svanemærkede rengøringsmidler.',
            WomanCleaningKitchenLarger: 'Professionel Housekeepr udfører miljøvenlig rengøring i køkken.',
        }
    },
    CleaningPage: {
        DkMap
    },
    TableSection: {
        Entrance,
        Kitchen,
        LivingRoom,
        OvenPng,
        Rooms,
        ShowerHead,
        alt: {
            Entrance: 'Grundig rengøring af indgang og entré udført af Housekeepr.',
            Kitchen: 'Detaljeret rengøring af køkken inklusive overflader og apparater.',
            LivingRoom: 'Hyggelig stue efter professionel rengøring, inklusiv støvfri møbler.',
            OvenPng: 'Dybdegående rengøring af ovn for at fjerne fedt og snavs.',
            Rooms: 'Rengjorte og velordnede værelser med frisk luft og ren atmosfære.',
            ShowerHead: 'Skinnende rent badeværelse med afkalket bruseniche og armaturer.',
        }
    },
    ReasonsWhy: {
        ExtraStartup,
        VacuumLivingRoom,
        LaughingPeople,
        MobileAppTwo,
        MobileAppTwoZoomedIn,
        alt: {
            ExtraStartup: 'Start din rengøring med en gratis ekstra time hos Housekeepr.',
            VacuumLivingRoom: 'Personlig rengøringsassistent fra Housekeepr støvsuger stue.',
            LaughingPeople: 'Glade Housekeepr medarbejdere deler et smil mens de bruger appen.',
            MobileAppTwo: 'Tilpas din rengøringsservice nemt med Housekeeprs mobilapp.',
            MobileAppTwoZoomedIn: 'Detaljeret visning af Housekeeprs app med teamoversigt.',
        }
    },
    BecomeHousekeepr: {
        ClappingHands,
        RandomFaces,
        CityMap,
        PeopleAndPhone,
        AppCalendar,
        WashingMachine,
        Phone: {
            Mobile1,
            Mobile2,
            Mobile3,
            Mobile4,
            Mobile5,
            Mobile6
        },
        alt: {
            ClappingHands: 'Housekeepr serviceleder klapper i hænderne for at motivere teamet.',
            RandomFaces: 'Portrætter af glade Housekeepr medarbejdere klar til at yde service.',
            CityMap: 'Kort over serviceområder, hvor Housekeepr tilbyder rengøring.',
            PeopleAndPhone: 'Housekeepr teamet engagerer sig i kundeservice via appen.',
            AppCalendar: 'Housekeepr appens kalenderfunktion, der viser planlagte rengøringsopgaver.',
            WashingMachine: 'Vaskemaskine brugt af Housekeepr til at sikre rene mopper og klude.',
            Phone: {
                Mobile1: 'Housekeepr app viser en arbejdskalender for nem planlægning.',
                Mobile2: 'Housekeepr app med optimeret kørselsrute for effektiv daglig planlægning.',
                Mobile3: 'Housekeepr app med oversigt over relevant information for medarbejdere.',
                Mobile4: 'Enkel to-do liste i Housekeepr app for klare arbejdsinstrukser.',
                Mobile5: 'Housekeepr app med oversigt over kommunikation mellem team og kunder.',
                Mobile6: 'Housekeepr app viser live-data relevant for medarbejderens daglige opgaver.',
            }
        }
    }
};
