import React from 'react';
import Footer from './footer/footer';
import Navbar from './navbar/navbar';
import Head from 'next/head';

const Layout: React.FC<{ children: React.ReactNode | React.ReactNode[] }> = (props: {
    children: React.ReactNode | React.ReactNode[]
}) => {
    const {children} = props;
    return <>
        <Head>
            <link rel="icon" href="/logo/Housekeepr-logo.webp"/>
        </Head>
        <Navbar/>
        <main>{children}</main>
        <Footer/>
    </>;
};

export default Layout;
