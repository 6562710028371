import {SearchPeriod} from "@crm/api/models/common/housekeepr-api";

export enum NewsletterSubscriptionAbout {
    CLEANING = 'cleaning',
    WINDOW_CLEANING = 'window-cleaning',
    PRODUCTS = 'products',
    CARPET_CLEANING = 'carpet-cleaning',
    LAUNDRY = 'laundry',
    GARDEN = 'garden',
    HANDYMAN = 'handyman',
    CAR_WASH = 'car-wash',
    HOUSEKEEPR = 'housekeepr',
    SUBCONTRACTOR = 'subcontractor',
    CALL_ME = 'call_me',
}

export interface NewsletterSubscriptionRequest {
    name: string;
    email: string;
    phone?: number;
    postal_code: number;
    about: NewsletterSubscriptionAbout;
    gclid?: string;
}

export interface RegisterUserRequest {
    name: string,
    email: string,
    phone: string,
    password: string,
    password_confirmation: string,
    invoice_address_id: number,
    accepted_marketing: boolean,
    gclid?: string,
}

export interface LoginRequest {
    email: string,
    password: string,
    origin?: string,
}

export interface CheckCodeRequest {
    code: string;
    service_id: number;
    frequency_id: number;
}

export interface WindowTypeRequest {
    id: number;
    count: number;
}

export interface ExtraServiceRequest {
    id: number;
    count: number;
    how_often: number;
    note?: string;
}

export interface AvailabilityRequest {
    service_id: number;
    address_id: number;
    frequency_id: number;
    specified_area?: number;
    has_secondary_glazing?: boolean;
    residence_type?: string;
    extra_services?: ExtraServiceRequest[];
    windows?: WindowTypeRequest[];
}

export type AvailabilityRequestForChunking = AvailabilityRequest & {
    search_period: SearchPeriod,
    search_chunks: number
};

export type PromoCodeRequest = ({
        discount_code?: string | null;
        referral_code?: never;
        campaign_code?: never;
    }
    | { discount_code?: never; referral_code?: string | null; campaign_code?: never; }
    | { discount_code?: never; referral_code?: never; campaign_code?: string | null; });

export type CleaningOrderRequest = CleaningOrderBaseRequest & PromoCodeRequest;

type CleaningOrderBaseRequest = {
    service_id: number;
    location_id: number;
    frequency_id: number;
    time_slot_id: number;
    schedule_id: number;
    payment_interval_id: number;
    extra_services: ExtraServiceRequest[];
    specified_area?: number | null;
}

export type WindowCleaningOrderBaseRequest = {
    service_id: number;

    location_id: number;
    residence_type?: string;
    has_secondary_glazing?: boolean;

    windows: WindowTypeRequest[];

    frequency_id: number;
    time_slot_id: number;
    schedule_id: number;

    extra_information?: string;
}

export type WindowCleaningOrderRequest = WindowCleaningOrderBaseRequest & PromoCodeRequest;

export interface CleaningServicePriceRequest {
    address_id: number;
    area?: number;
    service_id: number;
}

export interface WindowCleaningServicePriceRequest {
    service_id: number;
    address_id: number;
    residence_type: string;
    has_secondary_glazing: boolean;
    windows: WindowTypeRequest[];
}

export interface StoreLocationRequest {
    dawaId: string,
    area?: number
}

export interface ServicesRequest {
    serviceTypeId: number;
    parish: number;
}
