import React, {useCallback, useState} from 'react';
import styled from "styled-components";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown} from '@fortawesome/free-solid-svg-icons';
import OrderDropdownElement from "../order-dropdown-element";

export interface DropdownOption {
    text: string;
    value: any;
}

interface OrderDropdownProps {
    $transition_time: number;
    options: DropdownOption[];
    disabled?: boolean;
    defaultOptionIndex?: number;
    className?: string;
    $zIndexOffset?: number;
    'data-cy'?: string;

    onSelect(option: DropdownOption): any;
}

interface DropdownProps {
    $expand?: boolean;
    $transition_time?: number;
    dropdownHeight?: number;
    $zIndexOffset?: number;
}

interface DropdownContainerProps {
    $expanded?: boolean;
    $expanding?: boolean;
    $zIndexOffset?: number;
}

const ChevronIcon = styled(FontAwesomeIcon)<DropdownProps>`
    float: right;
    margin-right: 0.5rem;
    width: 20px;
    transform: ${props => props.$expand
            ? `scaleY(-1)`
            : `scaleY(1)`
    };
    transition: transform ${props => props.$transition_time}ms;
`

const DropdownWrapper = styled.div<{
    $expanded: boolean,
    $zIndexOffset: number
}>`
    width: 100%;
    position: relative;
    z-index: ${props => props.$zIndexOffset + 1};
`

const Dropdown = styled.ul<DropdownProps>`
    width: 100%;
    background: ${({theme}) => theme.palette.common.lightTurquoise};
    padding: 2.5rem 0.5rem 0.5rem 0.5rem;
    position: relative;
    transform: translate(0, ${props => props.$expand || !props.dropdownHeight ? `0` : -props.dropdownHeight + `px`});
    z-index: ${props => (props.$zIndexOffset ?? 0) + 1};
    overflow: hidden;
    transition-duration: ${props => props.$transition_time}ms;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    ${({theme}) => theme.typography.body}
`

const DropdownContainer = styled.div<DropdownContainerProps>`
    position: absolute;
    width: 100%;
    top: 0;
    z-index: ${props => (props.$zIndexOffset ?? 0) + 1};
    overflow: hidden;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    ${props => props.$expanded && props.$expanding ? `
    box-shadow: 1px 9px 16px -4px rgba(0,0,0,0.75);
    -webkit-box-shadow: 1px 9px 16px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: 1px 9px 16px -4px rgba(0,0,0,0.75);
    transition: box-shadow 0.1s ease-in-out;
  ` : ``}
    ${props => props.$expanding ? `visibility: visible` : `visibility: hidden;`}
    ${props => props.$expanding ? `` : `height: 0;`}
`

const DropdownToggleButton = styled.button<DropdownProps>`
    width: 100%;
    background: ${({theme}) => theme.palette.common.lightTurquoise};
    padding: 0.5rem;
    border-radius: 10px;
    border: none;
    margin: 0;
    cursor: pointer;
    position: relative;
    z-index: ${props => (props.$zIndexOffset ?? 0) + 2};
    text-align: left;
    color: ${({theme}) => theme.palette.common.darkBlue};
    ${({theme}) => theme.typography.body}
    ${props => props.$expand ? `font-weight: bold` : ``};

    &:hover {
        path {
            transition: 0.25s;
            fill: ${({theme}) => theme.palette.common.white};;
        }
    }

    path {
        transition: 0.25s;
    }
`

export const OrderDropdown = (props: OrderDropdownProps) => {
    const [expanding, setExpanding] = useState<boolean>(false);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [dropdownHeight, setDropdownHeight] = useState<number>();
    const [selectedIndex, setSelectedIndex] = useState(props.defaultOptionIndex && props.defaultOptionIndex >= 0 ? props.defaultOptionIndex : 0);
    const [cursor, setCursor] = useState<number | null>(null);
    const ulRef = React.createRef<HTMLUListElement>();
    const btnRef = React.createRef<HTMLButtonElement>();

    const selectedOption = props.options[selectedIndex];

    const $zIndexOffset = props.$zIndexOffset ?? 5;

    const select = (option: DropdownOption, index: number) => {
        setSelectedIndex(index);
        props.onSelect(option);
    }

    const handleToggleClick = useCallback(() => {
        if (!props.disabled) {
            setExpanding(!expanding);
            if (expanded && expanding) {
                setTimeout(function () {
                    setExpanded(false);
                }.bind(this), props.$transition_time);
            } else {
                setTimeout(function () {
                    setExpanded(true);
                }.bind(this), props.$transition_time);
            }
        }
    }, [props.disabled, expanding, expanded, props.$transition_time]);

    const handleElementClick = (option: DropdownOption, index: number) => {
        select(option, index);
        handleToggleClick();
    }

    const handleClickOutside = React.useCallback((event: any) => {
        // Click outside Dropdown list
        if (expanded && ulRef.current && !ulRef.current.contains(event.target) && btnRef.current && !btnRef.current.contains(event.target)) {
            handleToggleClick()
        }
    }, [btnRef, expanded, handleToggleClick, ulRef]);

    /**
     * Move the cursor with ArrowUp and ArrowDown, and select the element the cursor is on in the dropdown.
     */
    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (!cursor) setCursor(0);
        // arrow up/down button should select next/previous list element
        if (e.key === 'ArrowUp' && cursor && cursor > 0) {
            //Move the cursor op (lower index).
            setCursor(cursor - 1);
            return;
        }

        if (e.key === 'ArrowDown' && cursor !== null && cursor < props.options.length - 1) {
            //Move the cursor down (higher index).
            setCursor(cursor + 1);
            return;
        }

        if (["Space", "ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(e.code)) {
            e.preventDefault();
            return;
        }
        //Select the current element the cursor is on in the dropdown.
        if (e.key === 'Enter' && cursor != null) {
            setSelectedIndex(cursor);
        }
    }

    React.useEffect(() => {
        if (ulRef.current) {
            setDropdownHeight(ulRef.current.clientHeight);
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [handleClickOutside, ulRef]);

    React.useEffect(() => {
        if (!expanding) {
            btnRef.current?.blur()
            setCursor(null)
        }
    }, [btnRef, expanding])

    return <DropdownWrapper className={props.className} $expanded={expanded} $zIndexOffset={$zIndexOffset}>
        <DropdownToggleButton
            onClick={handleToggleClick} $expand={expanding} ref={btnRef}
            onKeyDown={handleKeyDown}
            $zIndexOffset={$zIndexOffset}
            data-cy={props['data-cy']}
        >
            {selectedOption?.text}
            <ChevronIcon icon={faChevronDown} $expand={expanding} $transition_time={props.$transition_time}/>
        </DropdownToggleButton>
        <DropdownContainer $expanding={expanding} $expanded={expanded} $zIndexOffset={$zIndexOffset}>
            <Dropdown
                $expand={expanding}
                $transition_time={props.$transition_time}
                dropdownHeight={dropdownHeight}
                $zIndexOffset={$zIndexOffset}
                ref={ulRef}
            >
                {props.options.map((option, index) =>
                    <OrderDropdownElement
                        key={`${JSON.stringify(option.value)} ${option.text}`}
                        $focused={index === cursor}
                        text={option.text}
                        value={option.value}
                        onClick={() => handleElementClick(option, index)}
                        data-cy={props["data-cy"] ? `${props["data-cy"]}-option` : undefined}
                    />
                )}
            </Dropdown>
        </DropdownContainer>
    </DropdownWrapper>;
}

export default OrderDropdown;