import {retry} from "@crm/utils/helpers";

export default function createPurchaseEvent(transactionID: number, value?: number, items: [] = []) {
    retry(async () =>
        window.gtag('event', 'purchase', {
            transaction_id: transactionID,
            value: value,
            currency: 'DKK',
            items: items,
        })).catch(console.error);
};
