import React from 'react';

export interface CallMeContextProps {
    children: React.ReactNode | React.ReactNode[];
}

export interface CallMeContextValues {
    opened: boolean;
    submitted: boolean;
    setCallMeSubmitted: () => void;
    openCallMe: () => void;
    closeCallMe: () => void;
}

export const CallMeContext = React.createContext({} as CallMeContextValues);

export const CallMeProvider = (props: CallMeContextProps): React.JSX.Element => {
    const {children} = props;

    const [opened, setOpened] = React.useState(false);
    const [submitted, setSubmitted] = React.useState(false);

    const handleOpen = React.useCallback(() => setOpened(true), []);

    const handleClose = React.useCallback(() => setOpened(false), []);

    const handleSubmitted = React.useCallback(() => {
        setSubmitted(true);
        handleClose();
    }, [handleClose]);

    return <CallMeContext.Provider value={{
        opened,
        submitted,
        setCallMeSubmitted: handleSubmitted,
        openCallMe: handleOpen,
        closeCallMe: handleClose
    }}>
        {children}
    </CallMeContext.Provider>;
};
