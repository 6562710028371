import {VAT} from '@crm/utils/constants';
import {ExtraServiceRequest} from '@crm/api/models/requests/housekeepr-api';

export interface Address {
    id: number;
    address_id: string;
    address_string: string;
    area: number;
    basement_area: number | undefined;
    longitude?: string;
    latitude?: string;
    parish: number;
    postal_code: number | string;
}

export interface PaymentInterval {
    id: number;
    discount: number;
    months: number;
}

export function paymentIntervalDisplayString(paymentInterval: PaymentInterval) {
    if (paymentInterval.discount > 0) {
        return paymentInterval.months === 1
            ? `Hver måned (${paymentInterval.discount}% rabat)`
            : `Hver ${paymentInterval.months}. måned (${paymentInterval.discount}% rabat)`;
    } else {
        return paymentInterval.months === 1
            ? 'Hver måned'
            : `Hver ${paymentInterval.months}. måned`;
    }
}

export interface Availability {
    date: string;
    schedule_id: number;
    time_slot_id: number;
    time_slot_range: string;
    team_id: number;
    score: number;
}

export interface DiscountCode {
    code: string;
    service_id?: number;
    item: string;
    type: 'percentage' | 'fixed' | string;
    value?: number;
    percent_off?: number;
    active: boolean;
}

export interface ReferralCode {
    code: string;
}

export interface CampaignCode {
    code: string;
}

export interface Location {
    id: number;
    access: string | null;
    address: Address;
    customer_id: number;
    pets: string[];
    state: string;
}

export interface ServiceType {
    id: number;
    allows_extra_services?: number;
    description?: string;
    heading?: string;
    logo?: string;
    name?: string;
    type: 'cleaning' | 'window_cleaning' | string;
    website_available?: boolean;
}

export interface TimeSlot {
    id: number;
    weekday: number;
    slot: string;
    slot_description: string;
    time_span: number;
    sorting_priority: number;
}

export interface User {
    id: number;
    customer: Customer;
    email: string;
    name: string;
    notifications_enabled?: boolean;
    phone: string;
    role: string;
}


export interface Customer {
    id: number;
    address: Address;
}

export interface Service {
    id: number;
    craftsman_deduction: number;
    description: string;
    extents: [];
    extraServices: [];
    frequencies: Frequency[];
    logo_url: string;
    name: string;
    parishes: [number];
    windows: [];
    search_period: SearchPeriod;
}

export type SearchPeriod = Readonly<{
    start_offset: number;
    end_offset: number;
}>

export interface PricedService extends Service {
    price: number;
}

export type Frequency = Readonly<{
    id: number;
    every: number;
    nicified_string: string;
    discount: number;
    public: boolean;
    search_chunks: number;
}>

export function frequencyDisplayString(frequency: Frequency) {
    return frequency.discount > 0 ? `${frequency.nicified_string} (${frequency.discount}% rabat)` : frequency.nicified_string;
}

export function frequencyInDays(frequency: Frequency) {
    return frequency.every * 7;
}

export type ExtraService = Readonly<{
    id: number;
    name: string;
    description: string;
    unit: string;
    image_url: string;
    price: number;
    max_number: number;
    is_orderable: boolean;
}>;

export function extraServicePriceDisplayString(extraService: ExtraService, discount?: number) {
    const priceWithVat = extraService.price * VAT * (1 - (discount ?? 0) / 100);

    if (extraService.max_number === 1) {
        return priceWithVat.toLocaleString(
            'da-DK',
            {
                style: 'currency',
                currency: 'DKK'
            }
        );
    }

    const unit = extraService.unit ?? 'stk.';
    return `${priceWithVat.toLocaleString(
        'da-DK',
        {
            style: 'currency',
            currency: 'DKK'
        }
    )}/${unit}`;
}

export function extraServicePriceOrSelectedDisplay(extraService: ExtraService, selected?: ExtraServiceRequest, discount?: number) {
    if ((selected?.count ?? 0) > 0) {
        const priceWithVat = extraService.price * VAT * selected!.count * (1 - (discount ?? 0) / 100);

        const priceString = priceWithVat.toLocaleString(
            'da-DK',
            {
                style: 'currency',
                currency: 'DKK'
            });

        const weekString = selected?.how_often === 1 ? 'besøg' : `${selected!.how_often}. besøg`;

        return `${priceString} hvert ${weekString}`;
    }

    return extraServicePriceDisplayString(extraService, discount).replace('kr. ', 'kr.\n');
}


export type WindowType = Readonly<{
    id: number;
    name: string;
    description: string;
    image_url: string;
    price: number;
    max_number: number;
}>;

export type ResidenceType = Readonly<{
    type: string;
    description: string;
}>;