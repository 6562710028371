import React from 'react';
import {DefaultTheme, useTheme} from 'styled-components';

// eslint-disable-next-line @typescript-eslint/no-empty-function
function noop() {
}

export const mockMediaQueryList: MediaQueryList = {
    media: '',
    matches: false,
    onchange: noop,
    addListener: noop,
    removeListener: noop,
    addEventListener: noop,
    removeEventListener: noop,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    dispatchEvent: (_: Event) => true
};

export const useMediaQuery = (
    queryInput: string | ((theme: { theme: DefaultTheme }) => string)
): boolean => {
    const [match, setMatch] = React.useState<boolean>(false);
    const theme = useTheme();
    let query = typeof queryInput === 'function' ? queryInput({theme}) : queryInput;
    query = query.replace(/^@media( ?)/m, '');

    React.useEffect(() => {
        let mounted = true;
        const mediaQueryList: MediaQueryList =
            typeof window === 'undefined' ? mockMediaQueryList : window.matchMedia(query);

        const onChange = () => {
            if (!mounted) {
                return;
            }

            setMatch(Boolean(mediaQueryList.matches));
        };

        mediaQueryList.addEventListener('change', onChange);
        setMatch(mediaQueryList.matches);

        return () => {
            mounted = false;
            mediaQueryList.removeEventListener('change', onChange);
        };
    }, [query]);

    return match;
};

export default useMediaQuery;
