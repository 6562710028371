import orderStyles from 'styles/order.module.css';
import {smoothScrollToTop} from '@crm/utils/helpers';
import React, {useEffect, useState} from 'react';
import {housekeeprApi} from '@crm/api/housekeepr-api';
import AddressField from '@crm/components/address-look-up/address-field';
import {useAppDispatch, useAppSelector} from '@crm/services/store';
import {skipToken} from '@reduxjs/toolkit/query';
import {
    InvalidAddressDialog
} from '@crm/components/layout/landing-page/order-wizard/steps/address-lookup/invalid-address-dialog';
import {cleaningActions, cleaningSelectors} from '@crm/services/cleaning-slice';

type CleaningHomeCardProps = { hidden?: boolean };

export const CleaningHomeCard = (props: CleaningHomeCardProps) => {
    const dispatch = useAppDispatch();

    const canGoToNext = useAppSelector(cleaningSelectors.selectCanGoToNextStep);

    const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);

    const serviceTypeId = useAppSelector(cleaningSelectors.selectServiceTypeId);
    const selectedAddress = useAppSelector(cleaningSelectors.selectAddress);
    const selectedArea = useAppSelector(cleaningSelectors.selectArea);
    const selectedServiceId = useAppSelector(cleaningSelectors.selectServiceId);

    const {
        data: serviceTypesResult
    } = housekeeprApi.useGetServiceTypesQuery();

    const {
        data: addressResult,
        error: addressError
    } = housekeeprApi.useRegisterAddressQuery(selectedAddress
        ? {
            darId: selectedAddress.adresse.id
        }
        : skipToken
    );

    const {
        data: filteredServicesResult
    } = housekeeprApi.useGetServicesWithoutPriceQuery(serviceTypeId && addressResult
        ? {
            serviceTypeId,
            parish: addressResult.parish
        }
        : skipToken
    );

    const {
        data: servicesResult
    } = housekeeprApi.useGetServicesWithoutFilterQuery(serviceTypeId && !filteredServicesResult
        ? serviceTypeId
        : skipToken
    );

    const services = filteredServicesResult ?? servicesResult;

    const serviceType = serviceTypeId ? serviceTypesResult?.find(serviceType => serviceType.id == serviceTypeId) : serviceTypesResult?.find(serviceType => serviceType.type == 'cleaning');

    const dawaAddress = selectedAddress?.adresse;

    const {
        data: bbrUnit
    } = housekeeprApi.useGetBbrUnitQuery(dawaAddress ? dawaAddress.id : skipToken);

    const allowedPropertyCodes = ['110', '120', '130', '131', '132', '140', '190', '510', '520'];

    const bbrArea = bbrUnit?.find(unit => {
        return unit.enh020EnhedensAnvendelse && allowedPropertyCodes.includes(unit.enh020EnhedensAnvendelse) && unit.enh026EnhedensSamledeAreal;
    })?.enh026EnhedensSamledeAreal;

    useEffect(() => {
        if (!serviceTypeId && serviceType) {
            dispatch(cleaningActions.start({serviceTypeId: serviceType.id}));
        }
    }, [serviceTypeId, serviceType, dispatch]);

    useEffect(() => {
        if (selectedServiceId && filteredServicesResult && !filteredServicesResult.some(service => service.id == selectedServiceId)) {
            dispatch(cleaningActions.clearService());
        }
    }, [serviceTypeId, serviceType, dispatch, selectedServiceId, filteredServicesResult]);

    useEffect(() => {
        if (!addressResult && !!addressError && selectedServiceId) {
            dispatch(cleaningActions.clearService());
        }
    }, [addressResult, addressError, selectedAddress, dispatch, selectedServiceId]);

    return <div className={props.hidden ? orderStyles.hidden : orderStyles.cardWrapper}>
        <div className={orderStyles.card}>
            <div className={orderStyles.orderSection}>
                <h1 className={orderStyles.cardH1}>
                    Hvad er din adresse?
                </h1>
                <div
                    id={'address-content'}
                    className={`${orderStyles.orderSectionContent}`}
                >
                    <div
                        className={`${orderStyles.addressCard} cursor-pointer`}
                        onClick={() => setIsAddressModalOpen(true)}
                    >
                        {selectedAddress
                            ? <p>
                                {selectedAddress.tekst}
                            </p>
                            : <p>
                                Indtast din adresse
                            </p>
                        }
                    </div>
                </div>
                {(services && services.length == 0 || !!addressError) &&
                    <InvalidAddressDialog
                        mobile={true}
                        lightBlue={true}
                        serviceName={'rengøring'}
                        address={selectedAddress?.tekst}
                        hideCloseButton={true}
                    />
                }
            </div>
            {selectedAddress != null &&
                <div
                    className={orderStyles.orderSection}
                >
                    <h1 className={orderStyles.cardH1}>
                        Hvor stort et areal skal rengøres?
                    </h1>
                    <div
                        id={'area-content'}
                        className={`${orderStyles.orderSectionContent} ${orderStyles.removeTopMargin}`}
                    >
                        Vi tager udgangspunkt i boligens registrerede areal fra BBR. Hvis der er værelser, der
                        ikke skal rengøres, kan deres areal trækkes fra.
                        <div
                            className={`${orderStyles.flexRow} ${orderStyles.flexCrossCenter}`}
                            style={{
                                marginTop: 20,
                                gap: 16,
                                position: 'relative'
                            }}
                        >
                            <input
                                className={orderStyles.inputField}
                                style={{
                                    marginTop: 8,
                                }}
                                placeholder="Areal i m²"
                                inputMode={'numeric'}
                                min={0}
                                max={1000}
                                value={`${selectedArea ?? bbrArea ?? ''} m²`}
                                onInput={event => {
                                    const inputElement = event.target as HTMLInputElement;
                                    const value = inputElement.value.substring(0, inputElement.value.lastIndexOf(' '));
                                    const count = parseInt(value);
                                    const newValue = Math.max(0, Math.min(500, isNaN(count) ? 0 : count));
                                    inputElement.value = `${selectedArea ?? bbrArea ?? ''} m²`;
                                    if (newValue != bbrArea) {
                                        dispatch(cleaningActions.setArea(newValue));
                                    } else {
                                        dispatch(cleaningActions.clearArea());
                                    }
                                    inputElement.selectionStart = Math.min(inputElement.selectionStart ?? inputElement.value.length, inputElement.value.lastIndexOf(' '));
                                    inputElement.selectionEnd = Math.min(inputElement.selectionEnd ?? inputElement.value.length, inputElement.value.lastIndexOf(' '));
                                }}
                                onSelect={event => {
                                    const inputElement = event.target as HTMLInputElement;
                                    inputElement.selectionStart = Math.min(inputElement.selectionStart ?? inputElement.value.length, inputElement.value.lastIndexOf(' '));
                                    inputElement.selectionEnd = Math.min(inputElement.selectionEnd ?? inputElement.value.length, inputElement.value.lastIndexOf(' '));
                                }}
                            />
                            {selectedArea !== undefined && selectedArea != bbrArea &&
                                <button
                                    style={{
                                        position: 'absolute',
                                        right: 12,
                                        top: '50%',
                                        transform: 'translateY(-50%)',
                                        background: 'none',
                                        border: 'none'
                                    }}
                                    onClick={() => {
                                        dispatch(cleaningActions.clearArea());
                                    }}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"
                                         fill="none" stroke="#2A4D4F" stroke-width="1.5" stroke-linecap="round"
                                         stroke-linejoin="round" className="feather feather-refresh-ccw">
                                        <polyline points="1 4 1 10 7 10"></polyline>
                                        <polyline points="23 20 23 14 17 14"></polyline>
                                        <path
                                            d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path>
                                    </svg>
                                </button>
                            }
                        </div>
                    </div>
                </div>
            }
            {isAddressModalOpen &&
                <div className={`${orderStyles.modalContainer}`}>
                    <div className={`${orderStyles.modalCard}`} style={{overflowY: "visible"}}>
                        <h1 className={orderStyles.cardH1}>
                            Indtast og vælg din adresse
                        </h1>
                        <div className={`${orderStyles.orderSectionContent} ${orderStyles.removeTopMargin}`}>
                            <AddressField
                                label="Adresse"
                                name={'address'}
                                onClick={(address) => {
                                    dispatch(cleaningActions.setAddress(address));
                                    setIsAddressModalOpen(false);
                                }}
                                hideOptions={false}
                                hideArrow={true}
                            />
                        </div>
                        <div
                            className={orderStyles.closeButton}
                            onClick={() => setIsAddressModalOpen(false)}
                        >
                            <svg
                                id="Component_5_1"
                                data-name="Component 5 – 1"
                                xmlns="http://www.w3.org/2000/svg"
                                width="100%" height="100%"
                                viewBox="0 0 22.715 22.715"
                            >
                                <path
                                    id="np_menu_1591047_000000"
                                    d="M11.821,26.763A1.682,1.682,0,0,0,10,28.256a1.682,1.682,0,0,0,1.821,1.493h25.5a1.682,1.682,0,0,0,1.821-1.493,1.682,1.682,0,0,0-1.821-1.493Z"
                                    transform="translate(13.964 -25.995) rotate(45)"
                                />
                                <path
                                    id="np_menu_1591047_000000-2"
                                    data-name="np_menu_1591047_000000"
                                    d="M1.821,2.986A1.682,1.682,0,0,1,0,1.493,1.682,1.682,0,0,1,1.821,0h25.5a1.682,1.682,0,0,1,1.821,1.493,1.682,1.682,0,0,1-1.821,1.493Z"
                                    transform="translate(22.715 2.111) rotate(135)"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
            }
        </div>
        <div
            className={`${orderStyles.buttonWrapper} ${orderStyles.outline} ${canGoToNext ? '' : orderStyles.disabled} self-end w-[200px]`}>
            <button
                onClick={() => {
                    dispatch(cleaningActions.nextPage());
                    smoothScrollToTop();
                }}
                disabled={!canGoToNext}
            >
                Videre
            </button>
        </div>
    </div>;
};

export default CleaningHomeCard;