import orderStyles from '@crm/styles/order.module.css';
import {AvailabilityOption} from '@crm/components/calendar/calendar-components/date-options/date-options';
import React, {useEffect} from 'react';
import {housekeeprApi} from '@crm/api/housekeepr-api';
import {skipToken} from '@reduxjs/toolkit/query';
import {useAppDispatch, useAppSelector} from '@crm/services/store';
import {LoadingDots} from '@crm/components/icon/loading-dots';
import Typography from '@crm/components/typography/typography';
import Calendar from '@crm/components/calendar/calendar';
import {useDebounce} from '@crm/hooks/use-debounce';
import {smoothScrollToTop} from '@crm/utils/helpers';
import {cleaningActions, cleaningSelectors} from '@crm/services/cleaning-slice';
import {AvailabilityRequestForChunking} from '@crm/api/models/requests/housekeepr-api';
import createEventV2 from '@crm/utils/gtagv2';
import {CLEANING_STEP_TIME} from "@crm/models/steps/cleaning_step_time";
import {CLEANING_STEP_PAYMENT} from "@crm/models/steps/cleaning_step_payment";
import {CLEANING_STEP_USER} from "@crm/models/steps/cleaning_step_user";

type CleaningTimeCardProps = { hidden?: boolean };

export const CleaningTimeCard = (props: CleaningTimeCardProps) => {
    const dispatch = useAppDispatch();

    const currentStep = useAppSelector(cleaningSelectors.selectStep);
    const canGoToNext = useAppSelector(cleaningSelectors.selectCanGoToNextStep);

    const selectedServiceTypeId = useAppSelector(cleaningSelectors.selectServiceTypeId);
    const selectedAddress = useAppSelector(cleaningSelectors.selectAddress);
    const selectedArea = useAppSelector(cleaningSelectors.selectArea);
    const selectedServiceId = useAppSelector(cleaningSelectors.selectServiceId);
    const selectedFrequencyId = useAppSelector(cleaningSelectors.selectFrequencyId);
    const selectedExtraServices = useAppSelector(cleaningSelectors.selectExtraServices);
    const selectedAvailability = useAppSelector(cleaningSelectors.selectAvailability);
    const selectedAvailabilityFetched = useAppSelector(cleaningSelectors.selectAvailabilityFetched);
    const email = useAppSelector(state => state.contactInfo.email);

    const {
        data: frequenciesResult
    } = housekeeprApi.useGetFrequenciesQuery(selectedServiceId ?? skipToken);

    const frequency = frequenciesResult?.find(frequency => frequency.id == selectedFrequencyId);

    const {
        data: serviceResult
    } = housekeeprApi.useGetServiceQuery(selectedServiceId ?? skipToken);

    const {
        data: addressResult
    } = housekeeprApi.useRegisterAddressQuery(selectedAddress
        ? {
            darId: selectedAddress.adresse.id
        }
        : skipToken
    );

    const debouncedExtraServices = useDebounce(selectedExtraServices, 3000);

    const canSearchAvailability = !!selectedServiceId && !!selectedFrequencyId && !!addressResult && !!serviceResult && !!frequency && (currentStep == CLEANING_STEP_TIME || currentStep == CLEANING_STEP_PAYMENT || currentStep == CLEANING_STEP_USER);

    const availabilityRequest = canSearchAvailability ? {
        service_id: selectedServiceId,
        address_id: addressResult.id,
        specified_area: selectedArea,
        frequency_id: selectedFrequencyId,
        extra_services: debouncedExtraServices,
        search_period: serviceResult.search_period,
        search_chunks: frequency.search_chunks
    } as AvailabilityRequestForChunking : undefined;

    const {
        data: availabilityResult,
        isFetching: isFetchingAvailability,
        isUninitialized: isUninitializedAvailability,
        error: availabilityError,
    } = housekeeprApi.useGetServiceAvailabilitySplitQuery(availabilityRequest ?? skipToken);

    useEffect(() => {
        if (availabilityError) {
            createEventV2('searching_availability_error', selectedServiceId?.toString());
        }
    }, [availabilityError]);

    useEffect(() => {
        if (selectedAvailability && availabilityResult && !availabilityResult.some(availability => availability.time_slot_id == selectedAvailability.time_slot_id && availability.schedule_id == selectedAvailability.schedule_id)) {
            const matchingAvailability = availabilityResult.find(availability => availability.time_slot_id == selectedAvailability.time_slot_id && availability.schedule_id == selectedAvailability.schedule_id);
            dispatch(matchingAvailability ? cleaningActions.setAvailability(matchingAvailability) : cleaningActions.clearAvailability());
        } else if (selectedAvailability) {
            if (availabilityError) {
                dispatch(cleaningActions.clearAvailability());
            } else if (Date.parse(selectedAvailability.date) < Date.now() || Date.now() - new Date(selectedAvailabilityFetched!).getTime() > 1200000) {
                dispatch(cleaningActions.clearAvailability());
                dispatch(housekeeprApi.util.invalidateTags(['Availability']));
            }
        }
    }, [availabilityError, availabilityResult, currentStep, dispatch, selectedAvailability, selectedAvailabilityFetched]);

    useEffect(() => {
        if (isFetchingAvailability) {
            createEventV2('searching_availability', selectedServiceId?.toString());
        }
    }, [addressResult?.address_string, email, isFetchingAvailability, selectedServiceId]);

    return <div className={props.hidden ? orderStyles.hidden : orderStyles.cardWrapper}>
        <div className={orderStyles.card}>
            <div className={orderStyles.orderSection} id={'address-content'}>
                <h1 className={`${orderStyles.cardH1}`}>
                    {frequency?.every == 0 ? 'Hvornår skal vi komme?' : 'Hvornår skal vi komme første gang?'}
                </h1>
                {frequency?.every == 0
                    ? <p className={`${orderStyles.orderSectionContent} ${orderStyles.removeTopMargin}`}>
                        Vælg ugedag og tidsrum for ankomst. Husk at være hjemme, så du kan lukke os ind.
                    </p>
                    : <>
                        <p className={`${orderStyles.orderSectionContent} ${orderStyles.removeTopMargin}`}>
                            Vælg din faste ugedag, tidsrum for ankomst, samt dato for dit første besøg.
                        </p>
                        <p className={`${orderStyles.orderSectionContent} ${orderStyles.removeTopMargin}`}>
                            Bemærk at du ved første rengøring skal være hjemme. Efter første besøg kan din personlige
                            Housekeepr selv låse sig ind, hvis du ønsker det. Dette udfyldes i app&apos;en efter
                            bestilling.
                        </p>
                    </>
                }
                {selectedFrequencyId && ((isUninitializedAvailability || isFetchingAvailability || !selectedServiceTypeId)
                        ? <div
                            className={`${orderStyles.calendarWrapper} ${orderStyles.flexColumn} ${orderStyles.flexMainCenter} ${orderStyles.flexCrossCenter} h-[300px]`}>
                            <LoadingDots/>
                            <Typography variant={'body'} className={'mt-4'}>
                                Vent venligst mens vi indlæser ledige tider...
                            </Typography>
                        </div>
                        : <div className={orderStyles.calendarWrapper}>
                            {availabilityError || !availabilityResult
                                ? <div className={orderStyles.orderHandler}>
                                    <Typography
                                        variant={'body'}
                                        textAlign={'center'}
                                    >
                                        <p style={{whiteSpace: 'pre-wrap'}}>
                                            {(availabilityError as any)?.data?.error?.toString()?.replace('. ', '.\n') ?? ' Der opstod desværre en fejl i søgning efter tid. Prøv igen senere.'}
                                        </p>
                                    </Typography>
                                </div>
                                : <Calendar
                                    availabilitySelector={cleaningSelectors.selectAvailability}
                                    dateOptions={availabilityResult?.map(availability => ({
                                        text: availability.date,
                                        availableDate: new Date(Date.parse(availability.date)),
                                        availability: availability
                                    } as AvailabilityOption)) ?? []}
                                    dateOptionRows={3}
                                    multipleDateOptions={false}
                                    onSelectOption={(availabilityOption) => {
                                        dispatch(availabilityOption
                                            ? cleaningActions.setAvailability(availabilityOption.availability)
                                            : cleaningActions.clearAvailability()
                                        );
                                    }}
                                    serviceTypeId={selectedServiceTypeId}
                                />
                            }
                        </div>
                )}
            </div>
        </div>
        <div className={`${orderStyles.flexRow} ${orderStyles.flexMainSpaceBetween} self-stretch`}>
            <div
                className={`${orderStyles.buttonWrapper} ${orderStyles.outline} w-[200px]`}>
                <button
                    onClick={() => {
                        dispatch(cleaningActions.previousPage());
                        smoothScrollToTop();
                    }}
                >
                    Tilbage
                </button>
            </div>
            <div
                className={`${orderStyles.buttonWrapper} ${orderStyles.outline} ${canGoToNext ? '' : orderStyles.disabled} w-[200px]`}>
                <button
                    onClick={() => {
                        dispatch(cleaningActions.nextPage());
                        smoothScrollToTop();
                    }}
                    disabled={!canGoToNext}
                >
                    Videre
                </button>
            </div>
        </div>
    </div>;
};

export default CleaningTimeCard;