import React from 'react';
import styled from 'styled-components';

const IconButtonRoot = styled.button`
  background: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  color: inherit;
  display: flex;
`;

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
}

const IconButton = React.forwardRef<HTMLButtonElement, Props>(function IconButton(
    props: Props,
    ref
) {
    const {children, type = 'button', ...rest} = props;

    return <IconButtonRoot ref={ref} type={type} {...rest}>
        {children}
    </IconButtonRoot>;
});

export default IconButton;
