import React from 'react';
import styled, {DefaultTheme} from 'styled-components';
import {ThemeColorType, themeColorTypes} from '@crm/theme/light-theme';

type Size = 'sm' | 'md' | 'lg' | number;
type Globals = 'inherit' | 'initial' | 'revert' | 'unset';
type Color = Globals | ThemeColorType | string & object;

export const sizeWrapper = {
    sm: '18px',
    md: '24px',
    lg: '30px'
};

interface StyledIconProps {
    size: Size;
    color: Color;
    styles?: string;
    width: string;
    height: string;
}

export const getThemeColorOrString = <T extends Color>(color: T, theme: DefaultTheme): string => {
    if (themeColorTypes.includes(color as ThemeColorType)) {
        return theme.palette.common[color as ThemeColorType];
    } else {
        return color;
    }
};

export const StyledIcon = styled.svg<StyledIconProps>`
  width: ${({width}) => width};
  height: ${({height}) => height};
  fill: currentColor;
  font-size: ${({size}) => {
    if (typeof size === 'number') return `${size}px`;

    return sizeWrapper[size];
}};

  color: ${({color, theme}) => getThemeColorOrString(color, theme)};

  ${({styles}) => styles};
`;

export interface IconProps {
    children?: React.ReactNode | React.ReactNode[];
    color?: Color;
    size?: Size;
    viewBox?: string;
    width?: string;
    height?: string;
    $styles?: {
        root?: string;
    };
}

export const Icon: React.FC<IconProps & React.SVGProps<SVGElement>> = (props: IconProps) => {
    const {
        children,
        $styles : styles,
        color = 'inherit',
        size = 'md',
        viewBox = '0 0 20 20',
        width = '20px',
        height = '20px',
        ...rest
    } = props;

    return <StyledIcon
        width={width}
        height={height}
        viewBox={viewBox}
        color={color}
        size={size}
        styles={styles?.root}
        {...rest}
    >
        {children}
    </StyledIcon>;
};

export default Icon;
