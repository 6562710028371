import React, {HTMLAttributes, PropsWithChildren} from 'react';
import styled, {keyframes} from 'styled-components';

interface Props extends HTMLAttributes<HTMLDivElement> {
    duration?: number;
    delay?: number;
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const FadeIn = (props: PropsWithChildren<Props>) => {
    const {duration = 300, delay = 0, children, ...delegated} = props;

    return <Wrapper
        {...delegated}
        style={{
            // ...((delegated.style && delegated.style) || {}),
            animationDuration: duration + 'ms',
            animationDelay: delay + 'ms'
        }}
    >
        {children}
    </Wrapper>;
};
const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  @media (prefers-reduced-motion: no-preference) {
    animation-name: ${fadeIn};
    animation-fill-mode: backwards;
  }
`;
export default FadeIn;
