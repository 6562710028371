import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {authSlice} from "@crm/services/auth-slice";

export interface ContactInfoState {
    firstName: string;
    lastName: string;
    email: string,
    countryCode: number,
    phoneNumber: string,
    password: string,
    passwordConfirmation: string,
    gclid?: string,
}

const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    countryCode: 45,
    phoneNumber: '',
    password: '',
    passwordConfirmation: '',
    gclid: undefined,
} as ContactInfoState;

export const contactInfoSlice = createSlice({
    name: 'contactInfo',
    initialState,
    reducers: {
        setFirstName(state, action: PayloadAction<string>) {
            state.firstName = action.payload;
        },
        setLastName(state, action: PayloadAction<string>) {
            state.lastName = action.payload;
        },
        setEmail(state, action: PayloadAction<string>) {
            state.email = action.payload;
        },
        setCountryCode(state, action: PayloadAction<number>) {
            state.countryCode = action.payload;
        },
        setPhoneNumber(state, action: PayloadAction<string>) {
            state.phoneNumber = action.payload;
        },
        setPassword(state, action: PayloadAction<string>) {
            state.password = action.payload;
        },
        setPasswordConfirmation(state, action: PayloadAction<string>) {
            state.passwordConfirmation = action.payload;
        },
        setGclid(state, action: PayloadAction<string>) {
            state.gclid ??= action.payload;

            window.dataLayer.push({
                'gclid': state.gclid,
            });
        }
    },
    extraReducers: builder => {
        builder
            .addCase(
                authSlice.actions.setUser,
                state => {
                    state.password = '';
                    state.passwordConfirmation = '';
                }
            )
            .addCase(
                authSlice.actions.clearUser,
                state => {
                    state.password = '';
                    state.passwordConfirmation = '';
                }
            );
    },

    selectors: {
        selectContactInfo: (state) => state,
        selectGclid: (state) => state.gclid,
    },
});

export const {
    actions: contactInfoActions,
    reducer: contactInfoReducer,
    selectors: contactInfoSelectors
} = contactInfoSlice;