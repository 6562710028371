import React from 'react';
import styled from 'styled-components';

export const BaseButtonRoot = styled.button`
  outline: none;
  border: none;
  cursor: pointer;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    cursor: default;
    pointer-events: none;
  }
`;

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
}

const BaseButton = React.forwardRef<HTMLButtonElement, Props>(function Button(props: Props, ref) {
    const {children, type = 'button', ...rest} = props;

    return <BaseButtonRoot ref={ref} type={type} {...rest}>
        {children}
    </BaseButtonRoot>;
});

export default BaseButton;
