import type {AppProps} from 'next/app';
import React, {useEffect, useState} from 'react';
import {createGlobalStyle, ThemeProvider} from 'styled-components';
import Layout from '../components/layout/layout';
import {MenuProvider} from '@crm/contexts/menu-context';
import '../styles/globals.css';
import lightTheme from '../theme/light-theme';
import {resetCSS} from '../utils/reset-css';
import {useRouter} from 'next/router';
import {getWindowDimensions} from '@crm/utils/helpers';
import {CallMeProvider} from "@crm/contexts/call-me-context";
import {wrapper} from "@crm/services/store";
import {Provider} from "react-redux";
import GclidSetter from "@crm/components/gclid-setter/gclid-setter";

const GlobalStyle = createGlobalStyle`
    ${resetCSS}
    .shared-nav-header {
        padding-top: 20px;

        & .menu-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
        }
    }

    html, button {
        font-family: ${props => props.theme.typography.primary.fontFamily};
    }

    html {
        overflow-x: hidden;
        background-color: ${props => props.theme.palette.background.default};
    }

    body {
        margin: 0 auto;
        padding: 0;
        color: ${props => props.theme.palette.text.primary};
        background-color: ${props => props.theme.palette.background.default};
        font-weight: ${props => props.theme.typography.weight.light};
        font-size: 18px;
        min-height: 100vh;
        overflow-x: hidden;
    }

    div[id="root"] {
        min-height: 100vh;
    }

    button {
        margin: 0;
        padding: 0;
    }

    a {
        text-decoration: none;
        color: inherit;

        &:hover {
            color: white;
        }

        &:visited {
            color: inherit;
        }
    }
`;

function App({Component, ...rest}: AppProps) {
    const router = useRouter();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const {store, props} = wrapper.useWrappedStore(rest);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const w = window as { Cypress?: any, store?: typeof store }
        if (w.Cypress) {
            w.store = store
        }
    }, [store]);

    return <>
        <Provider store={store}>
            <ThemeProvider theme={lightTheme}>
                <GclidSetter>
                    <MenuProvider>
                        <CallMeProvider>
                            <Layout>
                                <GlobalStyle/>
                                <Component {...props.pageProps} key={router.pathname}/>
                            </Layout>
                        </CallMeProvider>
                    </MenuProvider>
                </GclidSetter>
            </ThemeProvider>
        </Provider>
    </>;
}

export default App;
