import React from "react";
import {Step} from "@crm/models/step";
import CleaningExtraServicesCard from "@crm/components/cleaning/cleaning-extra-services-card";
import {CleaningState} from "@crm/services/cleaning-slice";

export const CLEANING_STEP_EXTRA_SERVICES = 'extra_services';

export class CleaningStepExtraServices extends Step {
    public getIdentifier(): string {
        return CLEANING_STEP_EXTRA_SERVICES;
    }

    public getName(): string {
        return 'Ekstraydelser';
    }

    public getShortName(): string {
        return 'Ekstra';
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public isComplete(state: CleaningState): boolean {
        return true;
    }

    public buildPage(currentPage: string): React.JSX.Element {
        return <CleaningExtraServicesCard
            key={this.getIdentifier()}
            hidden={currentPage !== this.getIdentifier()}
        />;
    }
}