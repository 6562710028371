/* eslint-disable import/no-named-as-default */
import React from 'react';
import styled from 'styled-components';
import display, {Display} from '../../mixins/display';
import flexbox, {Flexbox} from '../../mixins/flexbox';
import positions, {Positions} from '../../mixins/positions';
import sizing, {Sizing} from '../../mixins/sizing';
import spacing, {Spacing} from '../../mixins/spacing';

const StyledBox = styled.div<Props>`
    ${flexbox};
    ${sizing};
    ${display};
    ${spacing};
    ${positions};

    ${({styles}) => styles};
`;

export interface Props extends Spacing, Flexbox, Sizing, Display, Positions {
    children?: React.ReactNode | React.ReactNode[];
    component?: React.ElementType;
    styles?: { root?: string };
}

export const Box: React.FC<Props> = (props: Props) => {
    const {children, component, styles, ...rest} = props;

    const tag = component || 'div';

    return <StyledBox as={tag} {...rest} styles={styles?.root}>
        {children}
    </StyledBox>;
};

export default Box;
