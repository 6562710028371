import {Step} from "@crm/models/step";
import React from "react";
import CleaningOrderHandlerCard from "@crm/components/cleaning/cleaning-order-handler-card";
import {CleaningState} from "@crm/services/cleaning-slice";

export const CLEANING_STEP_SUBMITTING_ORDER = 'submitting_order';

export class CleaningStepSubmittingOrder extends Step {
    public getIdentifier(): string {
        return CLEANING_STEP_SUBMITTING_ORDER;
    }

    public getName(): string {
        return 'Ordrehåndtering';
    }

    public getShortName(): string {
        return 'Ordre';
    }

    public hideFromTrack(): boolean {
        return true;
    }

    public isComplete(state: CleaningState): boolean {
        return state.completed;
    }

    public buildPage(currentPage: string): React.JSX.Element {
        return <CleaningOrderHandlerCard
            key={this.getIdentifier()}
            hidden={currentPage !== this.getIdentifier()}
        />;
    }
}