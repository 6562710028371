import {css} from 'styled-components';

export interface Positions {
    position?: 'static' | 'absolute' | 'fixed' | 'relative' | 'sticky' | 'initial' | 'inherit';
    top?: string | number;
    right?: string | number;
    bottom?: string | number;
    left?: string | number;
}

export const positions = css<Positions>`
  top: ${props => props.top};
  right: ${props => props.right};
  bottom: ${props => props.bottom};
  left: ${props => props.left};
  position: ${props => props.position};
`;

export default positions;
