import {css} from 'styled-components';

export interface Sizing {
    width?: string | number;
    maxWidth?: string | number;
    minWidth?: string | number;
    height?: string | number;
    maxHeight?: string | number;
    minHeight?: string | number;
    boxSizing?: 'content-box' | 'border-box' | 'initial' | 'inherit';
}

const transformValue = (value: string | number) => {
    if (typeof value === 'string') {
        return value;
    }

    if (value <= 1) {
        return `${value * 100}%`;
    }

    return `${value}px`;
};

export const sizing = css<Sizing>`
  width: ${props => props.width && transformValue(props.width)};
  max-width: ${props => props.maxWidth && transformValue(props.maxWidth)};
  min-width: ${props => props.minWidth && transformValue(props.minWidth)};
  height: ${props => props.height && transformValue(props.height)};
  max-height: ${props => props.maxHeight && transformValue(props.maxHeight)};
  min-height: ${props => props.minHeight && transformValue(props.minHeight)};
  box-sizing: ${props => props.boxSizing};
`;

export default sizing;
