import React from 'react';
import styled, {css} from "styled-components";
import {AvailabilityOption} from "@crm/components/calendar/calendar-components/date-options/date-options";

interface DayOptionProps {
    text: string;
    value?: AvailabilityOption;
    selected: boolean;
    disabled?: boolean;

    onClick?(): any
}

interface OptionButton {
    selected: boolean;
}

const OptionButton = styled.button<OptionButton>`
    ${({theme}) => theme.typography.secondary};
    font-size: 13px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    min-width: 120px;
    min-height: 35px;
    border: ${({theme}) => theme.palette.common.darkBlue} 2px solid;

    ${({theme}) => theme.breakpoints.down('mobile')} {
        min-width: auto;
    }

    ${props => !props.disabled && css`
        cursor: pointer;

        &:hover {
            background-color: ${({theme}) => theme.palette.common.darkBlue};
            color: ${({theme}) => theme.palette.common.lightTurquoise};
        }
    `}

    opacity: ${props => props.disabled ? 0.5 : 1};

    background: ${props => props.selected
    ? ({theme}) => theme.palette.common.darkBlue
    : 'none'
};

    color: ${props => props.selected ? (({theme}) => theme.palette.common.lightTurquoise) : ({theme}) => theme.palette.common.darkBlue};
`

const DateOption = (props: DayOptionProps) =>
    <div>
        <OptionButton
            onClick={props.disabled ? undefined : props.onClick}
            disabled={props.disabled}
            selected={props.selected}
            className='px-3 py-1.5 flex flex-col items-center justify-center w-full'
            data-cy={'timeslot-button'}>
            <span>{props.text}</span>
        </OptionButton>
    </div>;

export default DateOption;