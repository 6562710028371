import React, {forwardRef, Ref, useImperativeHandle, useState} from 'react';
import DateOption from './date-option';
import {Availability, TimeSlot} from "@crm/api/models/common/housekeepr-api";
import {AppState, useAppSelector} from "@crm/services/store";
import {housekeeprApi} from "@crm/api/housekeepr-api";
import useMediaQuery from '@crm/hooks/use-media-query/use-media-query';
import {useTheme} from 'styled-components';

export interface AvailabilityOption {
    text: string;
    availability: Availability;
    availableDate: Date;
}

export interface DateOptionsProps {
    options: AvailabilityOption[];
    selectedDate: Date | undefined;
    rows: number;
    serviceTypeId: number;
    availabilitySelector: (state: AppState) => Availability | undefined;

    getSelectedOptionCallBack(option: AvailabilityOption): any;
}

export interface DateOptionsRef {
    clearSelection: () => void;
}

const DateOptions = (props: DateOptionsProps, ref: Ref<DateOptionsRef>) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(402));
    const availability = useAppSelector(props.availabilitySelector);
    const [selectedAvailability, setSelectedAvailability] = useState<Availability | undefined>(availability);

    const {
        data: timeSlots,
    } = housekeeprApi.useGetTimeSlotsQuery(props.serviceTypeId);

    const handleOnClickOption = (option: AvailabilityOption) => {
        setSelectedAvailability(option.availability);
        props.getSelectedOptionCallBack(option);
    };

    const clearSelection = () => setSelectedAvailability(undefined)

    useImperativeHandle(ref, () => ({
        clearSelection,
    }));

    const dateOptionFromTimeSlot = (timeSlot: TimeSlot, index: number) => {
        const matchingAvailability = props.options.find(option => option.availability.time_slot_id == timeSlot.id);
        return <DateOption
            selected={!!matchingAvailability && selectedAvailability?.schedule_id == matchingAvailability.availability.schedule_id && selectedAvailability?.time_slot_id == timeSlot.id}
            disabled={!matchingAvailability}
            onClick={matchingAvailability ? () => handleOnClickOption(matchingAvailability) : undefined}
            key={index}
            text={timeSlot.slot_description}
            value={matchingAvailability}
        />;
    }

    return isMobile
        ? <div
            className='overflow-y-auto items-center justify-center flex flex-row w-full min-h-[50px] pt-2 pb-8 flex-wrap gap-3 gap-y-5'>
            {timeSlots && props.selectedDate && timeSlots.filter(ts => ts.weekday == props.selectedDate!.getDay()).map(dateOptionFromTimeSlot)}
        </div>
        : <div
            className='overflow-y-auto items-center justify-center flex flex-row w-full min-h-[50px] pt-2 pb-8 flex-wrap gap-3 gap-y-5'>
            {timeSlots && props.selectedDate && timeSlots.filter(ts => ts.weekday == props.selectedDate!.getDay()).sort((a, b) => a.sorting_priority - b.sorting_priority).map(dateOptionFromTimeSlot)}
        </div>;
};

export default forwardRef(DateOptions);
