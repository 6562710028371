import React from 'react';
import styled from "styled-components";

interface DropdownElementProps {
    text: string;
    value: any;
    $focused: boolean;
    "data-cy"?: string;

    onClick(): any;
}

const ListElement = styled.li<{ $focused: boolean }>`
    text-decoration: none;
    padding: 2px 0 2px 0;
    cursor: pointer;
    color: ${({theme}) => theme.palette.common.darkBlue};

    ${props => props.$focused && `font-weight: bold; padding: 3px; border: solid 2px black;`}
    &:hover {
        font-weight: bold;
    }
`

const OrderDropdownElement = (props: DropdownElementProps) =>
    <ListElement onClick={props.onClick} $focused={props.$focused} data-cy={props["data-cy"]}>
        {props.text}
    </ListElement>;

export default OrderDropdownElement;