import React from 'react';
import styled from 'styled-components';

interface CalendarDividerProps {
    $marginBottom?: number;
    $marginTop?: number;
}

const Day = styled.div`
    text-align: center;
    ${({theme}) => theme.typography.secondary};
    font-size: 11px;
    font-weight: 450;
`;

export const CalendarDivider = styled.hr<CalendarDividerProps>`
    margin: ${props => props.$marginTop !== undefined ? props.$marginTop + `%` : `0`} 0 ${props => props.$marginBottom !== undefined ? props.$marginBottom + `%` : `2.5%`} 4%;
    flex-shrink: 0;
    width: 92%;
    border-width: 1px 0 thin;
    border-style: solid;
    border-color: ${({theme}) => theme.palette.common.turquoise};
`;

const DaysInTheWeek = () =>
    <>
        <div className="grid grid-cols-7 px-[1rem] w-full mb-1">
            <Day>MAN</Day>
            <Day>TIR</Day>
            <Day>ONS</Day>
            <Day>TOR</Day>
            <Day>FRE</Day>
            <Day>LØR</Day>
            <Day>SØN</Day>
        </div>
        <CalendarDivider/>
    </>;

export default DaysInTheWeek;
