import React from 'react';
import {Arrow} from "@crm/utils/images";
import styled from "styled-components";

interface ChangeMonthButtonProps {
    direction: "next" | "previous";

    onClick(direction: string): any;
}

const Button = styled.button<ChangeMonthButtonProps>`
  width: 20px;
  margin-top: 12px;
  cursor: pointer;
  background: none;
  border: none;

  ${({theme}) => theme.breakpoints.down('mobile')} {
    width: 15px;
    margin-top: 3px;
  }

  ${props => props.direction == "next"
    ? `float: right;`
    : `transform: scaleX(-1); float: left;`}
  &:hover {
    path {
      transition: 0.25s;
      fill: white;
    }
  }

  path {
    transition: 0.25s;
  }
`

const ChangeMonthButton = (props: ChangeMonthButtonProps) =>
    <Button direction={props.direction} onClick={() => props.onClick(props.direction)}>
        <Arrow/>
    </Button>;

export default ChangeMonthButton;