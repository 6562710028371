export const MonthsDK: { [monthNumber: number]: any } = {
    1: {upper: "Januar", lower: "januar"},
    2: {upper: "Februar", lower: "februar"},
    3: {upper: "Marts", lower: "marts"},
    4: {upper: "April", lower: "april"},
    5: {upper: "Maj", lower: "maj"},
    6: {upper: "Juni", lower: "juni"},
    7: {upper: "Juli", lower: "juli"},
    8: {upper: "August", lower: "august"},
    9: {upper: "September", lower: "september"},
    10: {upper: "Oktober", lower: "oktober"},
    11: {upper: "November", lower: "november"},
    12: {upper: "December", lower: "december"},
}