import OrderDropdown from '../layout/landing-page/order-wizard/dropdowns/order-dropdown';
import TextField from '../text-field';
import {TextFieldProps} from '../text-field/text-field';
import {DropdownOption} from "@crm/components/layout/landing-page/order-wizard/dropdowns/order-dropdown/order-dropdown";

interface Props extends TextFieldProps {
    onSelect: (value: DropdownOption) => void;
}

const countries = [
    {
        text: '+45', //Denmark
        value: 45
    },
    {
        text: '+46', //Sweden
        value: 46
    },
    {
        text: '+47', //Norway
        value: 47
    }
];

const PhoneNumberInputField = (props: Props) => {
    const {onSelect, ...rest} = props;

    return <div className="flex items-center gap-2 w-full">
        <div className="w-28 h-full py-2">
            <div className="w-full h-full rounded-xl flex items-center bg-lightTurquoise">
                <OrderDropdown
                    $transition_time={1}
                    options={countries}
                    onSelect={option => onSelect(option)}
                    className="m-0"
                />
            </div>
        </div>
        <div className="grow">
            <TextField
                {...rest}
                type={'tel'}
                inputMode={'tel'}
            />
        </div>
    </div>;
};

export default PhoneNumberInputField;
