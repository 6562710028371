import Image from "next/legacy/image";
import Link from 'next/link';
import React from 'react';
import styled, {useTheme} from 'styled-components';
import useMenu from '../../../hooks/use-menu';
import {getFluidSizing} from "@crm/utils/helpers";

interface Props {
    withName?: boolean
    negative?: boolean
}

const LogoContainerRoot = styled.div<{ $logoWidth: number | string }>`
    width: ${props => props.$logoWidth};
    cursor: pointer;
`;

export const LogoContainer = (props: Props) => {
    const theme = useTheme();
    const {handleClose} = useMenu();
    const imgName =
        props.withName
            ? (props.negative
                ? "Housekeepr-logo-negative"
                : "Housekeepr-logo-with-name")
            : "Housekeepr-logo";

    return <Link href="/">
        <LogoContainerRoot
            onClick={handleClose}
            $logoWidth={props.withName ? getFluidSizing(170, 240, theme.breakpoints.values.largeScreen, theme.breakpoints.values.mobile) : 30}
        >
            <Image
                priority={true}
                src={"/logo/" + imgName + ".webp"}
                width={props.withName ? 290 : 30}
                height={props.withName ? 50.5 : 30}
                alt="Housekeepr logo"
            />
        </LogoContainerRoot>
    </Link>;
};
