import React from 'react';
import styled, {css} from 'styled-components';

const colorMapper = {
    initial: `
    color: initial;
  `,
    inherit: `
    color: inherit;
  `,
    primary: css`
      color: ${props => props.theme.palette.primary.main};
    `,
    textPrimary: css`
      color: ${props => props.theme.palette.text.primary};

      &:hover {
        color: ${props => props.theme.palette.text.primaryHover};
      }
    `,
    textSecondary: css`
      color: ${props => props.theme.palette.text.secondary};

      &:hover {
        color: ${props => props.theme.palette.text.secondaryHover};
      }
    `,
    error: css`
      color: ${props => props.theme.palette.error.main};
    `
};

const underlineMapper = {
    none: `
    text-decoration: none
  `,
    hover: `
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  `,
    always: `
    text-decoration: underline;
  `
};

const noWrapStyles = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const LinkRoot = styled.a.withConfig({
    shouldForwardProp: (props: string) => !['color', 'underline', 'noWrap'].includes(props)
})<Props>`
  ${({underline}) => underline && underlineMapper[underline]};

  ${({color}) => color && colorMapper[color]};

  ${({styles}) => styles?.root};

  ${({noWrap}) => noWrap && noWrapStyles};
`;

interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
    underline?: 'none' | 'hover' | 'always';
    color?: 'initial' | 'inherit' | 'primary' | 'textPrimary' | 'textSecondary' | 'error';
    styles?: { root?: string };
    noWrap?: boolean;
}

// TODO: Refactor det her komponent, så den bruger NEXT's egen Link komponent
export const Link: React.FC<Props> = (props: Props) => {
    const {children, noWrap, color = 'primary', underline = 'none', ...rest} = props;

    return <LinkRoot noWrap={noWrap} color={color} underline={underline} {...rest}>
        {children}
    </LinkRoot>;
};

export default Link;
