import {routes} from '@crm/routes';
import Link from 'next/link';
import {useEffect, useId, useState} from 'react';
import {useForm} from 'react-hook-form';
import styled, {useTheme} from 'styled-components';
import {StylizedButton} from '../button/stylized-button';
import CheckBoxCircle from '../check-box/check-box-circle';
import Typography from '../typography';
import {NewsletterSubscriptionAbout, NewsletterSubscriptionRequest} from "@crm/api/models/requests/housekeepr-api";
import {housekeeprApi} from "@crm/api/housekeepr-api";
import {useAppSelector} from "@crm/services/store";
import {contactInfoSelectors} from "@crm/services/contact-info-slice";

interface Props {
    $display?: boolean;
    newsAbout: NewsletterSubscriptionAbout;
    textColor?: string;
    backgroundColor?: string;
    $phoneInput: boolean;
    cvrInput?: boolean;
    whenText?: string;
    checkBoxCircleColor?: string;
    checkBoxCheckmarkColor?: string;

    onSubmitted(res: 'error' | 'success', email?: string): any;
}

const ErrorMessage = styled.p`
    color: ${({theme}) => theme.palette.common.red};
    font-size: 12px;
    margin-top: 0;
    padding-top: 0;
    text-align: center;
`;

const InputTw = styled.input<{
    $textColor: string,
    $backgroundColor: string,
}>`
    background-color: ${props => props.$backgroundColor};

    &::placeholder {
        color: ${props => props.$textColor};
    }

    &:-ms-input-placeholder {
        color: ${props => props.$textColor};
    }

    &:focus {
        outline: none;
    }

    /* Change Autocomplete styles in Chrome*/

    &:-webkit-autofill {
        -webkit-text-fill-color: ${props => props.$textColor};
        box-shadow: 0 0 0 1000px ${props => props.$backgroundColor} inset;
        transition: background-color 5000s ease-in-out 0s;
        border: 2px solid ${props => props.$textColor};
    }
`;

const StyledForm = styled.form<{
    $display: boolean,
    $phoneInput: boolean
}>`
    display: ${props => props.$display ? 'flex' : 'none'};
    flex-direction: column;
    gap: 20px;
    padding-bottom: 30px;

    & ${InputTw} {
        font-size: 19px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px 0;
        font-family: ${({theme}) => theme.typography.body.fontFamily};

        ${({theme}) => theme.breakpoints.down('mobile')} {
            font-size: 15px;
        }
    }
`;

export const SubscriptionForm = (props: Props) => {
    const theme = useTheme();
    const {
        $display = props.$display ?? true,
        newsAbout,
        $phoneInput,
        cvrInput,
        onSubmitted,
        textColor = theme.palette.common.gold,
        backgroundColor = theme.palette.common.darkBrown,
        checkBoxCircleColor = textColor,
        checkBoxCheckmarkColor = backgroundColor,
        whenText,
    } = props;
    const [conditionFirst, setConditionFirst] = useState(false);
    const [conditionSecond, setConditionSecond] = useState(false);
    const [email, setEmail] = useState<undefined | string>();
    const checkboxTheme = {
        circle: checkBoxCircleColor,
        checkmark: checkBoxCheckmarkColor,
    };

    const gclid = useAppSelector(contactInfoSelectors.selectGclid);

    const [
        subscribe,
        {
            data: result,
            error,

        }
    ] = housekeeprApi.useSubscribeToNewsletterMutation();

    const {register, handleSubmit} = useForm();

    const errors = error && 'data' in error ? (error.data as any)?.errors : undefined;

    const saveFormData = async (data: any) => {
        const body = {
            about: newsAbout,
            phone: data.$phoneInput?.replace(' ', ''),
            gclid: gclid,
            ...data
        } as NewsletterSubscriptionRequest;
        setEmail(body.email);
        if (conditionFirst && conditionSecond) {
            subscribe(body);
        } else {
            alert(
                'For at tilmelde dig skal du acceptere Housekeeprs privatlivspolitik og at Housekeepr kontakter dig'
            );
        }
    };

    useEffect(() => {
        if (result !== undefined) {
            onSubmitted(result, email)
        }
    }, [email, onSubmitted, result]);


    useEffect(() => {
        if (error !== undefined) {
            onSubmitted("error", email);
        }
    }, [email, error, onSubmitted]);


    const InputTwClassNames = 'w-full text-center border-2 rounded-3xl';

    const formID = 'newsform_' + newsAbout + useId();

    const conditionsValidation = (val: boolean, id: string) => {
        switch (id) {
            case 'conditionFirst':
                setConditionFirst(val);
                break;
            case 'conditionSecond':
                setConditionSecond(val);
                break;
        }
    };

    return <div style={{color: textColor}}>
        <StyledForm id={formID} onSubmit={handleSubmit(saveFormData)} $phoneInput={$phoneInput} $display={$display}
                    data-netlify="true" data-netlify-honeypot="bot-field">
            <div>
                <InputTw
                    $textColor={textColor}
                    $backgroundColor={backgroundColor}
                    type="number"
                    className={'no-arrow-spin ' + InputTwClassNames}
                    min={0}
                    max={9999}
                    required
                    placeholder="POSTNUMMER"
                    {...register('postal_code', {required: true})}
                />
                {errors?.postal_code &&
                    <ErrorMessage>
                        {errors?.postal_code}
                    </ErrorMessage>
                }
            </div>
            <div>
                <InputTw
                    $textColor={textColor}
                    $backgroundColor={backgroundColor}
                    required
                    className={InputTwClassNames}
                    type="name"
                    placeholder="NAVN"
                    {...register('name', {required: true})}
                />
                {errors?.name &&
                    <ErrorMessage>
                        {errors?.name}
                    </ErrorMessage>
                }
            </div>
            {$phoneInput && <div>
                <InputTw
                    $textColor={textColor}
                    $backgroundColor={backgroundColor}
                    required
                    className={'no-arrow-spin ' + InputTwClassNames}
                    type="tel"
                    placeholder="TELEFONNUMMER"
                    {...register('phone', {required: true})}
                />
                {errors?.phone &&
                    <ErrorMessage>
                        {errors?.phone}
                    </ErrorMessage>
                }
            </div>
            }
            {
                cvrInput && <div>
                    <InputTw
                        $textColor={textColor}
                        $backgroundColor={backgroundColor}
                        required
                        className={InputTwClassNames}
                        type="text"
                        placeholder="CVR"
                        {...register('cvr', {required: true})}
                    />
                    {errors?.cvr &&
                        <ErrorMessage>
                            {errors?.cvr}
                        </ErrorMessage>
                    }
                </div>
            }
            <div>
                <InputTw
                    $textColor={textColor}
                    $backgroundColor={backgroundColor}
                    type="email"
                    placeholder="EMAIL"
                    autoComplete="email"
                    required
                    className={InputTwClassNames}
                    {...register('email', {required: true})}
                />
                {errors?.email &&
                    <ErrorMessage>
                        {errors?.email}
                    </ErrorMessage>
                }
            </div>
            <label hidden>

                Don’t fill this out: <input name="bot-field"/>

            </label>
        </StyledForm>

        <div className="grid grid-cols-12 px-0 mobile:px-5 gap-2">
            <div className="col-span-1">
                <CheckBoxCircle
                    colorTheme={checkboxTheme}
                    onToggle={(val, id) => conditionsValidation(val, id)}
                    id="conditionFirst"
                />
            </div>
            <div className="w-full col-span-11 flex content-center">
                <Typography variant="body" style={{fontSize: 12}} className="inline-block pl-2">
                    Jeg accepterer Housekeeprs{' '}
                    <Link href={routes.privacyPolicy.href}>privatlivspolitik</Link>.
                </Typography>
            </div>
            <div className="col-span-1">
                <CheckBoxCircle
                    colorTheme={checkboxTheme}
                    onToggle={(val, id) => conditionsValidation(val, id)}
                    id="conditionSecond"
                />
            </div>
            <div className="w-full col-span-11 flex content-center">
                <Typography variant="body" style={{fontSize: 12}} className="inline-block pl-2">
                    {whenText ?? 'Jeg accepterer at Housekeepr kontakter mig, når en ny service åbner i min by'}
                </Typography>
            </div>
        </div>
        <div className="flex justify-center" style={{marginTop: 20}}>
            <StylizedButton
                $textColor={backgroundColor}
                $hoverVariant="whiteBg"
                $bgColor={textColor}
                type="submit"
                form={formID}
                className={'select-none uppercase'}
            >
                Tilmeld
            </StylizedButton>
        </div>
    </div>;
};
