import {routes} from '@crm/routes';
import React from 'react';
import styled, {useTheme} from 'styled-components';
import useMediaQuery from '../../../hooks/use-media-query/use-media-query';
import {Link} from '../../link/link';
import Typography from '@crm/components/typography';

const AddressContainer = styled.div``;

const AddressContainerClassNames =
    'flex content-center pb-5 tablet:pb-0 laptop:last:w-2/5 tablet:last:justify-end tablet:last:self-end';

const ContactInformation: React.FC = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('tablet'));

    return <div className="mt-10">
        <div className="tablet:flex tablet:justify-between">
            <Typography variant="h5" color="turquoise">
                <strong>Kontakt os</strong>
            </Typography>
        </div>
        <div className="mt-5 w-full grid grid-cols-2 tablet:flex tablet:justify-between tablet:gap-2">
            <AddressContainer className={AddressContainerClassNames}>
                <Typography variant="h5" color="turquoise">
                    <strong>Housekeepr ApS</strong>
                    <br/>
                    Fabriksparken 38,
                    <br/>
                    2600, Glostrup
                </Typography>
            </AddressContainer>
            <AddressContainer className={AddressContainerClassNames}>
            </AddressContainer>
            {matches ? <>
                <AddressContainer className={AddressContainerClassNames}>
                    <Typography variant="h5" color="turquoise">
                        TLF:{' '}
                        <Link underline="hover" href="tel:+4570604069" color="inherit">
                            +45 70 60 40 69
                        </Link>
                    </Typography>
                </AddressContainer>
                <AddressContainer className={AddressContainerClassNames}>
                    <Typography variant="h5" color="turquoise">
                        <Link underline="hover" href="mailto:info@housekeepr.dk" color="inherit">
                            info@housekeepr.dk
                        </Link>
                    </Typography>
                </AddressContainer>
            </> : <AddressContainer className={'flex-col justify-between ' + AddressContainerClassNames}>
                <Typography variant="h5" color="turquoise">
                    TLF:{' '}
                    <Link underline="hover" href="tel:+4570604069" color="inherit">
                        +45 70 60 40 69
                    </Link>
                </Typography>
                <Typography variant="h5" color="turquoise">
                    CVR: 42 95 69 29
                </Typography>
                <Typography variant="h5" color="turquoise">
                    <Link underline="hover" href="mailto:info@housekeepr.dk" color="inherit">
                        info@housekeepr.dk
                    </Link>
                </Typography>
            </AddressContainer>}
            <AddressContainer className={AddressContainerClassNames}>
                <Typography variant="h5" color="turquoise">
                    Hverdage
                    <br/>
                    9.00 – 11:30
                    <br/>
                    12:30 – 15.00
                </Typography>
            </AddressContainer>
            {matches ? <>
                <AddressContainer className={AddressContainerClassNames}>
                    <Typography variant="h5" color="turquoise">
                        <Link underline="always" href={routes.cookiesPolicy.href} color="inherit">
                            Cookies
                        </Link>
                        <br/>
                        <Link underline="always" href={routes.bussPolicy.href} color="inherit">
                            Handelsbetingelser
                        </Link>
                    </Typography>
                </AddressContainer>
                <AddressContainer className={AddressContainerClassNames}>
                    <Typography variant="h5" color="turquoise">
                        CVR: 42 95 69 29
                    </Typography>
                </AddressContainer>
            </> : <AddressContainer className={AddressContainerClassNames}>
                <div className="relative">
                    <Typography variant="h5" color="turquoise">
                        <Link underline="always" href={routes.cookiesPolicy.href} color="inherit">
                            Cookies
                        </Link>
                        <br/>
                        <Link underline="always" href={routes.bussPolicy.href} color="inherit">
                            Handelsbetingelser
                        </Link>
                    </Typography>
                </div>
            </AddressContainer>}
        </div>
    </div>;
};

export default ContactInformation;
