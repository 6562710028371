import React from 'react';
import MenuContext, {MenuContextValues} from '../../contexts/menu-context/menu-context';

export const useMenu = (): MenuContextValues => {
    const values = React.useContext(MenuContext);

    return {...values};
};

export default useMenu;
