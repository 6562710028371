import React from "react";
import {CleaningState} from "@crm/services/cleaning-slice";

export abstract class Step {
    public abstract getIdentifier(): string;

    public abstract getName(): string;

    public getShortName(): string | undefined {
        return undefined;
    }

    public hideFromTrack(): boolean {
        return false;
    }

    public abstract isComplete(state: CleaningState): boolean;

    public abstract buildPage(currentPage: string): React.JSX.Element;
}
