import React from 'react';
import styled from 'styled-components';
import Image from "next/legacy/image";
import {AppleBadge, GoogleBadge} from '@crm/utils/images';

interface Props {
    style?: React.CSSProperties;
    mobileDisplayBlock?: boolean;
    className?: string;
}

const Root = styled.div<{ mobileDisplayBlock: boolean }>`
    width: 100%;
    padding-left: 5%;
    display: flex;
    align-items: center;

    & > div {
        display: inline-block;

        ${({theme}) => theme.breakpoints.down('mobile')} {
            scale: 0.8;
        }
    }

    & > div:nth-child(2) {
        ${({theme}) => theme.breakpoints.down('mobile')} {
            ${props => props.mobileDisplayBlock && `margin-left: -12px`};
        }
    }

    & > div {
        display: inline-block;

        ${({theme}) => theme.breakpoints.down('mobileM')} {
            scale: 0.65;
        }
    }

    & > div:nth-child(2) {
        ${({theme}) => theme.breakpoints.down('mobileM')} {
            ${props => props.mobileDisplayBlock && `margin-left: -65px`};
        }
    }
`;

const ImageContainer = styled.div`
    max-height: 66px;
`;

const LegacyLinksToApp = (props: Props) => {
    const {mobileDisplayBlock = true, className} = props;

    if (mobileDisplayBlock) {
        return <Root style={props.style} mobileDisplayBlock={mobileDisplayBlock} className={className}>
            <div className="">
                <a
                    href="https://apps.apple.com/dk/app/housekeepr/id6443780238"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <AppleBadge/>
                </a>
            </div>
            <div>
                <a
                    href="https://play.google.com/store/apps/details?id=dk.housekeepr.customer"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <GoogleBadge/>
                </a>
            </div>
        </Root>;
    }
    return <div className={"grid grid-cols-2 gap-2 " + className} style={props.style}>
        <a
            href="https://apps.apple.com/dk/app/housekeepr/id6443780238"
            rel="noopener noreferrer"
            target="_blank"
        >
            <ImageContainer>
                <Image
                    src={'/images/app-store-badge-DK-apple.svg'}
                    height={50}
                    width={150}
                    alt={'Apple store logo'}
                />
            </ImageContainer>
        </a>
        <a
            href="https://play.google.com/store/apps/details?id=dk.housekeepr.customer"
            rel="noopener noreferrer"
            target="_blank"
        >
            <ImageContainer>
                <Image
                    src={'/images/google-play-badge.webp'}
                    height={0}
                    width={0}
                    className="w-full h-auto"
                    alt={'Download nu via GooglePlay'}
                />
            </ImageContainer>
        </a>
    </div>;
};

export default LegacyLinksToApp;
