import React from 'react';
import styled, {useTheme} from 'styled-components';
import BaseButton from './base-button';

export type HoverVariant = 'whiteBg' | 'whiteBgAndInvertColor' | 'whiteText';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    $textColor?: string;
    $bgColor?: string;
    fontSize?: number;
    $hoverVariant?: HoverVariant;
}

interface RootProps {
    $textColor: string;
    $bgColor: string;
    fontSize?: number;
    $hoverVariant: 'whiteBg' | 'whiteBgAndInvertColor' | 'whiteText';
}

const ButtonRoot = styled(BaseButton)<RootProps>`
    min-width: 120px;

    font-family: ${props => props.theme.typography.secondary.fontFamily};
    font-weight: ${props => props.theme.typography.weight.light};
    line-height: normal;

    font-size: ${props => props.fontSize ?? 19}px;

    ${({theme}) => theme.breakpoints.down('mobile')} {
        font-size: ${props => props.fontSize ?? 15}px;
    }

    border-radius: 23px;
    background-color: ${props => props.$bgColor};
    color: ${props => props.$textColor};

    &:hover {
        background-color: ${props =>
                (props.$hoverVariant == 'whiteBg' || 'whiteBgAndInvertColor') && '#fff'};
        background-color: ${props => props.$hoverVariant == 'whiteText' && props.$bgColor};
        color: ${props => props.$hoverVariant == 'whiteBgAndInvertColor' && props.$bgColor};
        color: ${props => props.$hoverVariant == 'whiteText' && '#fff'};
    }

    &:disabled {
        cursor: default;
        pointer-events: none;
        background-color: ${({theme}) => theme.palette.secondary.main};
        color: grey;
    }
`;

export const StylizedButton = React.forwardRef<HTMLButtonElement, Props>(function StylizedButton(props: Props, ref) {
    const theme = useTheme();
    const {
        children,
        $hoverVariant: hoverVariant = 'whiteBgAndInvertColor',
        $textColor = theme.palette.common.turquoise,
        $bgColor = theme.palette.common.darkBlue,
        className,
        ...rest
    } = props;
    return <ButtonRoot
        ref={ref}
        $hoverVariant={hoverVariant}
        $textColor={$textColor}
        $bgColor={$bgColor}
        fontSize={props.fontSize}
        className={"flex justify-center px-6 py-3 " + className}
        {...rest}
    >
        {children}
    </ButtonRoot>;
});
