import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AutocompleteAddress} from '@crm/api/models/common/dawa-api';
import {Availability, Frequency, PaymentInterval, Service} from '@crm/api/models/common/housekeepr-api';
import {ExtraServiceRequest} from '@crm/api/models/requests/housekeepr-api';
import {HYDRATE} from 'next-redux-wrapper';
import createEventV2 from '@crm/utils/gtagv2';
import createPurchaseEvent from '@crm/utils/gtag-purchase';
import {housekeeprApi} from '@crm/api/housekeepr-api';
import {WritableDraft} from 'immer/src/types/types-external';
import {setTag, setTags} from '@sentry/core';
import {CleaningOrderResponse} from '@crm/api/models/responses/housekeepr-api';
import {CLEANING_STEP_HOME, CleaningStepHome} from "@crm/models/steps/cleaning_step_home";
import {CLEANING_STEP_PROGRAMME, CleaningStepProgramme} from "@crm/models/steps/cleaning_step_programme";
import {
    CLEANING_STEP_SUBMITTING_ORDER,
    CleaningStepSubmittingOrder
} from "@crm/models/steps/cleaning_step_submitting_order";
import {Step} from "@crm/models/step";
import {CleaningStepExtraServices} from "@crm/models/steps/cleaning_step_extra_services";
import {CleaningStepUser} from "@crm/models/steps/cleaning_step_user";
import {CleaningStepTime} from "@crm/models/steps/cleaning_step_time";
import {CLEANING_STEP_PAYMENT, CleaningStepPayment} from "@crm/models/steps/cleaning_step_payment";
import {authActions} from "@crm/services/auth-slice";
import {hash} from "@crm/utils/helpers";

export const DEFAULT_FLOW = 'default_v1';
export const EARLY_REGISTRATION_FLOW = 'early_registration';

export function getCleaningSteps(flow: string) {
    switch (flow) {
        case EARLY_REGISTRATION_FLOW:
            return [
                new CleaningStepHome(),
                new CleaningStepUser(),
                new CleaningStepProgramme(),
                new CleaningStepExtraServices(),
                new CleaningStepTime(),
                new CleaningStepPayment(),
                new CleaningStepSubmittingOrder(),
            ] as Step[];
        default:
            return [
                new CleaningStepHome(),
                new CleaningStepProgramme(),
                new CleaningStepExtraServices(),
                new CleaningStepUser(),
                new CleaningStepTime(),
                new CleaningStepPayment(),
                new CleaningStepSubmittingOrder(),
            ] as Step[];
    }
}

export type CleaningStep =
    string
    | 'home'
    | 'programme'
    | 'extra_services'
    | 'user'
    | 'time'
    | 'payment'
    | 'submitting_order';

export interface CleaningState {
    started: boolean;
    flow?: string;
    currentStep: CleaningStep;
    priceExpanded: boolean;

    serviceTypeId?: number;
    address?: AutocompleteAddress;
    area?: number;
    serviceId?: number;
    isRecurringService: boolean;
    extraServices: ExtraServiceRequest[];
    frequencyId?: number;
    paymentIntervalId?: number;
    availability?: Availability;
    availabilityFetched?: number; //ms time
    email?: string;
    authenticated: boolean;
    unverifiedCode?: string;
    verifiedCode?: string;
    paymentType?: 'card' | 'mobilepaysubscriptions';
    canPay: boolean;
    paymentAuthorized: boolean;
    paymentError?: string;
    completed: boolean;
    order?: CleaningOrderResponse;
}

const initialState = {
    started: false,
    currentStep: CLEANING_STEP_HOME,
    priceExpanded: false,
    isRecurringService: false,
    extraServices: [],
    authenticated: false,
    paymentType: 'card',
    canPay: false,
    paymentAuthorized: false,
    completed: false,
} as CleaningState;

export const cleaningSlice = createSlice({
    name: 'cleaning',
    initialState: initialState,
    reducers: {
        start(state, action: PayloadAction<{ serviceTypeId: number }>) {
            return {
                ...initialState,
                started: true,
                flow: state.flow ?? getRandomFlow(state),
                serviceTypeId: action.payload.serviceTypeId,
            };
        },

        startFromWizard(state, action: PayloadAction<{
            serviceTypeId: number,
            address: AutocompleteAddress,
            area?: number,
        }>) {
            return {
                ...initialState,
                started: true,
                flow: state.flow ?? getRandomFlow(state),
                serviceTypeId: action.payload.serviceTypeId,
                address: action.payload.address,
                area: action.payload.area,
                currentStep: CLEANING_STEP_PROGRAMME,
            } as CleaningState;
        },

        setPage(state, action: PayloadAction<CleaningStep>) {
            if (action.payload == CLEANING_STEP_SUBMITTING_ORDER) {
                state.paymentAuthorized = false;
                state.paymentError = undefined;
            }

            state.currentStep = action.payload;
            setMaxPage(state);
        },
        nextPage(state) {
            const cleaningSteps = getCleaningSteps(state.flow ?? DEFAULT_FLOW);
            const index = cleaningSteps.findIndex(step => step.getIdentifier() === state.currentStep);

            if (index !== -1 && index < cleaningSteps.length - 1) {
                state.currentStep = cleaningSteps[index + 1].getIdentifier();
            }
        },
        previousPage(state) {
            const cleaningSteps = getCleaningSteps(state.flow ?? DEFAULT_FLOW);
            const index = cleaningSteps.findIndex(step => step.getIdentifier() === state.currentStep);

            if (index >= 1) {
                state.currentStep = cleaningSteps[index - 1].getIdentifier();
            }
        },

        togglePriceExpanded(state) {
            if (!state.priceExpanded) {
                createEventV2('view_cart', null);
            }

            state.priceExpanded = !state.priceExpanded;
            setMaxPage(state);
        },

        setAddressInput(state, action: PayloadAction<{ text: string, caretPosStart?: number, caretPosEnd?: number }>) {
            if (!state.started) {

                createEventV2('search_address', action.payload.text);
            }

            return {
                ...initialState,
                flow: state.flow ?? getRandomFlow(state),
                started: true
            } as CleaningState;
        },

        setAddress(state, action: PayloadAction<AutocompleteAddress>) {
            state.address = action.payload;
            state.area = undefined;
            window.dataLayer.push({
                'user_data': {
                    'address': {
                        'string': hash(state.address.tekst),
                        'street': hash(state.address.adresse.vejnavn),
                        'postal_code': state.address.adresse.postnr,
                        'city': state.address.adresse.postnrnavn,
                    }
                },
            });

            setTag('address', action.payload.tekst);
            createEventV2('selected_address', action.payload.adresse.id);
            clearState(state, STATE_STEP.ADDRESS);
            setMaxPage(state);
        },
        clearAddress(state) {
            state.address = undefined;
            state.area = undefined;
            window.dataLayer.push({
                'user_data': {
                    'address': {
                        'string': undefined,
                        'street': undefined,
                        'postal_code': undefined,
                        'city': undefined,
                    }
                },
            });

            setTag('address', undefined);
            clearState(state, STATE_STEP.ADDRESS);
            setMaxPage(state);
        },

        setArea(state, action: PayloadAction<number>) {
            state.area = action.payload;

            createEventV2('changed_area', action.payload.toString());
            clearState(state, STATE_STEP.AREA);
            setMaxPage(state);
        },
        clearArea(state) {
            state.area = undefined;

            clearState(state, STATE_STEP.AREA);
            setMaxPage(state);
        },

        setService(state, action: PayloadAction<Service>) {
            state.serviceId = action.payload.id;

            if (state.frequencyId == undefined || !action.payload.frequencies.some(frequency => frequency.id === state.frequencyId)) {
                const frequency = action.payload.frequencies[0];
                state.frequencyId = frequency.id;
                state.isRecurringService = frequency.every > 0;
                if (!state.isRecurringService) {
                    state.paymentIntervalId = undefined;
                }
            }

            state.unverifiedCode = state.verifiedCode ?? state.unverifiedCode;
            state.verifiedCode = undefined;
            state.extraServices = [];

            createEventV2('selected_service', action.payload.name);
            clearState(state, STATE_STEP.SERVICE);
            setMaxPage(state);
        },
        clearService(state) {
            state.serviceId = undefined;

            state.isRecurringService = false;
            state.unverifiedCode = state.verifiedCode ?? state.unverifiedCode;
            state.verifiedCode = undefined;
            state.extraServices = [];

            clearState(state, STATE_STEP.SERVICE);
            setMaxPage(state);
        },

        setFrequency(state, action: PayloadAction<Frequency>) {
            state.frequencyId = action.payload.id;

            state.extraServices = [];
            state.isRecurringService = action.payload.every > 0;
            state.unverifiedCode = state.verifiedCode ?? state.unverifiedCode;
            state.verifiedCode = undefined;

            createEventV2('selected_frequency', action.payload.nicified_string);
            clearState(state, STATE_STEP.FREQUENCY);
            setMaxPage(state);
        },
        clearFrequency(state) {
            state.frequencyId = undefined;

            state.isRecurringService = false;
            state.unverifiedCode = state.verifiedCode ?? state.unverifiedCode;
            state.verifiedCode = undefined;

            clearState(state, STATE_STEP.FREQUENCY);
            setMaxPage(state);
        },

        setPaymentInterval(state, action: PayloadAction<PaymentInterval>) {
            if (state.isRecurringService) {
                state.paymentIntervalId = action.payload.id;
            }

            createEventV2('selected_payment_interval', action.payload.months.toString());
            setMaxPage(state);
        },
        clearPaymentInterval(state) {
            state.paymentIntervalId = undefined;

            setMaxPage(state);
        },

        setAvailability(state, action: PayloadAction<Availability>) {
            state.availability = action.payload;

            createEventV2('selected_date_and_time', `${action.payload.date} ${action.payload.time_slot_range}`);
            setMaxPage(state);
        },
        clearAvailability(state) {
            state.availability = undefined;

            setMaxPage(state);
        },
        updateAvailabilityFetched(state) {
            state.availabilityFetched = Date.now();
        },

        setVerifiedCode(state, action: PayloadAction<string>) {
            state.verifiedCode = action.payload;
            state.unverifiedCode = undefined;

            createEventV2('added_promotion_code', action.payload);
            setMaxPage(state);
        },
        clearVerifiedCode(state) {
            state.unverifiedCode = state.verifiedCode ?? state.unverifiedCode;
            state.verifiedCode = undefined;

            setMaxPage(state);
        },

        setPaymentType(state, action: PayloadAction<'card' | 'mobilepaysubscriptions'>) {
            state.paymentType = action.payload;

            createEventV2('selected_payment_type', action.payload);
            setMaxPage(state);
        },

        setCanPay(state, action: PayloadAction<boolean>) {
            state.canPay = action.payload;
            state.paymentError = undefined;

            setMaxPage(state);
        },

        setExtraService(state, action: PayloadAction<ExtraServiceRequest>) {
            if (action.payload.count < 1) {
                state.extraServices = state.extraServices.filter(extraService => extraService.id !== action.payload.id);

                createEventV2('removed_extra_service', action.payload.id.toString());
                clearState(state, STATE_STEP.EXTRA_SERVICES);
                setMaxPage(state);
                return;
            }

            const extraService = state.extraServices.find(extraService => extraService.id === action.payload.id);
            if (extraService) {
                const {id, count, how_often, note} = action.payload;
                extraService.id = id;
                extraService.count = count;
                extraService.how_often = how_often;
                extraService.note = note;
                createEventV2('updated_extra_service', action.payload.id.toString());
            } else {
                state.extraServices.push(action.payload);
                createEventV2('selected_extra_service', action.payload.id.toString());
            }

            clearState(state, STATE_STEP.EXTRA_SERVICES);
            setMaxPage(state);
        },
        removeExtraService(state, action: PayloadAction<number>) {
            state.extraServices = state.extraServices.filter(extraService => extraService.id !== action.payload);

            createEventV2('removed_extra_service', action.payload.toString());
            clearState(state, STATE_STEP.EXTRA_SERVICES);
            setMaxPage(state);
        },

        setEmail(state, action: PayloadAction<string>) {
            state.email = action.payload;
        },

        setAuthenticated(state, action: PayloadAction<boolean>) {
            state.authenticated = action.payload;
            state.canPay = false;

            setMaxPage(state);
        },

        setPaymentAuthorized(state, action: PayloadAction<boolean>) {
            state.paymentAuthorized = action.payload;
            state.paymentError = undefined;

            if (action.payload) {
                createEventV2('add_payment_info', state.paymentType);
            }

            setMaxPage(state);
        },

        setPaymentError(state, action: PayloadAction<string>) {
            state.paymentError = action.payload;
            state.canPay = false;
            state.paymentAuthorized = false;

            setMaxPage(state);
        },
        clearPaymentError(state) {
            state.paymentError = undefined;

            state.canPay = false;
            state.paymentAuthorized = false;

            setMaxPage(state);
        },

        retryPaymentStep(state) {
            state.currentStep = CLEANING_STEP_PAYMENT;
            state.paymentError = undefined;
            state.canPay = false;
            state.paymentAuthorized = false;

            setMaxPage(state);
        },

        markCompleted(state, action: PayloadAction<{ order: CleaningOrderResponse, revenue: number }>) {
            state.completed = true;
            state.order = action.payload.order;

            createPurchaseEvent(action.payload.order.order_id, action.payload.revenue);
            setMaxPage(state);
        },

        resetState(state) {
            if (!state.completed) {
                createEventV2('closed_order_wizard', 'Cleaning');
            }
            setTags({
                'address': undefined,
                'email': undefined
            });
            return {
                ...initialState,
                flow: state.flow,
            };
        }
    },

    extraReducers: builder => {
        builder
            .addCase(
                HYDRATE,
                (state, action: any) => ({
                    ...state,
                    ...action.payload.subject
                })
            )
            .addCase(
                authActions.setUser,
                (state, action) => {
                    state.email = action.payload.user.email;
                    state.authenticated = true;
                    state.canPay = false;
                }
            )
            .addCase(
                authActions.clearUser,
                (state) => {
                    state.email = undefined;
                    state.authenticated = false;
                    state.canPay = false;
                }
            )
            .addMatcher(
                housekeeprApi.endpoints.getServiceAvailability.matchFulfilled,
                state => {
                    state.availabilityFetched = Date.now();
                }
            )
            .addMatcher(
                housekeeprApi.endpoints.getServiceAvailabilitySplit.matchFulfilled,
                state => {
                    state.availabilityFetched = Date.now();
                }
            );
    },

    selectors: {
        selectStarted: (state) => state.started,
        selectStep: (state) => state.currentStep,
        selectFlow: (state) => state.flow,
        selectPriceExpanded: (state) => state.priceExpanded,
        selectServiceTypeId: (state) => state.serviceTypeId,
        selectIsRecurringService: (state) => state.isRecurringService,
        selectAddress: (state) => state.address,
        selectArea: (state) => state.area,
        selectExtraServices: (state) => state.extraServices,
        selectServiceId: (state) => state.serviceId,
        selectFrequencyId: (state) => state.frequencyId,
        selectPaymentIntervalId: (state) => state.paymentIntervalId,
        selectAvailability: (state) => state.availability,
        selectAvailabilityFetched: (state) => state.availabilityFetched,
        selectAuthenticated: (state) => state.authenticated,
        selectUnverifiedCode: (state) => state.unverifiedCode,
        selectVerifiedCode: (state) => state.verifiedCode,
        selectPaymentType: (state) => state.paymentType,
        selectCanPay: (state) => state.canPay,
        selectPaymentAuthorized: (state) => state.paymentAuthorized,
        selectPaymentError: (state) => state.paymentError,
        selectCompleted: (state) => state.completed,
        selectOrder: (state) => state.order,
        selectCleaning: (state) => state,
        selectCanGoToNextStep: createSelector(
            [
                (state: CleaningState) => state.currentStep,
                (state: CleaningState) => state.flow,
                (state: CleaningState) => state,
            ],
            (currentStepIdentifier, flow, cleaningState) => {
                const cleaningSteps = getCleaningSteps(flow ?? DEFAULT_FLOW);

                const index = cleaningSteps.findIndex(step => step.getIdentifier() === currentStepIdentifier);

                if (index === -1 || index >= cleaningSteps.length - 1) {
                    return false;
                }

                for (const step of cleaningSteps) {
                    if (!step.isComplete(cleaningState)) {
                        return false;
                    }
                    if (step.getIdentifier() === currentStepIdentifier) {
                        return true;
                    }
                }

                return true;
            },
        ),
    }
});

enum STATE_STEP {
    ADDRESS_INPUT,
    ADDRESS,
    SERVICE,
    FREQUENCY,
    AREA,
    EXTRA_SERVICES,
}

/**
 * Depending on the step being updated, this will reset all following states.
 * It is intentional that the steps don't have any break or return statements and will fall-through to the next step.
 * @param state
 * @param step
 */
function clearState(state: WritableDraft<CleaningState>, step: STATE_STEP) {
    /* eslint-disable no-fallthrough */
    // noinspection FallThroughInSwitchStatementJS
    switch (step) {
        case STATE_STEP.ADDRESS_INPUT:
            state.address = undefined;
            setTag('address', undefined);
        case STATE_STEP.ADDRESS:
            state.area = undefined;
            state.isRecurringService = false;
            state.serviceId = undefined;
            state.frequencyId = undefined;
        case STATE_STEP.SERVICE:
            state.paymentIntervalId = undefined;
        case STATE_STEP.FREQUENCY:
            state.extraServices = [];
            state.unverifiedCode = state.verifiedCode ?? state.unverifiedCode;
            state.verifiedCode = undefined;
        case STATE_STEP.EXTRA_SERVICES:
            state.availability = undefined;
    }
    /* eslint-enable no-fallthrough */
    return state;
}

function setMaxPage(state: WritableDraft<CleaningState>) {
    const cleaningSteps = getCleaningSteps(state.flow ?? DEFAULT_FLOW);

    for (const step of cleaningSteps) {
        if (state.currentStep === step.getIdentifier()) {
            return;
        }

        if (!step.isComplete(state)) {
            state.currentStep = step.getIdentifier();
            return;
        }
    }
}

function getRandomFlow(state: WritableDraft<CleaningState>) {
    // Randomness disabled for one month
    // const flow = Math.random() < 0.5 ? EARLY_REGISTRATION : DEFAULT_FLOW;
    const flow = DEFAULT_FLOW;
    createEventV2('selected_flow', flow);
    return flow;
}

export const {actions: cleaningActions, reducer: cleaningReducer, selectors: cleaningSelectors} = cleaningSlice;
