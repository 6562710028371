import CleaningProgrammeCard from "@crm/components/cleaning/cleaning-programme-card";
import React from "react";
import {Step} from "@crm/models/step";
import {CleaningState} from "@crm/services/cleaning-slice";

export const CLEANING_STEP_PROGRAMME = 'programme';

export class CleaningStepProgramme extends Step {
    public getIdentifier(): string {
        return CLEANING_STEP_PROGRAMME;
    }

    public getName(): string {
        return 'Service';
    }

    public getShortName(): undefined {
        return undefined;
    }

    public isComplete(state: CleaningState): boolean {
        return state.serviceId !== undefined && state.frequencyId !== undefined && (!state.isRecurringService || state.paymentIntervalId !== undefined);
    }

    public buildPage(currentPage: string): React.JSX.Element {
        return <CleaningProgrammeCard
            key={this.getIdentifier()}
            hidden={currentPage !== this.getIdentifier()}
        />;
    }
}