import React from 'react';
import styled, {useTheme} from 'styled-components';
import {useMediaQuery} from '@crm/hooks/use-media-query/use-media-query';
import {Close} from '../../../../utils/images';
import {IconButton} from '../../../icon-button';
import {useCallMe} from "@crm/hooks/use-call-me";
import {SubscriptionForm} from "@crm/components/newsletter-subscription/subcription-form";
import Typography from '@crm/components/typography';
import {NewsletterSubscriptionAbout} from "@crm/api/models/requests/housekeepr-api";
import createGtagEvent from "@crm/utils/gtag";

const CallMeDialogRoot = styled.div<{ open: boolean }>`
    position: absolute;

    top: 10vh;

    left: 10vw;
    right: 10vw;

    margin: 0 auto;

    padding: 24px 24px 32px 24px;

    width: 442px;
    max-width: min(450px, 80vw);

    z-index: 11;

    background-color: ${({theme}) => theme.palette.common.turquoise};

    display: flex;
    align-items: flex-start;
    flex-direction: column;
    box-shadow: 0 0 35px #00000065;

    transform: ${({open}) => open ? 'translateY(0)' : 'translateY(-150%)'};
    justify-content: space-between;
    overflow-y: hidden;
    border-radius: 20px;

    transition: transform 0.2s ease-out;
`;

const CloseButtonContainer = styled.div`
    path {
        scale: 0.85;
        fill: ${({theme}) => theme.palette.common.darkBlue};
    }

    &:hover {
        path {
            cursor: pointer;
        }
    }
`;

const Title = styled(Typography)`
    text-align: center;
    font-size: 22px;
    letter-spacing: 0;
    line-height: 29px;
    margin-bottom: 30px;
    color: ${({theme}) => theme.palette.common.darkBlue};
`;

const CloseButton: React.FC = () =>
    <CloseButtonContainer aria-label="Close button">
        <Close/>
    </CloseButtonContainer>;

const CallMeDialog = (props: { onOpen?(opened: boolean): void }) => {
    const rootRef = React.useRef<null | HTMLDivElement>(null);
    const {opened, closeCallMe, setCallMeSubmitted, submitted} = useCallMe();
    const theme = useTheme();
    const mobileVw = useMediaQuery(theme.breakpoints.down('mobile'));

    const onClickOutsideMenu = React.useCallback(
        (ev: MouseEvent) => {
            if (rootRef?.current?.contains(ev.target as Node) || mobileVw) {
                return;
            }
            closeCallMe();
        },
        [closeCallMe, mobileVw]
    );

    React.useEffect(() => {
        if (opened) {
            document.addEventListener('mousedown', onClickOutsideMenu, false);
        } else {
            document.removeEventListener('mousedown', onClickOutsideMenu, false);
        }

        props.onOpen?.(opened);

        return () => document.removeEventListener('mousedown', onClickOutsideMenu, false);
    }, [onClickOutsideMenu, opened, props]);

    return <CallMeDialogRoot open={opened} ref={rootRef}>
        <div className="h-5 w-full flex justify-end">
            <IconButton onClick={closeCallMe}>
                <CloseButton/>
            </IconButton>
        </div>

        <Title variant={'h40'}>
            Modtag et opkald om tilbud og hjælp
        </Title>

        <SubscriptionForm
            newsAbout={NewsletterSubscriptionAbout.CALL_ME}
            $phoneInput={true}
            onSubmitted={(result, email) => {
                if (result == 'success' && !submitted) {
                    createGtagEvent('email_subscription', NewsletterSubscriptionAbout.CALL_ME, undefined, 'mail_list', {email: email});
                    setCallMeSubmitted();
                }
            }}
            backgroundColor={theme.palette.common.turquoise}
            textColor={theme.palette.common.darkBlue}
            whenText={'Jeg accepterer at Housekeepr kontakter mig med et uforpligtende tilbud'}
        />
    </CallMeDialogRoot>;
};

export default CallMeDialog;
